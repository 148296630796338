'use strict'

import Vue from 'vue'
import dayjs from 'dayjs'
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

const Plugin = {}
Plugin.install = function (Vue) {
  Vue.dayjs = dayjs
  Object.defineProperties(Vue.prototype, {
    $dayjs: {
      get () {
        return dayjs
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
