/**
 * 沟通记录字段
 */
export default function() {
  return [
    {
      label: '合同期限',
      props: ['startDate','endDate'],
      type: 'datePicker',
      rule: 'date',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      include: ['form', 'table']
    },
    {
      label: '合同状态',
      prop: 'contractStatus',
      type: 'select',
      rule: 'select',
      optionKey: 'contractStatus',
      include: ['form', 'table'],
    },
    {
      label: '客户全名',
      prop: 'fullName',
      type: 'input',
      rule: 'text',
      formWidth: 24,
      include: ['form', 'table', 'query']
    },
    {
      label: '合同主体',
      prop: 'subject',
      type: 'input',
      rule: 'text',
      formWidth: 24,
      include: ['form', 'table']
    },
    {
      label: '是否独家',
      prop: 'isMonopoly',
      type: 'select',
      rule: 'select',
      optionKey: 'isMonopoly',
      include: ['form', 'table'],
    },
    {
      label: '是否续约',
      prop: 'isRenew',
      type: 'select',
      rule: 'select',
      optionKey: 'isRenew',
      include: ['form', 'table'],
    },
    {
      label: '定金/预付',
      prop: 'costType',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '费率（分段）',
      prop: 'rate',
      type: 'input',
      formWidth: 24,
      include: ['form', 'table']
    },
    {
      label: '最低固定收费',
      prop: 'miniFee',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '保证期（月）',
      prop: 'warrantyDate',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '保护期',
      prop: 'protectionPeriod',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '包税方',
      prop: 'tax',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '税率',
      prop: 'taxRate',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '付款期限',
      prop: 'paymentDeadline',
      type: 'textarea',
      include: ['form', 'table']
    },
    {
      label: '创建时间',
      prop: 'createdAt',
      include: ['table']
    }
  ]
}
