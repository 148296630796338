/**
 * 联系人字段
 */
export default function () {
  return [
    {
      label: '姓名',
      prop: 'name',
      type: 'input',
      fixed: 'left',
      rule: 'text',
      include: ['form', 'table', 'query']
    },
    {
      label: '职位',
      prop: 'post',
      type: 'input',
      rule: 'text',
      include: ['form', 'table', 'query']
    },
    {
      label: '联系电话',
      prop: 'mobile',
      type: 'input',
      rule: 'text',
      include: ['form', 'table']
    },
    {
      label: '邮箱',
      prop: 'email',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '联系地址',
      prop: 'address',
      type: 'address',
      include: ['form', 'table']
    },

    {
      label: '备注',
      prop: 'remark',
      type: 'textarea',
      include: ['form', 'table']
    },
    {
      label: '创建时间',
      prop: 'createdAt',
      include: ['table']
    }
  ]
}
