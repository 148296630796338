<template>
  <div class="">
    <UiQuery :items="items" :ctrls="ctrls"></UiQuery>
    <UiTable
      :columns="tableColumns"
      :data="data"
      :ctrls="tableCtrls"
      :loading="loading"
      :tree-props="treeProps"
    ></UiTable>
    <el-dialog
      title="标签信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="formItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="标签信息"
      :visible.sync="isShowDetail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="detailItem"
        :formDefault="currentDetailItem"
        :key="currentDetailItem.id"
        @cancel="isShowDetail = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    formItem() {
      return this.columns.filter((n) => {
        if (n.include && n.include.length) {
          return n.include.includes('form')
        }
      })
    },
    items() {
      return this.columns.filter((n) => {
        if (n.include && n.include.length) {
          return n.include.includes('query')
        }
      })
    },
    tableColumns() {
      return this.columns.filter((n) => {
        if (n.include && n.include.length) {
          return n.include.includes('table')
        }
      })
    },
  },
  data() {
    return {
      loading: false,
      isShow: false,
      isShowDetail: false,
      currentItem: {},
      data: {},
      treeProps: {
        children: 'children',
      },
      currentDetailItem: {},
      detailItem: [
        {
          label: '标签名',
          prop: 'name',
          type: 'input',
          include: ['form'],
        },
        {
          label: '标识',
          prop: 'sign',
          type: 'input',
          include: ['form'],
        },
        {
          label: '排序',
          prop: 'sort',
          type: 'number',
          include: ['form'],
        },
        {
          label: '批量标签名',
          prop: 'multiName',
          type: 'textarea',
          include: ['form'],
          placeholder: '多个标签名用逗号隔开',
        },
      ],
      ctrls: [
        {
          label: '查询',
          click: (params) => {
            this.query(params)
          },
        },
        {
          label: '创建标签',
          click: () => {
            this.isShow = true
          },
        },
      ],
      columns: [
        {
          label: '标签名称',
          prop: 'name',
          sortable: true,
          type: 'input',
          rule: 'text',
          include: ['query', 'table', 'form'],
        },
        {
          label: '标识（唯一）',
          prop: 'sign',
          type: 'input',
          include: ['table', 'form'],
        },
        {
          label: '值',
          prop: 'id',
          include: ['table'],
        },
        {
          label: '排序',
          prop: 'sort',
          type: 'input',
          include: ['table'],
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          include: ['table', 'form'],
        },
      ],
      tableCtrls: [
        {
          label: '添加状态',
          click: (item) => {
            this.currentDetailItem = {}
            this.currentItem = { ...item }
            this.isShowDetail = true
          },
          isShow: (item) => {
            return item.parentId === 0
          },
        },
        {
          label: '修改',
          click: (item) => {
            this.currentDetailItem = { ...item }
            this.isShowDetail = true
          },
          isShow: (item) => {
            return !(item.children && item.children.length)
          },
        },
        {
          label: '删除',
          type: 'danger',
          click: (item) => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              this.$http
                .get('/setting/enum/del', {
                  params: {
                    id: item.id,
                  },
                })
                .then((ret) => {
                  if (ret.code === 200) {
                    this.query()
                    this.$message.success('删除成功 !')
                  }
                })
            })
          },
          // isShow: item => {
          //   return !(item.children && item.children.length)
          // }
        },
      ],
    }
  },
  methods: {
    onSubmit(form) {
      let postData = {
        ...form,
        parentId: 0,
      }
      if (this.isShowDetail) {
        // 添加枚举
        if (this.currentItem.id) {
          if (!postData.name && !postData.multiName) {
            this.$message.error('标签名不能为空 !')
            return
          }
          postData.parentId = this.currentItem.id
          if (postData.multiName) {
            let names = postData.multiName.split(',')
            let reqs = names.map((name) => {
              return this.$http.post('/setting/enum/save', {
                name,
                parentId: this.currentItem.id,
              })
            })
            Promise.all(reqs).then(() => {
              this.$message.success('提交成功 !')
              this.isShow = false
              this.isShowDetail = false
              this.query()
            })
            return
          }
        }
        // 修改枚举
        if (this.currentDetailItem.id) {
          postData.parentId = this.currentDetailItem.parentId
          postData.id = this.currentDetailItem.id
        }
      }
      this.$http.post('/setting/enum/save', postData).then((ret) => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.isShowDetail = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
    onClosed() {
      this.currentItem = {}
    },
    query(params) {
      this.loading = true
      this.$http.get('/setting/enum/tree', { params }).then((res) => {
        this.loading = false
        this.data = res
      })
    },
  },
  mounted() {
    this.query()
  },
}
</script>