<template>
  <div class="ui-tag">
    <div class="ui-tag-checked" v-if="showType === 'button'">
      <el-tag
        v-for="tag in showChecked"
        :key="tag.name"
        closable
        @close="onTagClose(tag, showChecked)"
      >
        {{ tag.label }}
      </el-tag>
      <el-button type="default" size="small" @click="showDialog">
        + 新标签
      </el-button>
    </div>

    <div class="ui-tag-input" v-else>
      <el-input
        v-model="showCheckedInput"
        :placeholder="placeholder"
        @focus="showDialog"
        readonly
        clearable
      ></el-input>
    </div>

    <el-dialog
      title="选择标签"
      :visible.sync="isShow"
      width="70%"
      top="2%"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @closed="onClosed"
    >
      <el-form ref="form" :model="form" label-width="110px" class="ui-tag-form">
        <el-form-item
          :label="item.label"
          v-for="item in tagGroup"
          :key="item.value"
        >
          <template v-for="o in item.options">
            <el-tag
              :key="o.value"
              :type="o.type"
              size="medium"
              :effect="
                checked.find((n) => n.value === o.value) ? 'dark' : 'plain'
              "
            >
              <label :for="`id-${comId}-${o.value}`" class="ui-tag-label"
                ><input
                  type="checkbox"
                  :id="`id-${comId}-${o.value}`"
                  :value="o"
                  v-model="checked"
              /></label>
              {{ o.label }}
            </el-tag>
          </template>

          <el-input
            v-model="item.newTag"
            v-show="item.isShowNew"
            size="mini"
            class="ui-input-mini"
            :ref="'tag' + item.value"
            clearable
            @keyup.enter.native="onSaveNew(item)"
            @blur="onSaveNew(item)"
            v-loading="loading"
          ></el-input>
          <el-button
            type="default"
            size="mini"
            plain=""
            v-show="!item.isShowNew"
            @click="showNew(item)"
          >
            + 新标签
          </el-button>
        </el-form-item>

        <el-form-item label="已选标签">
          <el-tag
            type="default"
            closable
            v-for="tag in checked"
            :key="tag.value"
            @close="onTagClose(tag, checked)"
          >
            {{ tag.label }}
          </el-tag>
          <el-button
            type="danger"
            size="mini"
            plain=""
            icon="el-icon-close"
            @click="clearAll"
            v-if="checked.length"
          >
            清空已选
          </el-button>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: [Array, String],
    showType: {
      type: String,
      default: 'button',
    },
    placeholder: String,
    type: {
      default: 'default',
      type: String,
    },
  },
  watch: {
    '$store.getters.tagGroup': {
      immediate: true,
      handler(val) {
        let tagGroup = val.map((n) => {
          return {
            ...n,
            isShowNew: false,
            newTag: '',
          }
        })
        if (this.type === 'default') {
          tagGroup = tagGroup.filter(
            (n) => !['地域', '公司', '求职状态'].includes(n.label)
          )
        }
        if (this.type === 'talent') {
          tagGroup = tagGroup.filter(
            (n) =>
              ![
                '地域',
                '公司',
                '求职状态',
                '职能模块',
                '所在行业',
                '产品运营',
                '资历',
                '应用场景',
              ].includes(n.label)
          )
        }
        tagGroup.forEach((n) => {
          // n.options.reverse()
          n.options.sort(function (a, b) {
            return a.label.localeCompare(b.label, 'zh')
          })
        })
        this.tagGroup = tagGroup
      },
    },
    showChecked(val) {
      let arr = val.map((n) => n.value)
      let arrStr = arr.join(',')
      let value = this.showType === 'button' ? arr : arrStr
      this.$emit('input', value)
    },
  },
  computed: {
    showCheckedInput() {
      return this.showChecked.map((n) => n.label).join(',')
    },
  },
  data() {
    return {
      comId: '',
      isShow: false,
      loading: false,
      form: {},
      checked: [],
      showChecked: [],
      tagGroup: [],
    }
  },
  methods: {
    clearAll() {
      this.checked = []
    },
    // 动态创建新标签
    onSaveNew(item) {
      let newTag = item.newTag
      if (newTag) {
        let exitItem = item.options.find((n) => n.label === newTag)
        if (exitItem) {
          this.$set(exitItem, 'type', 'danger')
          this.$message.error('标签已存在,请重试 !')
          return
        }
        item.options.forEach((n) => {
          this.$set(n, 'type', 'primary')
        })
        this.loading = true
        this.onEnumSave({
          name: newTag,
          parentId: item.id,
        }).then(() => {
          this.$store.dispatch('getEnumOptions', true).then(() => {
            this.loading = false
            item.isShowNew = false
            item.newTag = ''
          })
        })
        return
      }
      item.isShowNew = false
      item.options.forEach((n) => {
        this.$set(n, 'type', 'primary')
      })
    },
    onEnumSave({ name, parentId }) {
      return this.$http.post('/setting/enum/save', {
        name,
        parentId,
      })
    },
    showNew(item) {
      item.isShowNew = true
      this.$nextTick(() => {
        let key = 'tag' + item.value
        this.$refs[key][0].$refs.input.focus()
      })
    },
    showDialog() {
      this.checked = this.showChecked
      this.isShow = true
    },
    onClosed() {},
    onCancel() {
      this.isShow = false
    },
    onSubmit() {
      this.showChecked = this.checked
      this.isShow = false
    },
    onTagClose(tag, list) {
      let idx = list.findIndex((n) => n.value === tag.value)
      if (idx !== -1) {
        list.splice(idx, 1)
      }
    },
  },
  created() {
    this.comId = new Date().getTime().toString()
    if (this.value && this.value.length) {
      let value = Array.isArray(this.value)
        ? this.value
        : this.value.split(',').map((n) => Number(n))
      this.tagGroup.forEach((n) => {
        let ret = n.options.filter((o) => value.includes(o.value))
        if (ret && ret.length) {
          this.showChecked.push(...ret)
        }
      })
    }
  },
}
</script>

<style lang="less">
.ui-tag {
  &-checked {
    .el-tag {
      margin: 0 10px 5px 0;
      cursor: pointer;
    }
  }
  &-form {
    .el-tag {
      margin: 0 10px 0 0;
      cursor: pointer;
      position: relative;
    }
  }
  &-label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    input {
      visibility: hidden;
    }
  }
}
.ui-input-mini {
  width: 120px;
}
</style>
