/**
 * 客户字段
 */
export default function(context) {
  return [
    {
      label: '基本信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '客户名称',
      prop: 'name',
      sortable: true,
      fixed: 'left',
      width: '150px',
      tableType: 'link',
      click: item => {
        context.openLink(`/client/${item.id}/info`)
        // context.$router.push({ path: `/client/${item.id}/info` })
      },
      type: 'input',
      rule: 'text',
      include: ['query', 'table', 'form', 'show'],
    },
    {
      label: '英文名',
      prop: 'englishName',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '状态',
      prop: 'status',
      optionKey: 'clientStatus',
      type: 'select',
      rule: 'select',
      tableType: 'status',
      showType: 'status',
      include: ['query', 'table', 'form', 'show'],
      format: item => {
        const key = 'status'
        const ret = context.enumOptions.clientStatus.find(
          n => n.value === item[key]
        )
        return ret || item[key]
      },
    },
    {
      label: '客户联系人',
      prop: 'contactId',
      type: 'select',
      tableType: 'link',
      click: item => {
        context.openLink(`/client/${item.id}?type=contact`)
        // context.$router.push({
        //   path: `/client/${item.id}`,
        //   query: { type: 'contact' },
        // })
      },
      include: ['query', 'show'],
      source: () => {
        return context.$http.get('/client/user/list').then(res => {
          return res.data.list.map(n => {
            return {
              label: n.name,
              value: n.id,
            }
          })
        })
      },
    },
    {
      label: '客户联系人',
      prop: 'contact',
      include: ['table'],
      format: item => {
        return item.contact && item.contact.length
          ? item.contact.map(n => n.name).join(',')
          : ''
      },
    },
    {
      label: '客户来源',
      prop: 'source',
      optionKey: 'source',
      type: 'select',
      include: ['table', 'form', 'show'],
      rule: 'select',
    },
    {
      label: '客户地址',
      prop: 'address',
      type: 'address',
      width: '220px',
      tableType: 'line',
      include: ['table', 'form', 'show'],
    },
    {
      label: '标签',
      prop: 'label',
      type: 'multiSelect',
      tableType: 'tag',
      optionKey: 'tag',
      showType: 'tag',
      source: () => {
        return Promise.resolve(context.enumOptions.tag)
      },
      include: ['query', 'table', 'form', 'show'],
    },
    {
      label: '跟进信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '负责人',
      prop: 'followerId',
      type: 'select',
      include: ['table', 'form', 'show', 'query'],
      rule: 'select',
      source: () => Promise.resolve(context.userList),
    },
    {
      label: '参与人',
      prop: 'adviserId',
      type: 'multiSelect',
      include: ['table', 'form', 'show', 'query'],
      source: () => Promise.resolve(context.userList),
    },

    {
      label: '开票信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '开票类型',
      prop: 'financeType',
      type: 'select',
      optionKey: 'financeType',
      include: ['form', 'show'],
    },
    {
      label: '购买方名称',
      prop: 'financeName',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '纳税人识别号',
      prop: 'financeNo',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '服务名称',
      prop: 'serviceName',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '地址',
      prop: 'financeAddress',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '电话',
      prop: 'financeMobile',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '开户行',
      prop: 'financeBank',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '账户',
      prop: 'financeAccount',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '其他信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '公司全称',
      prop: 'fullName',
      type: 'input',
      formWidth: 24,
      include: ['form', 'show'],
    },

    {
      label: '成立年份',
      prop: 'year',
      sortable: true,
      type: 'input',
      dataType: 'number',
      include: ['form', 'show'],
    },
    {
      label: '官网',
      prop: 'website',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '公司性质',
      prop: 'business',
      type: 'select',
      include: ['form', 'show'],
      source: () => {
        return Promise.resolve(context.enumOptions.companyType)
      },
    },
    {
      label: '人员规模',
      prop: 'scale',
      type: 'select',
      optionKey: 'scale',
      include: ['table', 'form', 'show'],
      dataType: 'string'
    },
    {
      label: '公司简介',
      prop: 'intro',
      type: 'textarea',
      include: ['form', 'show'],
    },
    {
      label: '公司产品',
      prop: 'product',
      type: 'textarea',
      include: ['form', 'show'],
    },
    {
      label: '创始人背景',
      prop: 'leaderStroy',
      type: 'textarea',
      include: ['form', 'show'],
    },
    {
      label: '融资情况',
      prop: 'finance',
      type: 'textarea',
      showType: 'raw',
      include: ['form', 'show'],
    },
    {
      label: '核心技术',
      prop: 'technology',
      type: 'textarea',
      include: ['form', 'show'],
    },
    {
      label: '其它备注',
      prop: 'remark',
      type: 'textarea',
      include: ['form', 'show'],
    },
    {
      label: '创建人',
      prop: 'user.name',
      include: ['table', 'show'],
    },
    {
      label: '创建时间',
      prop: 'createdAt',
      sortable: true,
      include: ['table', 'show'],
    },
  ]
}
