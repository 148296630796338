<template>
  <div class>
    <template v-if="timeRange">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/data' }">
          数据报表
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/recommend' }">
          推荐管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ timeRange.startDate }} 至 {{ timeRange.endDate }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ timeRange.type }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <template v-else>
      <UiQuery
        :items="queryColumns"
        :defaultParams="defaultParams"
        :ctrls="ctrls"
      ></UiQuery>
    </template>

    <UiTable
      :columns="tableColumns"
      :data="formatTableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
      @onSortChange="onSortChange"
    ></UiTable>
    <el-dialog
      title="岗位信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="positionColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  watch: {
    '$route.query': {
      handler(query, oldQuery) {
        if (oldQuery.startDate && !query.startDate) {
          location.reload()
        }
      },
    },
  },
  computed: {
    formatTableData() {
      let tableData = {
        total: this.tableData.total,
      }
      if (this.tableData && this.tableData.list) {
        tableData.list = this.tableData.list.map((n) => {
          return { ...n, relateId: n.id, id: n.id }
        })
      }
      return tableData
    },
    queryColumns() {
      return this.positionCandidateColumns.filter(
        (n) => n.prop !== 'followerId'
      )
    },
    tableColumns() {
      return this.positionCandidateColumns.filter((n) => n.prop !== 'follower')
    },
    timeRange() {
      if (this.$route.query.startDate && this.$route.query.endDate) {
        let type = this.$route.query.isInterview
          ? '面试安排'
          : this.$route.query.isOffer
          ? 'offer成功'
          : '推荐人才'
        return {
          startDate: this.$route.query.startDate.substr(0, 10),
          endDate: this.$route.query.endDate.substr(0, 10),
          type,
        }
      }
      return false
    },
  },
  data() {
    return {
      queryUrl: '/recommend/list',
      tableCtrls: [],
      defaultQueryParams: {},
    }
  },
  methods: {
    onSubmit(form) {
      let postData = { ...form }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      this.$http.post('/post/save', postData).then((ret) => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
  },
  created() {
    let postData = {
      isRecommend: true,
    }
    // 非管理员只能看自己推荐
    if (![1, 7].includes(this.userInfo.roleId)) {
      postData.recommendId = this.userInfo.id
    }
    this.defaultParams = { ...postData }
    this.defaultQueryParams = { ...postData }
  },
  mounted() {
    // 读取默认参数
    const key = `path${this.$route.path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    }
    this.query({ ...this.defaultParams, ...this.$route.query })
    this.setTitle()
  }
}
</script>