<template>
  <div class="">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/project' }" replace>
        项目管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project/46000019810503877X' }" replace>
        项目: {{ $route.params.projectName }}
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: `/project/position/${$route.params.positionId}/${$route.params.positionName}`
        }"
        replace
      >
        岗位: {{ $route.params.positionName }}
      </el-breadcrumb-item>

      <el-breadcrumb-item>
        人才详情
      </el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="activeName" tab-position="left">
      <el-tab-pane
        :label="tab.label"
        :name="tab.name"
        v-for="tab in tabs"
        :key="tab.name"
        :style="{ maxHeight: maxHeight + 'px', overflow: 'auto' }"
        v-loading="loading"
      >
        <template v-if="activeName === 'info'">
          <el-form
            :model="form"
            ref="form"
            label-width="100px"
            size="mini"
            label-position="left"
            style="padding: 20px;margin:0 auto;"
            class="ui-info"
          >
            <template v-for="item in infoItem">
              <el-form-item :label="item.label" :key="item.prop">
                <template v-if="item.showType === 'tag'">
                  <el-tag
                    v-for="t in form[item.prop]"
                    :key="t"
                    size="small"
                    style="margin: 0 10px 0 0;"
                  >
                    {{ t }}
                  </el-tag>
                </template>

                <template v-else-if="item.showType === 'status'">
                  <el-tag
                    size="small"
                    style="margin: 0 10px 0 0;"
                    :type="form[item.prop].type"
                    v-if="form[item.prop]"
                  >
                    {{ form[item.prop].name }}
                  </el-tag>
                </template>
                <template v-else>
                  {{ form[item.prop] }}
                </template>
              </el-form-item>
            </template>

            <!-- <el-form-item>
              <el-button type="primary" @click="isShowDetail = true"
                >修改</el-button
              >
            </el-form-item> -->
          </el-form>
        </template>
        <template v-if="activeName === 'list'">
          <div style="padding: 0 0 10px;">
            <el-button type="primary" size="mini" @click="isShow = true">
              创建
            </el-button>
          </div>
          <UiTable
            :columns="tableColumns"
            :data="tableData"
            :ctrls="tableCtrls"
            :loading="loading"
            :height="tableHeight"
          ></UiTable>
        </template>
        <template v-if="activeName === 'experience'">
          <div style="padding: 0 0 10px;">
            <el-button type="primary" size="mini" @click="isShowHistory = true">
              创建
            </el-button>
          </div>
          <el-timeline>
            <el-timeline-item
              :timestamp="n.startTime + ' - ' + n.endTime"
              placement="top"
              v-for="n in history"
              :key="n.id"
            >
              <el-card>
                <h4>
                  {{ n.company }} - {{ n.position }}
                  <span style="color: #999;padding: 0 0 0 20px">
                    ({{ n.startTime }} - {{ n.endTime }})
                  </span>
                </h4>
                <p>工作内容详细介绍</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </template>

        <template v-if="activeName === 'history'">
          <div style="padding: 0 0 10px;">
            <el-button type="primary" size="mini" @click="isShowHistory = true">
              创建
            </el-button>
          </div>
          <el-timeline>
            <el-timeline-item
              :timestamp="n.createdTime"
              placement="top"
              v-for="n in history"
              :key="n.id"
            >
              <el-card>
                <h4>{{ n.remark }}</h4>
                <p><img :src="n.avatar" class="avatar" />{{ n.name }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </template>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="项目详情"
      :visible.sync="isShowDetail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="detailItem"
        :formDefault="form"
        @cancel="isShowDetail = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="岗位信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="formItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="内容记录"
      :visible.sync="isShowHistory"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="historyItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowHistory = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    formItem() {
      return this.tableColumns.filter(
        n => n.include && n.include.includes('form')
      )
    },
    detailItem() {
      return this.infoItem.filter(n => n.include && n.include.includes('form'))
    }
  },
  data() {
    return {
      isShowDetail: false,
      maxHeight: window.innerHeight - 110,
      currentItem: {},
      isShowHistory: false,
      isShow: false,
      tableHeight: window.innerHeight - 190,
      tableData: {},
      history: [],
      historyItem: [
        {
          label: '日期',
          props: 'date',
          type: 'dateTime'
        },
        {
          label: '内容记录',
          prop: 'remark',
          type: 'textarea'
        }
      ],
      tableColumns: [
        {
          label: '简历名称',
          prop: 'resumeName',
          // tableType: 'link',
          type: 'input',
          include: ['form', 'table'],
          click: item => {
            this.$router.push({ path: `/project/position/${item.id}` })
          }
        },
        {
          label: '最高学历',
          prop: 'education',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '毕业院校',
          prop: 'school',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '专业',
          prop: 'major',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '毕业时间',
          prop: 'graduationTime',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '技术擅长',
          prop: 'skill',
          tableType: 'tag',
          showType: 'tag',
          formType: 'select',
          include: ['form', 'table']
        },
        {
          label: '其他备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '简历附件',
          prop: 'file',
          type: 'upload',
          include: ['form', 'table']
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          include: ['table']
        }
      ],
      tableCtrls: [
        {
          label: '修改',
          click: item => {
            this.currentItem = { ...item }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              let idx = this.tableData.data.findIndex(n => n.id === item.id)
              this.tableData.data.splice(idx, 1)
              this.$message.success('删除成功 !')
            })
          }
        }
      ],
      loading: false,
      form: {},
      activeName: 'info',
      infoItem: [
        {
          label: '姓名',
          prop: 'follower',
          tableType: 'link',
          type: 'input',
          include: ['table'],
          click: item => {
            this.$router.push({
              path: `/project/${item.projectId}/${item.projectName}/position/${item.positionId}/${item.positionName}/candidate/${item.id}}`
            })
          }
        },
        {
          label: '手机号码',
          prop: 'mobile',
          type: 'input',
          include: ['table']
        },
        {
          label: '所在城市',
          prop: 'address',
          type: 'input',
          include: ['table']
        },
        {
          label: '人才标签',
          prop: 'tag',
          tableType: 'tag',
          formType: 'tag',
          showType: 'tag',
          type: 'tag',
          include: ['table']
        },
        {
          label: '求职状态',
          prop: 'jobStatus',
          showType: 'status',
          tableType: 'status',
          include: ['form', 'table']
        },
        {
          label: '来源',
          prop: 'source',
          type: 'input',
          include: ['table']
        },
        {
          label: '负责顾问',
          prop: 'follower',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '其他备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          include: ['table']
        }
      ],
      tabs: [
        {
          label: '人才详情',
          name: 'info'
        },
        {
          label: '关联简历',
          name: 'list'
        },
        {
          label: '工作经验',
          name: 'experience'
        },
        {
          label: '面试记录',
          name: 'history'
        }
      ]
    }
  },
  methods: {
    goBack() {
      window.history.back()
    },
    onSubmit() {
      this.$message.success('提交成功 !')
      this.isShowDetail = false
      this.isShow = false
    },
    onClosed() {},
    query() {
      this.loading = true
      this.$http.get('/list').then(res => {
        this.loading = false
        this.form = res.data[0]
      })
    },
    getPosition() {
      this.$http.get('/list?count=2').then(res => {
        this.tableData = res
      })
    },
    getHitory() {
      this.$http.get('/history').then(res => {
        this.history = res.data
      })
    }
  },
  mounted() {
    this.activeName = 'info'
    this.query()
    this.getPosition()
    this.getHitory()
  }
}
</script>

<style lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}
.el-timeline {
  padding: 0 0 0 10px;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }
}

.el-page-header {
  margin-bottom: 10px;
}
.ui-info {
  .el-form-item {
    margin: 0 0 8px !important;
  }
}
</style>
