<template>
  <div class="ui-config">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>微信小程序首页设置</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="ui-config-content">
      <div class="ui-block">
        <h5>首页封面图</h5>
        <Uploader
          type="avatar"
          @input="onchange"
          v-model="config.banner"
        ></Uploader>
      </div>

      <div class="ui-block">
        <h5>资讯文章</h5>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-input
              v-model="config.article"
              placeholder="输入公众号文章链接"
              size="normal"
              clearable
            >
            </el-input>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-button type="primary" size="default" @click="saveArticle">
              保存
            </el-button>
          </el-col>
        </el-row>
      </div>

      <div class="ui-block">
        <h5>关于我们</h5>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-input
              v-model="config.about"
              placeholder="输入公众号文章链接"
              size="normal"
              clearable
            >
            </el-input>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-button type="primary" size="default" @click="onchangeAbout">
              保存
            </el-button>
          </el-col>
        </el-row>
      </div>

      <div class="ui-block">
        <h5>
          热招岗位
          <el-button
            type="primary"
            size="mini"
            style="float: right"
            @click="showNew"
          >
            新增
          </el-button>
        </h5>
        <el-table :data="showPostList" style="width: 100%">
          <el-table-column
            prop="prop"
            :label="item.label"
            width="width"
            v-for="item in columns"
            :key="item.prop"
          >
            <template slot-scope="{ row }">
              {{ format(item, row) }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="prop"
            label="操作"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="danger"
                size="mini"
                plain=""
                @click="deleteRow(scope)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog title="热招岗位" :visible.sync="isShow" width="60%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="选择顾问">
          <el-select
            v-model="form.client"
            placeholder="请选择"
            clearable
            filterable
            @change="changeAgent"
          >
            <el-option
              v-for="item in allAgent"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择岗位">
          <el-select
            v-model="form.post"
            placeholder="请选择"
            clearable
            filterable
            multiple
          >
            <el-option
              v-for="item in postList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="savePost" :loading="submitting">
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Uploader from "../../components/Uploader.vue";
import mixin from "../../common/index";
import component from "../../common/compoment";
export default {
  mixins: [mixin, component],
  components: {
    Uploader,
  },
  computed: {
    agentList() {
      if (this.allAgent && this.config.agent) {
        return this.allAgent.filter((n) => {
          return this.config.post.split(",").includes(n.value.toString());
        });
      }
      return [];
    },
    columns() {
      let maps = ["岗位名称", "所属客户", "负责人", "岗位类别"];
      return this.positionColumns.filter((n) => {
        return n.include.includes("table") && maps.includes(n.label);
      });
    },
  },
  data() {
    return {
      form: {},
      config: {},
      allAgent: [],
      isShow: false,
      submitting: false,
      clientList: [],
      postList: [],
      showPostList: [],
    };
  },
  methods: {
    saveArticle() {
      this.$http
        .post("/wechat/config", {
          key: "article",
          value: this.config.article,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("保存成功");
            return;
          }
          this.$message.error(res.message);
        });
    },
    deleteRow(scope) {
      let arr = this.config.post.split(",");
      let idx = arr.findIndex((n) => parseInt(n) === scope.row.id);
      arr.splice(idx, 1);
      this.$http
        .post("/wechat/config", {
          key: "post",
          value: arr.join(","),
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("保存成功");
            this.getData().then(() => {
              this.getPost(this.config.post);
            });
            return;
          }
          this.$message.error(res.message);
        });
    },
    showNew() {
      this.isShow = true;
    },
    getPost(ids) {
      this.$http
        .get("/wechat/post", {
          params: {
            ids,
          },
        })
        .then((res) => {
          this.showPostList = res.data.list;
        });
    },
    changeAgent(value) {
      this.$http
        .get("/post/list", {
          params: {
            agentId: value,
            pageSize: 10000,
          },
        })
        .then((res) => {
          this.postList = res.data.list.map((n) => {
            return {
              ...n,
              label: n.name,
              value: n.id,
            };
          });
        });
    },
    getAgent() {
      this.$http
        .get("/wechat/agent", {
          pageSize: 10000,
        })
        .then((res) => {
          this.allAgent = res.data.list.map((n) => {
            return {
              ...n,
              label: n.name,
              value: n.id,
            };
          });
        });
    },
    // 新增热门岗位
    savePost() {
      this.submitting = true;
      let currentPost = [];
      if (this.config.post) {
        currentPost = this.config.post
          .split(",")
          .map((n) => parseInt(n))
          .filter(Boolean);
      }
      currentPost.push(...this.form.post);
      currentPost = [...new Set(currentPost)];
      this.$http
        .post("/wechat/config", {
          key: "post",
          value: currentPost.join(","),
        })
        .then((res) => {
          this.submitting = false;
          this.isShow = false;
          if (res.code === 200) {
            this.$message.success("保存成功");
            this.getData().then(() => {
              this.getPost(this.config.post);
            });
            return;
          }
          this.$message.error(res.message);
        });
    },
    getData() {
      return this.$http.get("/wechat/config").then((res) => {
        let config = {};
        res.data.forEach((n) => {
          let { key, value } = n;
          config[key] = value;
        });
        this.config = config;
      });
    },
    onchange(val) {
      this.$http
        .post("/wechat/config", {
          key: "banner",
          value: val,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("上传成功 !");
            return;
          }
          this.$message.error(res.message);
        });
    },
    onchangeAbout() {
      this.$http
        .post("/wechat/config", {
          key: "about",
          value: this.config.about,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("提交成功 !");
            return;
          }
          this.$message.error(res.message);
        });
    },
  },
  mounted() {
    this.getData().then(() => {
      this.getPost(this.config.post);
    });
    this.getAgent();
    this.setTitle()
  },
};
</script>

<style lang="less">
.ui-config {
  &-content {
    border: 2px solid #eceff3;
    padding: 20px;
  }

  .ui-block {
    border-bottom: 1px solid #ebebeb;
    padding: 20px 0 30px;
    h5 {
      margin: 0 0 15px;
    }
  }
}
</style>
