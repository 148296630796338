<template>
  <div>
    <UiTable
      :columns="recordColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      :ctrlWidth="180"
      @page-change="onPageChange"
    >
      <template #ctrlHeader>
        <el-button type="primary" @click="showNew" size="mini">
          创建记录
        </el-button>
        <el-button type="success" @click="isShowReminder = true" size="mini">
          创建提醒
        </el-button>
      </template>
    </UiTable>

    <el-dialog
      title="沟通记录"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="recordColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <UiReminder :visible.sync="isShowReminder"></UiReminder>
  </div>
</template>

<script>
import UiReminder from '../../components/Reminder.vue'
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  components: {
    UiReminder
  },
  computed: {
    postId() {
      return parseInt(this.$route.params.id)
    }
  },
  data() {
    return {
      isShowReminder: false,
      queryUrl: '/post/record/list',
      tableCtrls: [
        {
          label: '修改',
          click: item => {
            this.currentItem = {
              ...item,
              recordDate: new Date(item.recordDate),
              adviserId: Array.isArray(item.adviserId)
                ? item.adviserId
                : item.adviserId.split(',').map(n => parseInt(n))
            }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              this.$http
                .get('/post/record/del', {
                  params: {
                    id: item.id
                  }
                })
                .then(ret => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          }
        }
      ],
      tableHeight: window.innerHeight - 190
    }
  },
  methods: {
    showNew() {
      this.currentItem = {
        recordDate: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
        adviserId: [this.userInfo.id]
      }
      this.isShow = true
    },
    onSubmit(form) {
      let postData = {
        ...form,
        postId: this.postId
      }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      if (typeof postData.recordDate !== 'string') {
        postData.recordDate = this.$dayjs(postData.recordDate).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      }
      this.$http.post('/post/record/save', postData).then(ret => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    }
  },
  mounted() {
    this.query({
      postId: this.postId
    })
  }
}
</script>

<style></style>
