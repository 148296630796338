<template>
  <div class>
    <UiQuery
      :items="positionColumns"
      :defaultParams="defaultParams"
      :ctrls="ctrls"
      :rightCtrls="rightCtrls"
    ></UiQuery>
    <UiTable
      :columns="positionColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
      @onSortChange="onSortChange"
    ></UiTable>
    <el-dialog
      title="岗位信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="positionColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  data() {
    return {
      queryUrl: '/post/list',
      rightCtrls: [
        {
          label: '创建岗位',
          float: 'right',
          plain: false,
          click: () => {
            this.isShow = true
          }
        }
      ],
      tableCtrls: [
        {
          label: '详情',
          type: 'info',
          click: item => {
            // this.$router.push({ path: `/position/${item.id}` })
            this.openLink(`/position/${item.id}/info`)
          }
        },
        {
          label: '修改',
          role: [1, 6, 7, 8, 9, 10, 11],
          click: item => {
            let { roleId, nickname } = this.userInfo
            if ([9, 10, 11].includes(roleId) && item.creator !== nickname) {
              this.$message.error('无权限操作')
              return
            }
            this.currentItem = { ...item }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          role: [1],
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              this.$http
                .get('/post/del', {
                  params: {
                    id: item.id
                  }
                })
                .then(ret => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          }
        }
      ]
    }
  },
  methods: {
    onSubmit(form) {
      let postData = { ...form }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      } else {
        postData = this.formatObj(postData)
      }
      this.$http.post('/post/save', postData).then(ret => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    }
  },
  mounted() {
    // 读取默认参数
    const key = `path${this.$route.path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    }
    this.query({ ...this.defaultParams, ...this.$route.query })
    this.setTitle()
  }
}
</script>

