<template>
  <div class="ui-address">
    <div class="" v-if="content">
      <div class="" style="margin: 0 0 16px">
        {{ formatAddress(content) }}
        <el-button
          type="primary"
          plain=""
          icon="el-icon-edit"
          circle
          size="mini"
          @click="content = ''"
        ></el-button>
      </div>
      <div class="">
        {{ formatAddress(content, 'detail') }}
      </div>
    </div>
    <template v-else>
      <el-cascader
        style="width: 100%; margin: 0 0 15px"
        v-model="area"
        :options="options"
        :props="props"
        placeholder="请选择行政区"
        filterable
      ></el-cascader>
      <el-input v-model="detail" placeholder="详细地址"></el-input>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default() {
        return ''
      },
    },
    formDefault: {
      type: Object,
    },
    prop: {
      type: String,
      default: 'address',
    },
  },
  data() {
    return {
      isInited: false,
      content: '',
      area: [],
      detail: '',
      props: {
        expandTrigger: 'click',
        lazy: true,
        lazyLoad: (node, resolve) => {
          let { level, label } = node
          // 第一层
          if (level === 0) {
            resolve(
              ['中华人民共和国', '海外地区'].map((n) => {
                return {
                  label: n,
                  value: n,
                }
              })
            )
            return
          }
          if (level === 1 && label === '海外地区') {
            resolve(
              [
                '加拿大',
                '澳大利亚',
                '东南亚',
                '欧洲',
                '美国',
                '日韩',
                '中东',
              ].map((n) => {
                return {
                  label: n,
                  value: n,
                  leaf: true,
                }
              })
            )
            return
          }
          let params = { keywords: label }
          this.$http.get('/district', { params }).then((res) => {
            if (res.districts && res.districts.length) {
              resolve(
                res.districts[0].districts
                  .map((n) => {
                    return {
                      label: n.name,
                      value: n.name,
                      leaf: level === 2,
                    }
                  })
                  .sort((a, b) => {
                    return [
                      '广东省',
                      '北京市',
                      '上海市',
                      '深圳市',
                      '广州市',
                    ].includes(b.label)
                      ? 1
                      : -1
                  })
              )
            }
          })
        },
      },
      options: [],
    }
  },
  watch: {
    formDefault: {
      immediate: true,
      handler(val) {
        if (val && val[this.prop] && this.isInited === false) {
          this.content = val[this.prop]
          this.isInited = true
        }
      },
    },
    area: {
      handler(val) {
        this.$emit('input', [...val, this.detail].join(' '))
      },
    },
    detail: {
      handler(val) {
        this.$emit('input', [...this.area, val].join(' '))
      },
    },
    // value: {
    //   handler(val) {
    //     this.content = val
    //   }
    // }
  },
  methods: {
    setValue(val) {
      this.content = val
    },
    formatAddress(content, type = 'city') {
      let c = content.split(' ')
      if (type === 'city') {
        return c.slice(0, 3).join(' ')
      }
      return c.slice(3).join('')
    },
  },
  created() {
    this.content = this.value
  },
}
</script>
