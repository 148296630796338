/**
 * 账号字段
 */
export default function (context) {
  return [
    {
      label: '推荐客户',
      prop: 'companyId',
      type: 'select',
      rule: 'select',
      disabled: () => {
        return true
      },
      valueKey: 'id',
      include: ['form', 'query'],
      source: () => {
        return context.$http
          .get('/client/list', {
            params: {
              pageSize: 10000,
              id: context.currentItem.companyId,
            },
          })
          .then(ret => {
            context.companyList = ret.data.list
            return ret.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
                key: n.id,
                obj: n,
              }
            })
          })
      },
      // relate: [
      //   {
      //     prop: 'contactId',
      //     source: item => {
      //       if (!item.companyId) {
      //         return Promise.resolve([])
      //       }
      //       return context.$http
      //         .get('/client/user/list', {
      //           params: {
      //             companyId: item.companyId.id
      //           }
      //         })
      //         .then(ret => {
      //           if (ret.code === 200) {
      //             return ret.data.list.map(n => {
      //               return {
      //                 label: n.name,
      //                 value: n,
      //                 key: n.id,
      //                 obj: n
      //               }
      //             })
      //           }
      //         })
      //     }
      //   },
      //   {
      //     prop: 'recommendId',
      //     source: item => {
      //       return context.waitUntil(() => {
      //         if (context.companyList && context.companyList.length) {
      //           const company = context.companyList.find(
      //             n => n.id === item.companyId.id
      //           )
      //           let ret = []
      //           if (company) {
      //             const { follower, adviserList } = company
      //             ret = [
      //               {
      //                 label: follower.name,
      //                 value: follower.id
      //               }
      //             ]
      //             if (adviserList && adviserList.length) {
      //               adviserList.forEach(n => {
      //                 ret.push({
      //                   label: n.name,
      //                   value: n.id
      //                 })
      //               })
      //             }
      //           }
      //           return ret
      //         }
      //         return false
      //       })
      //     }
      //   }
      // ]
    },
    {
      label: '推荐时间',
      prop: 'recommendDate',
      type: 'date',
      include: ['form'],
    },
    {
      label: '客户联系人',
      prop: 'contactId',
      type: 'select',
      rule: 'select',
      valueKey: 'id',
      include: ['form'],
      source: () => {
        if (
          context.currentItem &&
          context.currentItem.company &&
          context.currentItem.company.id
        ) {
          return context.$http
            .get('/client/user/list', {
              params: {
                companyId: context.currentItem.company.id,
              },
            })
            .then(ret => {
              if (ret.code === 200) {
                return ret.data.list.map(n => {
                  return {
                    label: n.name,
                    value: n.id,
                    key: n.id,
                    obj: n,
                  }
                })
              }
            })
        }
        return Promise.resolve([])
      },
      relate: [
        {
          prop: 'email',
          setValue: (form, item) => {
            let result = ''
            if (item.options) {
              const ret = item.options.find(n => n.value === form.contactId)
              if (ret && ret.obj && ret.obj.email) {
                result = ret.obj.email
              }
            }
            return Promise.resolve(result)
          },
        },
      ],
    },
    {
      label: '推荐顾问',
      prop: 'recommendId',
      rule: 'select',
      type: 'select',
      include: ['form'],
      source: () => {
        return Promise.resolve(context.userList)
      },
    },
    {
      label: '学历及工作背景简介',
      prop: 'backIntro',
      type: 'textarea',
      rule: 'text',
      include: ['form'],
    },
    {
      label: '能力匹配',
      prop: 'skill',
      type: 'textarea',
      rule: 'text',
      include: ['form'],
    },
    {
      label: '优势劣势及软性评估',
      prop: 'assess',
      type: 'textarea',
      include: ['form'],
    },
    {
      label: '求职动机及到岗时间',
      prop: 'intention',
      type: 'textarea',
      rule: 'text',
      include: ['form'],
    },
    {
      label: '薪资详情及期望',
      prop: 'expectation',
      type: 'textarea',
      rule: 'text',
      include: ['form'],
    },
    {
      label: '其他补充内容',
      prop: 'otherContent',
      type: 'textarea',
      include: ['form'],
    },
    {
      label: '是否发送邮件',
      prop: 'isSend',
      type: 'switch',
      options: [
        {
          label: '是',
          value: 1,
        },
        {
          label: '否',
          value: 0,
        },
      ],
      include: ['form'],
    },
    {
      label: '客户邮箱',
      prop: 'email',
      type: 'input',
      include: ['form'],
    },
    {
      label: '邮件模板',
      prop: 'emailTemplate',
      rule: 'select',
      type: 'select',
      include: ['form'],
      source: () => {
        return context.$http
          .get('/email/list', {
            params: {
              pageSize: 10000,
              scene: 686,
            },
          })
          .then(ret => {
            context.emailTemplateList = ret.data.list
            console.log('context.currentItem', context.currentItem)
            context.$set(
              context.currentItem,
              'emailTemplate',
              ret.data.list[0].id
            )
            return ret.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
                key: n.id,
                obj: n,
              }
            })
          })
      },
    },
    {
      label: '抄送邮箱',
      prop: 'copyEmail',
      type: 'input',
      include: ['form'],
    },
    {
      label: '选择简历',
      prop: 'resumeId',
      type: 'select',
      include: ['form'],
      source: () => {
        return context.$http
          .get('/talent/resume/list', {
            params: {
              talentId: context.currentItem.talentId,
            },
          })
          .then(res => {
            return res.data.list.map(n => {
              return {
                label: n.resumeName,
                value: n.id,
                key: n.id,
                obj: n,
              }
            })
          })
      },
    },
    {
      label: '其他信息',
      prop: 'otherInfo',
      type: 'textarea',
      include: ['form'],
    },
  ]
}
