/**
 * 教育经历字段
 */
export default function (context) {
  return [
    {
      label: '起始时间',
      props: ['startDate', 'endDate'],
      type: 'monthrange',
      rule: 'date',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      include: ['form']
    },
    {
      label: '开始时间',
      prop: 'startDate',
      type: 'date',
      format: 'YYYY-MM',
      include: ['table']
    },
    {
      label: '结束时间',
      prop: 'endDate',
      type: 'date',
      format: 'YYYY-MM',
      include: ['table']
    },
    {
      label: '学历',
      prop: 'edu',
      rule: 'text',
      optionKey: 'education',
      type: 'select',
      include: ['form', 'table'],
      source: () => {
        return Promise.resolve(context.enumOptions.education)
      }
    },
    {
      label: '毕业院校',
      prop: 'object',
      rule: 'text',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '专业',
      prop: 'profession',
      rule: 'text',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '备注',
      prop: 'remark',
      type: 'textarea',
      include: ['form', 'table']
    }
  ]
}
