<template>
  <div class="" :id="id"></div>
</template>

<script>
import { Column } from '@antv/g2plot'
export default {
  props: {
    data: Array
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.init(val)
        }
      }
    }
  },
  data() {
    return {
      id:
        'id-' +
        Math.random()
          .toString(36)
          .slice(-6)
    }
  },
  methods: {
    init(data) {
      // const paletteSemanticRed = '#F4664A'
      // const brandColor = '#5B8FF9'
      const columnPlot = new Column(this.id, {
        appendPadding: 10,
        data,
        xField: 'type',
        yField: 'value',
        meta: {
          value: {
            alias: '数量'
          }
        },
        legend: {
          layout: 'horizontal',
          position: 'right'
        }
      })

      columnPlot.render()
    }
  }
}
</script>

<style></style>
