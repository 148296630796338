<template>
  <el-dialog title="操作日志" :visible.sync="isShow" width="80%">
    <div></div>
  </el-dialog>
</template>

<script>
export default {
  name: 'UiLogTable',
  props: {
    visible: Boolean
  },
  watch: {
    visible(val) {
      this.isShow = val
    },
    isShow(val) {
      this.$emit('update:visible', val)
    }
  },
  data() {
    return {
      isShow: false
    }
  }
}
</script>

<style></style>
