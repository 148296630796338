<template>
  <div class="ui-share">
    <div class="inner">
      <div class="banner" :style="bg"></div>
      <div class="h5">
        {{ content.title }}
      </div>
      <div class="h4">
        {{ content.content }}
      </div>
      <div class="list">
        <div class="list-item" v-for="item in labels" :key="item.key">
          <div class="list-value">
            {{ dataFormat(content[item.key], item) }}
          </div>
          <div class="list-title">{{ item.label }}</div>
        </div>
      </div>
      <div class="code">
        <el-row type="flex" align="middle">
          <el-col :span="11" :offset="0">
            <img :src="code" alt="" class="qrcode" />
          </el-col>
          <el-col :span="13" :offset="0">
            <p>长按识别小程序码</p>
            <p>加入社区报名参与</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footer">
      <img src="../../assets/logo-white.png" alt="" />
    </div>
  </div>
</template>

<script>
import mixin from '../../common/index'
import qs from 'qs'
export default {
  mixins: [mixin],
  computed: {
    bg() {
      const url = this.content.sector_id?.image?.url
      return {
        backgroundImage: `url(${url})`,
      }
    },
    url() {
      return this.$route.query.url
    },
    code() {
      return `/share/getwxacodeunlimit?url=${encodeURIComponent(this.url)}`
    },
  },
  data() {
    return {
      baseUrl: 'https://community.rockmind.cn',
      id: '',
      content: {},
      labels: [
        {
          label: '类型',
          key: 'tags',
          format: data => {
            if (data && data.length) {
              const obj = {
                3: '饭局',
                4: '爬山',
              }
              return obj[data[0].id]
            }
            return ''
          },
        },
        {
          label: '日期',
          key: 'start_time',
          format: data =>
            data ? this.$dayjs(data).format('YYYY-MM-DD HH:mm') : '',
        },
        {
          label: '人数限制',
          key: 'limit',
        },
        {
          label: '活动地点',
          key: 'location',
        },
      ],
    }
  },
  methods: {
    dataFormat(content, item) {
      return item.format ? item.format(content) : content
    },
    async getData() {
      let [, query] = this.url.split('?')
      const queryObj = {}
      query.split('&').forEach(n => {
        const [key, val] = n.split('=')
        queryObj[key] = val
      })
      const url = this.baseUrl + '/api/cm-posts/' + queryObj.postId
      const ret = await this.$http.get(url, {
        params: {
          populate: {
            sector_id: {
              populate: '*',
            },
            tags: {
              populate: '*',
            },
          },
          filters: {
            is_deleted: false,
          },
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
        headers: {
          Authorization: `Bearer ${this.masterKey}`,
        },
      })
      this.content = ret.data
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="less">
.ui-share {
  max-width: 640px;
  margin: 0 auto;
  background: #ecb745;
  overflow: hidden;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;

  .banner {
    height: 170px;
    background-position: center;
    background-size: cover;

    img {
      height: 100%;
    }
  }

  .list {
    &-item {
      padding: 8px 15px;
    }
    &-value {
      float: right;
    }
  }

  .footer {
    color: #fff;
    text-align: center;
    padding: 0 0 15px;
    font-size: 10px;

    img {
      display: block;
      width: 30%;
      margin: 0 auto;
    }

    h5 {
      font-size: 14px;
      margin: 0;
    }
  }

  .code {
    margin: 0 30px;
    padding: 20px 0;
    line-height: 1.5;
  }

  .h5 {
    white-space: nowrap;
    font-weight: bold;
    padding: 20px 15px 0;
  }
  .h4 {
    padding: 20px 15px 15px;
    line-height: 1.5;
    max-height: 111px;
    overflow: hidden;
  }

  .qrcode {
    width: 90px;
    height: 90px;
  }

  .tag {
    margin: 0 30px;
    height: 70px;
    overflow: hidden;
    padding: 0 0 20px;
    border-bottom: 1px solid #bbb;

    .el-tag {
      margin: 0 10px 10px 0;
      color: #fff;
      background: #343434;
    }
  }

  .inner {
    margin: 20px 10px 10px;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  img {
    max-width: 100%;
  }
}
</style>
