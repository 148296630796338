<template>
  <div>
    <UiTable
      :columns="positionColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    >
      <template #ctrlHeader>
        <el-button type="primary" @click="isShow = true" size="small">
          关联岗位
        </el-button>
      </template>
    </UiTable>

    <el-dialog
      title="关联岗位"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="projectPositionColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    projectId() {
      return parseInt(this.$route.params.id)
    }
  },
  data() {
    return {
      queryUrl: '/post/list',
      tableCtrls: [
        {
          label: '修改',
          click: item => {
            this.currentItem = { ...item }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              this.$http
                .get('/talent/experience/del', {
                  params: {
                    id: item.id
                  }
                })
                .then(ret => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          }
        }
      ],
      tableHeight: window.innerHeight - 190
    }
  },
  methods: {
    onSubmit(form) {
      let postData = {
        ...form,
        projectId: this.projectId
      }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      this.$http.post('/project/post/save', postData).then(ret => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    }
  },
  mounted() {
    this.query({
      projectId: this.projectId
    })
  }
}
</script>

<style></style>
