/**
 * 岗位与人才关联字段
 */
export default function (context) {
  return [
    {
      label: '推荐信息',
      type: 'divide',
      include: ['form']
    },
    {
      label: '推荐状态',
      prop: 'recommendStatus',
      type: 'select',
      rule: 'select',
      include: ['form'],
      formWidth: 24,
      optionKey: 'recommendStatus'
    },
    {
      label: '负责人',
      prop: 'followerId',
      rule: 'select',
      type: 'select',
      include: ['form'],
      formWidth: 24,
      source: () => Promise.resolve(context.userList)
    },
    {
      label: 'Offer信息',
      type: 'divide',
      include: ['form']
    },
    {
      label: 'Offer时间',
      prop: 'offerDate',
      type: 'date',
      include: ['form']
    },
    {
      label: 'offer薪资',
      prop: 'offerSalary',
      type: 'input',
      formWidth: 24,
      include: ['form']
    },

    {
      label: 'Offer详情',
      prop: 'offerInfo',
      type: 'textarea',
      formWidth: 24,
      include: ['form']
    },
    {
      label: '入职信息',
      type: 'divide',
      include: ['form']
    },
    {
      label: '入职时间',
      prop: 'entryDate',
      type: 'date',
      include: ['form']
    },
    {
      label: '入职备注',
      prop: 'entryRemark',
      formWidth: 24,
      type: 'textarea',
      include: ['form']
    }
  ]
}
