<template>
  <div class="">
    <UiQuery
      :items="itemColumn"
      :defaultParams="defaultParams"
      :ctrls="ctrls"
    ></UiQuery>

    <el-row :gutter="20" class="ui-datalist">
      <el-col :span="12" v-for="item in dataList" :key="item.label">
        <el-card class="box-card" shadow="hover">
          <div class="sub-title">
            <span>{{ item.label }}</span>
          </div>
          <template v-if="item.type === 'pie'">
            <G2Pie :data="item.data"></G2Pie>
          </template>

          <template v-if="item.type === 'column'">
            <G2Column :data="item.data"></G2Column>
          </template>
        </el-card>
      </el-col>
    </el-row>

    <div
      class="ui-datalist-wrap"
      v-loading="pageLoading"
      element-loading-text="数据统计中, 请稍后"
      v-if="pageLoading"
    ></div>
  </div>
</template>

<script>
import G2Pie from '../../components/G2Pie.vue'
import G2Column from '../../components/G2Column.vue'
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  components: {
    G2Pie,
    G2Column
  },
  computed: {
    itemColumn() {
      return this.dataColumns.filter(
        n => n.prop === 'followerId' || n.prop === 'talentType'
      )
    }
  },
  data() {
    return {
      pageLoading: false,
      ctrls: [
        {
          label: '查询',
          click: params => {
            this.getData(params)
          }
        }
      ],
      dataList: [
        {
          label: '性别',
          value: 'sex',
          type: 'pie',
          loading: false,
          data: []
        },
        {
          label: '年龄',
          type: 'pie',
          value: 'ages',
          loading: false,
          data: []
        },
        {
          label: '地点',
          type: 'pie',
          value: 'address',
          loading: false,
          data: []
        },
        {
          label: '学历',
          type: 'pie',
          value: 'edu',
          loading: false,
          data: []
        },
        {
          label: '人才层级',
          type: 'pie',
          value: 'jobTitle',
          loading: false,
          data: []
        },
        {
          label: '模块分类',
          type: 'pie',
          value: 'moduleType',
          loading: false,
          data: []
        },
        {
          label: '所在行业',
          type: 'pie',
          value: 'industry',
          loading: false,
          data: []
        },
        {
          label: '求职状态',
          type: 'pie',
          value: 'jobStatus',
          loading: false,
          data: []
        }
      ]
    }
  },
  methods: {
    getData(params) {
      this.pageLoading = true
      this.$http
        .get('/dateset/talent/list', {
          params
        })
        .then(res => {
          let keys = Object.keys(res.data)
          keys.forEach(key => {
            let item = this.dataList.find(n => n.value === key)
            if (item) {
              item.data = res.data[key]
            }
          })
          this.pageLoading = false
        })
    }
  },
  mounted() {
    this.getData()
    this.setTitle()
  }
}
</script>
<style lang="less">
.ui-datalist {
  &-wrap {
    min-height: 500px;
    position: fixed;
    bottom: 0;
    right: 0;
    top: 51px;
    left: 66px;
    z-index: 100;
  }
  .el-col {
    margin-bottom: 20px;
  }
  .sub-title {
    font-size: 20px;
    color: #666;
  }
  .box-card {
    height: 465px;
  }
}
</style>
