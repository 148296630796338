/**
 * 账号字段
 */
export default function (context) {
  return [
    {
      label: '姓名',
      prop: 'name',
      sortable: true,
      fixed: 'left',
      type: 'input',
      rule: 'text',
      include: ['query', 'table', 'form']
    },
    {
      label: 'ID',
      prop: 'id',
      include: ['table']
    },
    {
      label: '角色',
      prop: 'roleId',
      type: 'select',
      rule: 'select',
      include: ['query', 'table', 'form'],
      optionKey: 'roles',
      source: () => {
        return Promise.resolve(context.roles)
      }
    },
    {
      label: '手机号',
      prop: 'nickname',
      sortable: true,
      type: 'input',
      rule: 'mobile',
      disabled: () => {
        return !!context.currentItem.id
      },
      include: ['query', 'table', 'form']
    },
    {
      label: '密码',
      prop: 'password',
      placeholder: '不再显示, 请牢记',
      type: 'input',
      include: ['form']
    },
    {
      label: '邮箱',
      prop: 'email',
      type: 'input',
      include: ['table', 'form']
    },
    {
      label: '邮箱安全密码',
      prop: 'emailPassword',
      tooltip:
        '登录企业邮箱-设置-邮箱绑定-开启安全登录-生成新密码，获取安全密码',
      type: 'input',
      include: ['form']
    },

    {
      label: '备注',
      prop: 'remark',
      type: 'textarea',
      include: ['table', 'form']
    },
    {
      label: '修改人',
      prop: 'modifier',
      sortable: true,
      type: 'input',
      include: ['table']
    },
    {
      label: '修改时间',
      prop: 'modifiedAt',
      sortable: true,
      type: 'date',
      include: ['table']
    },
    {
      label: '状态',
      prop: 'status',
      type: 'select',
      rule: 'select',
      include: ['query'],
      source: () => {
        return Promise.resolve([
          {
            label: '启用',
            value: 1
          },
          {
            label: '停用',
            value: 2
          }
        ])
      }
    }
  ]
}
