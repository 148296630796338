<template>
  <el-form
    :model="form"
    ref="form"
    label-width="100px"
    size="mini"
    label-position="left"
    style="padding: 20px; margin: 0 auto"
    class="ui-info"
  >
    <el-row :gutter="40">
      <template v-for="item in formItems">
        <el-col
          :span="item.type === 'divide' ? 24 : 8"
          :key="item.prop || item.label"
        >
          <template v-if="item.type === 'divide'">
            <div
              style="border-top: 1px solid #eee;margin: 20px 0 0;"
            >
              <div
                class="ui-sub-title"
                :key="item.label"
                style="margin: 30px 0 30px;"
              >
                {{ item.label }}
              </div>
            </div>
          </template>
          <template v-else>
            <el-form-item :label="item.label">
              <!-- 标签 -->
              <template v-if="item.showType === 'tag'">
                <el-tag
                  v-for="t in format(item, form)"
                  :key="t"
                  size="small"
                  style="margin: 0 10px 0 0"
                >
                  {{ t }}
                </el-tag>
              </template>

              <!-- 状态 -->
              <template v-else-if="item.showType === 'status'">
                <el-tag
                  size="small"
                  style="margin: 0 10px 0 0"
                  :type="format(item, form).sign"
                  v-if="format(item, form)"
                >
                  {{ format(item, form).label }}
                </el-tag>
              </template>

              <template v-else-if="item.showType === 'raw'">
                {{ form[item.prop] }}
              </template>

              <template v-else>
                {{ format(item, form) }}
              </template>
            </el-form-item>
          </template>
        </el-col>
      </template>
    </el-row>
  </el-form>
</template>
<script>
import mixin from '../common/compoment'
export default {
  name: 'UiList',
  mixins: [mixin],
  props: {
    formData: Object,
    formItem: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    form() {
      return this.formData
    },
    formItems() {
      return this.formItem.filter(item => {
        return item.include.includes('show')
      })
    }
  },
  data() {
    return {
      isShowDetail: false
    }
  }
}
</script>
