<template>
  <UiList :formItem="formItem" :formData="form"></UiList>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  props: {
    form: Object,
    formItem: Array
  },
  data(){
    return {
    }
  }
}
</script>

<style>

</style>