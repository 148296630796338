/**
 * 角色字段
 */
export default function (context) {
  return [
    {
      label: 'ID',
      prop: 'id',
      include: [ 'table']
    },
    {
      label: '角色名称',
      prop: 'name',
      sortable: true,
      type: 'input',
      rule: 'text',
      include: ['query', 'table', 'form']
    },
    {
      label: '标识',
      prop: 'sign',
      type: 'input',
      rule: 'text',
      include: ['table', 'form']
    },
    {
      label: '权限',
      prop: 'authority',
      type: 'multiSelect',
      rule: 'multiSelect',
      tableType: 'tag',
      optionKey: 'authority',
      include: ['query', 'table', 'form'],
      source: () => {
        return Promise.resolve(context.enumOptions.authority)
      }
    },
    {
      label: '备注',
      prop: 'remark',
      type: 'textarea',
      include: ['table', 'form']
    },
    {
      label: '修改人',
      prop: 'modifier',
      sortable: true,
      type: 'input',
      include: ['table']
    },
    {
      label: '修改时间',
      prop: 'modifiedAt',
      sortable: true,
      type: 'date',
      include: ['table']
    }
  ]
}
