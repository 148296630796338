/**
 * 简历字段
 */
export default function(context) {
  return [
    {
      label: '简历名称',
      prop: 'resumeName',
      rule: 'text',
      fixed: 'left',
      type: 'input',
      formWidth: 24,
      include: ['form', 'table'],
      click: item => {
        context.$router.push({ path: `/position/${item.id}` })
      },
    },
    {
      label: '简历附件',
      prop: 'file',
      type: 'upload',
      rule: 'select',
      tableType: 'file',
      include: ['form', 'table'],
      show: () => {
        return !context.currentItem.id
      },
      format: item => {
        return encodeURIComponent(item.file)
      },
    },
    {
      label: '创建时间',
      prop: 'createdAt',
      include: ['table'],
    },
  ]
}
