/**
 * 人才字段
 */
export default function (context) {
  return [
    {
      label: '基本信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '姓名',
      prop: 'name',
      rule: 'text',
      tableType: 'link',
      type: 'input',
      fixed: 'left',
      include: ['table', 'form', 'query'],
      click: item => {
        context.openLink(`/candidate/${item.id}/info`)
        // context.$router.push({ path: `/candidate/${item.id}/info` })
      },
    },
    {
      label: '性别',
      prop: 'sex',
      rule: 'select',
      type: 'select',
      optionKey: 'gender',
      include: ['table', 'form'],
    },
    {
      label: '手机号码',
      prop: 'mobile',
      rule: 'text',
      type: 'input',
      include: ['form', 'show', 'query'],
    },
    {
      label: '邮箱',
      prop: 'email',
      type: 'input',
      include: ['form', 'show', 'query'],
    },
    {
      label: '微信',
      prop: 'wechat',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '出生年份',
      prop: 'year',
      type: 'input',
      include: ['form', 'show', 'table'],
    },
    {
      label: '所在地',
      prop: 'address',
      type: 'address',
      include: ['table', 'form', 'show', 'query'],
    },
    {
      label: '籍贯',
      prop: 'hometown',
      type: 'input',
      include: ['table', 'form', 'show'],
    },
    {
      label: '最高学历',
      prop: 'edu',
      type: 'select',
      rule: 'select',
      optionKey: 'education',
      include: ['form', 'show', 'table', 'query'],
    },
    {
      label: '工作语言',
      prop: 'language',
      type: 'input',
      include: ['form', 'show'],
    },
    {
      label: '政治面貌',
      prop: 'political',
      type: 'select',
      optionKey: 'political',
      include: ['form', 'show'],
    },
    {
      label: '婚姻状态',
      prop: 'maritalStatus',
      type: 'select',
      optionKey: 'maritalStatus',
      include: ['form', 'show'],
    },
    {
      label: '人才标签',
      prop: 'label',
      tableType: 'tagGroup',
      tagType: 'talent',
      showType: 'tag',
      optionKey: 'tag',
      type: 'tagGroup',
      include: ['form', 'show', 'query'],
    },
    {
      label: '跟进信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '负责人',
      prop: 'follower',
      include: ['table', 'show'],
    },
    {
      label: '负责人',
      prop: 'followerId',
      type: 'select',
      include: ['form', 'show', 'query'],
      source: () => {
        return context.waitUntil(() => {
          if (context.userList && context.userList.length) {
            return context.userList
          }
          return false
        })
      },
    },

    {
      label: '来源',
      prop: 'source',
      type: 'select',
      include: ['table', 'form', 'show'],
      optionKey: 'candidateSource',
    },
    {
      label: '是否保密',
      prop: 'isConfidential',
      type: 'select',
      include: ['form'],
      options: [
        {
          label: '是',
          value: 1,
        },
        {
          label: '否',
          value: 0,
        },
      ],
      show: () => {
        return context.userInfo.roleId === 1
      },
    },
    {
      label: '职业信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '人才层级',
      prop: 'jobTitle',
      type: 'select',
      include: ['table', 'form', 'show', 'query'],
      optionKey: 'jobTitle',
      rule: 'select',
    },
    {
      label: '职业类型',
      prop: 'moduleType',
      type: 'select',
      include: ['table', 'form', 'show', 'query'],
      rule: 'select',
      optionKey: 'moduleType',
    },
    {
      label: '求职状态',
      prop: 'jobStatus',
      type: 'select',
      include: ['table', 'form', 'show', 'query'],
      rule: 'select',
      optionKey: 'jobStatus',
    },
    {
      label: '所在行业',
      prop: 'industry',
      type: 'select',
      include: ['table', 'form', 'show', 'query'],
      rule: 'select',
      optionKey: 'jobIndustry',
    },
    {
      label: '目前岗位',
      prop: 'post',
      type: 'input',
      include: ['table', 'form', 'show'],
    },
    {
      label: '工作岗位',
      prop: 'post',
      type: 'input',
      include: ['query'],
    },
    {
      label: '薪水区间',
      prop: 'salaryRange',
      type: 'select',
      include: ['table', 'form', 'show'],
      rule: 'select',
      optionKey: 'salaryRange',
    },
    {
      label: '工作经历',
      prop: 'talentExperience',
      include: ['table'],
      format: item => {
        if (item.talentExperience && item.talentExperience.length) {
          const obj = item.talentExperience.find(n => n.type === 2)
          return obj ? obj.object + '-' + obj.post : ''
        }
        return ''
      },
    },
    {
      label: '简历数量',
      prop: 'resume',
      include: ['table'],
      format: item => item.resume.length,
    },
    {
      label: '沟通次数',
      prop: 'talentRecord',
      type: 'input',
      include: ['table'],
      format: item => item.talentRecord.length,
    },
    {
      label: '最近沟通时间',
      prop: 'lastRecordTime',
      type: 'input',
      include: ['table'],
    },
    {
      label: '备注',
      span: 2,
      prop: 'remark',
      include: [],
    },
    {
      label: '备注/介绍',
      span: 2,
      prop: 'mixFields',
      include: ['query'],
    },
    {
      label: '介绍',
      span: 2,
      prop: 'intro',
      type: 'textarea',
      include: ['form', 'show'],
    },
    {
      label: '教育/工作经历',
      prop: 'object',
      type: 'textarea',
      span: 4,
      include: ['query'],
    },
    {
      label: '其他备注',
      prop: 'remark',
      type: 'textarea',
      include: ['form', 'show'],
    },
    {
      label: '创建时间',
      prop: 'createdAt',
      include: ['table', 'show'],
    },
  ]
}
