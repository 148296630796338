'use strict'

import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// console.log('ba', window.location.hostname)

// let baseURL = window.location.hostname === 'localhost' ? 'http://127.0.0.1:7001' : ''

const baseURL = ''

const config = {
  baseURL,
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const { params } = config
    const newParams = {}
    for (const key in params) {
      const value = params[key]
      if (value !== '' && value !== null && value !== undefined) {
        newParams[key] = value
      }
    }
    config.params = newParams
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    const { status, data } = response
    if (status === 200) {
      return response.data
    }
    Message.error({
      message: data.message || '请求出错, 请重试 !',
    })
  },
  function (error) {
    // 登录超时
    if (error.response.status === 302) {
      if (window.location.hash !== '#/login') {
        window.localStorage.clear()
        window.location.reload()
      }
    }
    // Do something with response error
    return Promise.reject(error)
  }
)

const formatObj = obj => {
  const cleanObj = Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) => value !== null && value !== undefined && value !== ''
    )
  )
  return cleanObj
}

const Plugin = {}

Plugin.install = function (Vue) {
  Vue.$http = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
    $http: {
      get() {
        return _axios
      },
    },
    $formatObj: {
      get() {
        return formatObj
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
