/**
 * 开票字段
 */
export default function(context) {
  return [
    {
      label: '开票时间',
      prop: 'entryDate',
      type: 'date',
      include: ['form', 'table'],
      format: 'YYYY-MM-DD',
      fixed: 'left',
    },
    {
      label: '客户',
      prop: 'company',
      type: 'select',
      include: ['table'],
      tableType: 'link',
      fixed: 'left',
      source: () => {
        return context.$http
          .get('/client/list', {
            params: {
              pageSize: 10000,
              pageNo: 1,
            },
          })
          .then(ret => {
            context.companyList = ret.data.list
            return ret.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
              }
            })
          })
      },
      click: item => {
        context.openLink(`/client/${item.companyId}/info`)
        // context.$router.push({ path: `/client/${item.companyId}/info` })
      },
    },
    {
      label: '客户',
      prop: 'companyId',
      type: 'select',
      include: ['query'],
      tableType: 'link',
      source: () => {
        return context.$http
          .get('/client/list', {
            params: {
              pageSize: 10000,
              pageNo: 1,
            },
          })
          .then(ret => {
            context.companyList = ret.data.list
            return ret.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
              }
            })
          })
      },
    },
    {
      label: '人才',
      prop: 'talent',
      type: 'select',
      include: ['table'],
      tableType: 'link',
      fixed: 'left',
      click: item => {
        context.openLink(`/candidate/${item.talentId}/info`)
        // context.$router.push({ path: `/candidate/${item.talentId}/info` })
      },
    },
    {
      label: '客户负责人',
      prop: 'company',
      include: ['table'],
      format: item => {
        return item.company && item.company.follower
          ? item.company.follower.name
          : ''
      },
    },
    {
      label: '联系人',
      prop: 'contact',
      type: 'select',
      include: ['table'],
    },
    {
      label: '推荐人',
      prop: 'talentPost',
      include: ['table'],
      format: item => {
        return item.talentPost && item.talentPost.recommend
          ? item.talentPost.recommend.name
          : ''
      },
    },
    {
      label: '项目',
      prop: 'project',
      type: 'select',
      include: ['table'],
    },
    {
      label: '岗位',
      prop: 'post',
      type: 'select',
      include: ['table'],
      tableType: 'link',
      click: item => {
        context.openLink(`/position/${item.postId}/info`)
        // context.$router.push({
        //   path: `/position/${item.postId}/info`,
        // })
      },
    },

    {
      label: '开票状态',
      prop: 'status',
      type: 'select',
      optionKey: 'financeStatus',
      include: ['form', 'table'],
    },

    {
      label: '开票金额',
      prop: 'cost',
      type: 'input',
      rule: 'text',
      include: ['form', 'table'],
    },
    {
      label: '开票类型',
      prop: 'type',
      type: 'select',
      rule: 'select',
      optionKey: 'financeType',
      include: ['form', 'table'],
    },
    {
      label: '服务名称',
      prop: 'name',
      type: 'input',
      include: ['form', 'table'],
    },
    {
      label: '发票代码',
      prop: 'invoiceCode',
      type: 'input',
      include: ['form', 'table'],
    },
    {
      label: '寄送联系人',
      prop: 'contact',
      include: ['table'],
    },
    {
      label: '寄送联系人',
      prop: 'contactId',
      type: 'select',
      rule: 'select',
      valueKey: 'id',
      include: ['form'],
      source: () => {
        return context.$http
          .get('/client/user/list', {
            params: {
              companyId: context.currentItem.companyId,
            },
          })
          .then(ret => {
            if (ret.code === 200) {
              return ret.data.list.map(n => {
                return {
                  label: n.name,
                  value: n,
                  key: n.id,
                  obj: n,
                }
              })
            }
          })
      },
      relate: [
        {
          prop: 'mobile',
          setValue: form => {
            if (form.contactId && form.contactId.mobile) {
              return Promise.resolve(form.contactId.mobile)
            }
            return Promise.resolve('')
          },
        },
        {
          prop: 'address',
          setValue: form => {
            if (form.contactId && form.contactId.address) {
              return Promise.resolve(form.contactId.address)
            }
            return Promise.resolve('')
          },
        },
      ],
    },
    // {
    //   label: '联系人电话',
    //   prop: 'mobile',
    //   type: 'input',
    //   rule: 'text',
    //   include: ['form', 'table'],
    // },
    // {
    //   label: '联系人地址',
    //   prop: 'address',
    //   type: 'address',
    //   rule: 'text',
    //   include: ['form', 'table'],
    // },
    {
      label: '付款通知书',
      prop: 'costFiles',
      type: 'upload',
      tableType: 'file',
      include: ['form', 'table'],
      format: item => {
        return encodeURIComponent(item.costFiles)
      },
    },
    {
      label: '开票备注',
      prop: 'remark',
      type: 'textarea',
      placeholder: '如: 一次性付款/第一笔60%..',
      include: ['form', 'table'],
    },
    {
      label: '寄送情况',
      prop: 'deliveryStatus',
      type: 'textarea',
      formWidth: 24,
      include: ['form', 'table'],
    },
    {
      label: '收款情况',
      prop: 'payStatus',
      type: 'select',
      optionKey: 'payStatus',
      include: ['form', 'table'],
    },
    {
      label: '收款金额',
      prop: 'paySum',
      type: 'input',
      include: ['form', 'table'],
    },
    {
      label: '收款时间',
      prop: 'payDate',
      type: 'date',
      include: ['form', 'table'],
    },
    {
      content: '请核对以上开票信息，如无问题可点击开票',
      type: 'tips',
      include: ['form'],
      formWidth: 24,
    },
  ]
}
