<template>
  <div class="">
    <UiQuery
      :items="emailColumns"
      :ctrls="ctrls"
      :defaultParams="defaultParams"
      :rightCtrls="rightCtrls"
    >
    </UiQuery>
    <UiTable
      :columns="emailColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    >
      <template #ctrlHeader>
        <el-button type="info" size="mini" plain="" @click="isShowLog = true">
          操作日志
        </el-button>
      </template>
    </UiTable>

    <el-dialog
      title="模板信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="emailColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  data() {
    return {
      queryUrl: '/email/list',
      rightCtrls: [
        {
          label: '创建模板',
          float: 'right',
          plain: false,
          click: () => {
            this.isShow = true
          },
        },
      ],
      tableCtrls: [
        {
          label: '修改',
          click: (item) => {
            this.currentItem = {
              ...item,
            }
            this.isShow = true
          },
        },
        {
          label: '删除',
          type: 'danger',
          role: [1],
          click: (item) => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              this.$http
                .get('/email/del', {
                  params: {
                    id: item.id,
                  },
                })
                .then((ret) => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          },
        },
      ],
    }
  },
  methods: {
    onSubmit(form) {
      this.$http
        .post('/email/save', {
          ...form,
          ...(this.currentItem.id ? { id: this.currentItem.id } : {}),
        })
        .then((ret) => {
          if (ret.code === 200) {
            this.$message.success('提交成功 !')
            this.isShow = false
            this.query()
            return
          }
          this.$message.error(ret.message)
        })
    },
  },
  mounted() {
    this.query()
  },
}
</script>

<style>
</style>