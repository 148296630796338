/**
 * 工作经验字段
 */
export default function () {
  return [
    {
      label: '起始时间',
      props: ['startDate', 'endDate'],
      type: 'monthrange',
      rule: 'date',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      include: ['form']
    },
    {
      label: '开始时间',
      prop: 'startDate',
      type: 'date',
      format: 'YYYY-MM',
      include: ['table']
    },
    {
      label: '结束时间',
      prop: 'endDate',
      type: 'date',
      format: 'YYYY-MM',
      include: ['table']
    },
    {
      label: '公司名称',
      prop: 'object',
      type: 'input',
      rule: 'text',
      include: ['form', 'table']
    },
    {
      label: '部门',
      prop: 'department',
      type: 'input',
      include: ['form', 'table']
    },
    {
      label: '职位',
      prop: 'post',
      type: 'input',
      rule: 'text',
      include: ['form', 'table']
    },
    {
      label: '工作地点',
      prop: 'address',
      type: 'address',
      include: ['form', 'table']
    },
    {
      label: '工作内容',
      prop: 'wordContent',
      type: 'textarea',
      include: ['form', 'table']
    }
  ]
}
