<template>
  <div>
    <UiQuery
      :items="tableColumns"
      :ctrls="ctrls"
      :rightCtrls="rightCtrls"
      :defaultParams="defaultParams"
    >
    </UiQuery>
    <UiTable
      :columns="tableColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    >
    </UiTable>

    <el-dialog
      title="联系人信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="contactColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <UiMultiEmail :visible.sync="isShowEmail" :scene="688" emailType="contact"/>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    companyId() {
      return parseInt(this.$route.params.id)
    },
    tableColumns() {
      let columns = [
        {
          label: '客户名称',
          prop: 'company.name',
          fixed: 'left',
          include: ['table'],
          tableType: 'link',
          click: (item) => {
            this.$router.push({ path: `/client/${item.company.id}/info` })
          },
        },
        ...this.contactColumns,
      ]
      return columns
    },
  },
  data() {
    return {
      queryUrl: '/client/user/list',
      isShowEmail: false,
      rightCtrls: [
        {
          label: '发送邮件',
          float: 'right',
          plain: false,
          click: () => {
            this.isShowEmail = true
          },
        },
      ],
      tableCtrls: [
        {
          label: '修改',
          click: (item) => {
            this.currentItem = { ...item }
            this.isShow = true
          },
        },
        {
          label: '删除',
          type: 'danger',
          click: (item) => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              this.$http
                .get('/client/user/del', {
                  params: {
                    id: item.id,
                  },
                })
                .then((ret) => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          },
        },
      ],
      tableHeight: window.innerHeight - 190,
    }
  },
  methods: {
    onSubmit(form) {
      let postData = {
        ...form,
        companyId: this.companyId,
      }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      this.$http.post('/client/user/save', postData).then((ret) => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
  },
  mounted() {
    // 读取默认参数
    const key = `path${this.$route.path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    }
    this.query({ ...this.defaultParams, ...this.$route.query })
    this.setTitle()
  },
}
</script>