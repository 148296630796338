<template>
  <div class="">
    <template v-if="timeRange">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/data' }">
          数据报表
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/candidate' }">
          人才管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ timeRange.startDate }} 至 {{ timeRange.endDate }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <template v-else>
      <UiQuery
        :items="candidateColumns"
        :defaultParams="defaultParams"
        :ctrls="ctrls"
        :rightCtrls="rightCtrls"
        @on-complete="onComplete"
      ></UiQuery>
    </template>

    <UiTable
      :columns="candidateColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      showSelection
      @selection-change="onSelectionChange"
      @page-change="onPageChange"
    ></UiTable>
    <el-dialog
      title="人才信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="candidateColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        :showAnalysis="currentItem.id !== undefined"
        ref="form"
        @on-complete="onComplete"
        @cancel="isShow = false"
        @submit="onSubmit"
        v-if="isShow"
      ></UiForm>
    </el-dialog>

    <UiMultiEmail :visible.sync="isShowEmail" :selection="selection" />
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  name: 'Candidate',
  watch: {
    '$route.query': {
      handler(query, oldQuery) {
        if (oldQuery.startDate && !query.startDate) {
          location.reload()
        }
      },
    },
    '$route.path': {
      // immediate: true,
      handler(val) {
        console.log('val', val)
        if (!this.timeRange) {
          let talentType = this.enumOptions.talentType
          let t1 = talentType.find((n) => n.label === '互联网行业')
          if (val === '/candidate/type') {
            this.currentParams.talentType = t1.value
            this.defaultQueryParams.talentType = t1.value
            this.query()
          }
          if (val === '/candidate') {
            this.currentParams.talentType = -1
            this.defaultQueryParams.talentType = -1
            this.query()
          }
        }
      },
    },
  },
  computed: {
    timeRange() {
      if (this.$route.query.startDate && this.$route.query.endDate) {
        return {
          startDate: this.$route.query.startDate.substr(0, 10),
          endDate: this.$route.query.endDate.substr(0, 10),
        }
      }
      return false
    },
  },
  data() {
    return {
      selection: [],
      queryUrl: '/talent/candidate/list',
      defaultQueryParams: {},
      isShowEmail: false,
      rightCtrls: [
        {
          label: '创建人才',
          float: 'right',
          plain: false,
          click: () => {
            this.isShow = true
          },
        },
        {
          label: '导入简历',
          float: 'right',
          plain: false,
          type: 'upload',
          click: () => {
            this.isShow = true
          },
        },
        {
          label: '发送邮件',
          float: 'right',
          plain: false,
          click: () => {
            this.isShowEmail = true
          },
        },
      ],
      tableCtrls: [
        {
          label: '详情',
          type: 'info',
          click: (item) => {
            this.$router.push({ path: `/candidate/${item.id}}/info` })
          },
        },
        {
          label: '修改',
          click: (item) => {
            let currentItem = {
              ...item,
            }
            if (item.label) {
              currentItem.label = item.label
                .split(',')
                .filter((n) => n !== '')
                .map((n) => parseInt(n))
            }
            this.currentItem = currentItem
            this.isShow = true
          },
        },
        {
          label: '删除',
          type: 'danger',
          role: [1],
          click: (item) => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              this.$http
                .get('/talent/candidate/del', {
                  params: {
                    id: item.id,
                  },
                })
                .then((ret) => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          },
        },
      ],
    }
  },
  methods: {
    onSelectionChange(list) {
      this.selection = list
    },
    onComplete(state) {
      if (state) {
        this.isShow = false
        this.query()
      }
    },
    onAnalysis(data) {
      let now = new Date()
      let { analysisResult } = data
      let { result: r } = analysisResult
      let currentItem = {
        name: r.name,
        sex: r.gender,
        mobile: r.phone,
        email: r.email,
        wechat: r.weixin,
        year: r.birthday || r.age ? now.getFullYear() - r.age : '',
        address: r.living_address_norm || r.city_norm,
        hometown: r.hometown_address,
        edu: r.degree,
        language: r.languages,
        political: r.polit_status,
        maritalStatus: r.marital_status,
        source: r.resume_source,
        post: r.work_position,
        industry: r.work_industry,
        jobStatus: r.work_status,
      }
      this.$refs.form.analysis(currentItem)
    },
    // 创建人才
    onSubmit(params) {
      let postData = { ...params }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      } else {
        postData = this.formatObj(postData)
      }
      this.$http.post('/talent/candidate/save', postData).then((ret) => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
  },
  mounted() {
    let { path } = this.$route

    // 读取默认参数
    const key = `path${path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    } else {
      if (!this.timeRange) {
        let talentType = this.enumOptions.talentType
        let t1 = talentType.find((n) => n.label === '互联网行业')
        if (path === '/candidate/type') {
          this.currentParams.talentType = t1.value
          this.defaultQueryParams.talentType = t1.value
        }
        if (path === '/candidate') {
          this.currentParams.talentType = -1
          this.defaultQueryParams.talentType = -1
        }
      }
    }

    this.query({ ...this.defaultParams, ...this.$route.query })
    this.setTitle()
  },
}
</script>