import { mapState } from 'vuex'
import { stateList } from '../store/common'

const mixin = {
  computed: {
    ...mapState(stateList),
    tagGroup () {
      return this.$store.getters.tagGroup
    }
  },
  methods: {
    format (column, row) {
      let value = ''
      if (column.prop.indexOf('.') !== -1) {
        const prop = column.prop.split('.')
        value = row[prop[0]] ? row[prop[0]][prop[1]] : ''
      } else {
        value = row[column.prop]
      }

      if (value === 'Invalid date') {
        return ''
      }

      if (value === null || value === '' || value === undefined) {
        return ''
      }

      if (column.format && typeof column.format === 'function') {
        return column.format(row)
      }

      if (column.type && column.type === 'date' && value) {
        const date = this.$dayjs(value)
        const year = date.year()
        if (year === 2100) {
          return '至今'
        }
        return date.format(column.format || 'YYYY-MM-DD HH:mm:ss')
      }

      // 格式化标签
      if (column.tableType === 'tag') {
        const optionKey = column.optionKey || 'tag'
        const options = this.enumOptions[optionKey]
        if (options) {
          const ret = options.filter(n =>
            value.split(',').includes(n.value.toString())
          )
          return ret.map(n => n.label)
        }
        return []
      }

      // 多组标签
      if (column.tableType === 'tagGroup') {
        const valueArr = value
          .split(',')
          .filter(n => n !== '' && n !== 'NaN' && n !== null && n !== undefined)
          .map(n => parseInt(n))
        const ret = []
        this.tagGroup.forEach(n => {
          const target = n.options.filter(o => valueArr.includes(o.value))
          ret.push(...target)
        })
        return ret.map(n => n.label)
      }

      // 跟进人||所属客户 object
      if (typeof value === 'object' && value.name) {
        return value.name
      }

      // 客户联系人 array / object
      if (column.prop === 'contact') {
        let ret = ''
        if (Array.isArray(value)) {
          ret = value.map(n => n.name).join(', ')
        } else if (typeof value === 'object' && value.name) {
          ret = value.name
        } else {
          ret = value
        }
        return ret
      }

      // 跟进人 单个
      if (column.prop === 'followerId' || column.prop === 'recommendId') {
        const ret = this.users.find(n => n.value === value)
        return ret ? ret.label : value
      }

      // 参与人 多个 字符串
      if (column.prop === 'adviserId') {
        const adviserId = Array.isArray(value) ? value : value.split(',')
        const ret = adviserId
          .filter(n => n !== '' && n !== 'NaN' && n !== null && n !== undefined)
          .map(n => {
            const user = this.users.find(m => m.value === parseInt(n))
            return user || ''
          })
        return ret.map(n => n.label).join(', ')
      }

      // 时间类型
      if (column.prop === 'createdAt' || column.prop === 'modifiedAt') {
        return this.$dayjs(value).format('YYYY-MM-DD HH:mm:ss')
      }

      // 地址格式化
      if (column.type === 'address') {
        return value.replace('中华人民共和国 ', '')
      }

      // 角色
      if (column.prop === 'roleId') {
        const ret = this.roles.find(n => n.value === value)
        return ret ? ret.label : value
      }

      // 开票状态
      if (column.prop === 'finance') {
        const defaultStr = '未开票'
        if (Array.isArray(value) && value.length) {
          return value
            .map(n => {
              const { status, cost } = n
              if (status) {
                const financeStatus = this.enumOptions.financeStatus.find(
                  m => m.value === status
                )
                const statusStr = financeStatus
                  ? financeStatus.label
                  : defaultStr
                return statusStr + cost
              }
              return defaultStr + cost
            })
            .join(',')
        }
        return defaultStr
      }

      // 属性枚举
      if (column.optionKey && column.type === 'select') {
        const optionKey = column.optionKey
        const options = this.enumOptions[optionKey]
        if (options) {
          const ret = options.find(n => parseInt(n.value) === parseInt(value))
          return ret ? ret.label : value
        }
        return value
      }

      return value
    }
  }
}

export default mixin
