/**
 * 沟通记录字段
 */
export default function (context) {
  return [
    // {
    //   label: '沟通主题',
    //   prop: 'title',
    //   type: 'input',
    //   include: ['form', 'table']
    // },
    {
      label: '沟通时间',
      prop: 'recordDate',
      type: 'date',
      include: ['form', 'table'],
      rule: 'date'
    },
    {
      label: '沟通类型',
      prop: 'type',
      type: 'select',
      rule: 'select',
      include: ['form', 'table'],
      source: () => {
        return Promise.resolve(context.enumOptions.communication)
      },
      format: (item) => {
        const ret = context.enumOptions.communication.find(
          (n) => n.value === item.type
        )
        return ret ? ret.label : item.type
      }
    },
    {
      label: '参与顾问',
      prop: 'adviserId',
      rule: 'multiSelect',
      type: 'multiSelect',
      include: ['form', 'table'],
      source: () => {
        return Promise.resolve(context.userList)
      }
    },
    {
      label: '行业经验',
      prop: 'industryExperience',
      type: 'textarea',
      include: ['form', 'table']
    },
    {
      label: '核心能力',
      prop: 'coreCompetence',
      type: 'textarea',
      include: ['form', 'table']
    },
    {
      label: '求职动机',
      prop: 'jobSeekingMotivation',
      type: 'textarea',
      include: ['form']
    },
    {
      label: '薪资详情及期望',
      prop: 'salaryDetails',
      type: 'textarea',
      include: ['form', 'table']
    },
    // {
    //   label: '沟通地点',
    //   prop: 'address',
    //   type: 'address',
    //   include: ['form', 'table']
    // },

    {
      label: '其他',
      prop: 'record',
      type: 'textarea',
      include: ['form', 'table']
    },
    {
      label: '创建时间',
      prop: 'createdAt',
      include: ['table']
    }
  ]
}
