import Vue from 'vue'
import './plugins/axios'
import './plugins/dayjs'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/socket'
import './assets/index.less'
import 'normalize.css'

import UiQuery from './components/Query.vue'
import UiTable from './components/Table.vue'
import UiForm from './components/UiForm.vue'
import UiList from './components/List.vue'
// import Uemail from './components/Email.vue'
// import Uinterview from './components/Interview.vue'
import UiLogTable from './components/LogTable.vue'
import UiMultiEmail from './components/MultiEmail.vue'
// import { createProvider } from './vue-apollo'

import formCreate from '@form-create/element-ui'
Vue.use(formCreate)

Vue.component(UiQuery.name, UiQuery)
Vue.component(UiTable.name, UiTable)
Vue.component(UiForm.name, UiForm)
Vue.component(UiList.name, UiList)
Vue.component(UiMultiEmail.name, UiMultiEmail)
// Vue.component(Uemail.name, Uemail)
// Vue.component(Uinterview.name, Uinterview)
Vue.component(UiLogTable.name, UiLogTable)

Vue.component('Uemail', resolve => {
  require(['./template/Email.vue'], resolve)
})

Vue.component('Uinterview', resolve => {
  require(['./template/Interview.vue'], resolve)
})

Vue.config.productionTip = false
Vue.config.devtools = true

router.beforeEach((to, from, next) => {
  const isLogin = window.localStorage.loginTime
  if (!isLogin && (to.path === '/share' || to.path === '/forum')) {
    next()
    return
  }
  if ((isLogin && to.path !== '/login') || (!isLogin && to.path === '/login')) {
    next()
    return
  }
  if (isLogin && to.path === '/login') {
    next({ path: '/' })
    return
  }
  if (!isLogin && to.path !== '/login') {
    next({ path: '/login' })
  }
})

new Vue({
  router,
  store,
  // apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app')
