<template>
  <div :id="id"></div>
</template>

<script>
import { Pie } from '@antv/g2plot'
export default {
  props: {
    data: Array
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
          this.init(val)
        }
      }
    }
  },
  data() {
    return {
      id:
        'id-' +
        Math.random()
          .toString(36)
          .slice(-6),

      pieIns: null
    }
  },
  methods: {
    init(data) {
      if (this.pieIns) {
        this.pieIns.changeData(data)
        return
      }
      if (data.length === 0) {
        return
      }
      const piePlot = new Pie(this.id, {
        appendPadding: 10,
        data,
        angleField: 'count',
        colorField: 'label',
        innerRadius: 0.55,
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}'
        },
        interactions: [
          { type: 'pie-legend-active' },
          { type: 'element-active' }
        ],
        legend: {
          layout: 'horizontal',
          position: 'bottom'
        }
      })

      piePlot.render()

      this.pieIns = piePlot
    }
  }
}
</script>
