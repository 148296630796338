/**
 * 岗位字段
 */
const operateTypes = [
  {
    label: '新增',
    value: 1,
  },
  {
    label: '修改',
    value: 2,
  },
  {
    label: '删除',
    value: 3,
  },
]
const modelTypes = [
  {
    label: '人才',
    value: 'Talent',
  },
  {
    label: '合同',
    value: 'ContractInfo',
  },
  {
    label: '岗位',
    value: 'Post',
  },
  {
    label: '顾问',
    value: 'Agent',
  },
  {
    label: '客户',
    value: 'Company',
  },
  {
    label: '联系人',
    value: 'Contact',
  },
  {
    label: '客户记录',
    value: 'CompanyRecord',
  },
  {
    label: '岗位记录',
    value: 'PostRecord',
  },
  {
    label: '人才记录',
    value: 'TalentRecord',
  },
  {
    label: '项目',
    value: 'Project',
  },
  {
    label: '财务',
    value: 'Finance',
  },
]
export default function() {
  return [
    {
      label: '操作账号',
      prop: 'creator',
      include: ['table', 'query'],
      format: row => {
        return row.creators && row.creators.name
          ? row.creators.name
          : row.creator
      },
    },
    {
      label: '操作数据表',
      prop: 'tableName',
      include: ['table'],
      format: row => {
        let item = modelTypes.find(n => n.value === row.tableName)
        return item ? item.label : row.tableName
      },
    },
    {
      label: '操作类型',
      prop: 'type',
      type: 'select',
      include: ['table', 'query'],
      format: row => {
        return operateTypes.find(n => n.value === row.type).label
      },
      source: () => {
        return Promise.resolve(operateTypes)
      },
    },
    {
      label: '操作前',
      prop: 'type',
      tableType: 'prism',
      include: ['table'],
      format: row => {
        return row.operateBefore || ''
      },
    },
    {
      label: '操作后',
      prop: 'type',
      tableType: 'prism',
      include: ['table'],
      format: row => {
        return row.operateAfter || ''
      },
    },
    {
      label: '操作时间',
      prop: 'createdAt',
      type: 'date',
      include: ['table'],
    },
  ]
}
