<template>
  <div class="">
    <el-row :gutter="20" style="height: 43px; overflow: hidden">
      <el-col :span="12" :offset="0">
        <form-create
          v-if="isAdmin"
          v-model="fApi"
          :rule="rule"
          :option="option"
          :value.sync="value"
        ></form-create>
        <div style="height:1px;"></div>
      </el-col>
      <el-col :span="12" :offset="0" style="text-align: right">
        <el-button type="primary" size="mini" @click="showNew()">
          创建日志
        </el-button>
      </el-col>
    </el-row>

    <UiTable
      :columns="columns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    >
      <!-- <template #ctrlHeader>
        <el-button type="info" size="mini" plain="" @click="isShowLog = true">
          操作日志
        </el-button>
      </template> -->
    </UiTable>

    <el-dialog
      title="工作日志"
      :visible.sync="isShow"
      width="50%"
      top="1%"
      @closed="onClose()"
    >
      <form-create
        v-model="formRef"
        :option="formOption"
        :rule="formRule"
        :value.sync="formValue"
        @submit="onSubmit"
      ></form-create>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  name: 'WorkLog',
  mixins: [mixin],
  watch: {
    userList: {
      immediate: true,
      handler(val) {
        this.rule.find(n => n.field === 'userId').options = val.map(n => {
          return {
            label: n.label,
            value: n.value,
          }
        })
      },
    },
  },
  data() {
    return {
      queryUrl: '/work',
      currentItem: {},
      fApi: {},
      formRef: {},
      value: {},
      loading: false,
      tableCtrls: [
        {
          label: '修改',
          click: item => {
            let currentItem = {
              ...item,
            }
            this.currentItem = currentItem
            this.isShow = true
            this.$nextTick(() => {
              this.formRef.setValue({ ...currentItem })
            })
          },
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              this.$http.delete(`/work/${item.id}`).then(ret => {
                if (ret.code === 200) {
                  this.$message.success('删除成功 !')
                  this.query()
                }
              })
            })
          },
        },
      ],
      formValue: {},
      isShow: false,
      columns: [
        {
          label: '顾问',
          prop: 'user.name',
          sortable: true,
          fixed: 'left',
          width: '150px',
          type: 'input',
          rule: 'text',
          include: ['query', 'table', 'form'],
        },
        {
          label: '日期',
          prop: 'workTime',
          sortable: true,
          type: 'date',
          format: 'YYYY-MM-DD',
          include: ['table'],
        },
        {
          label: '重点岗位',
          prop: 'keyPosition',
          include: ['table'],
        },
        {
          label: '次要岗位',
          prop: 'secondPosition',
          include: ['table'],
        },
        {
          label: '寻访公司',
          prop: 'company',
          include: ['table'],
        },
        {
          label: '电话量',
          prop: 'callCount',
          include: ['table'],
        },
        {
          label: '有效电话量',
          prop: 'callCountValid',
          include: ['table'],
        },
        {
          label: '跟进人数',
          prop: 'followCount',
          include: ['table'],
        },
        {
          label: '资讯浏览量',
          prop: 'newsView',
          include: ['table'],
        },
        {
          label: '知识收获',
          prop: 'knowledgeContent',
          include: ['table'],
        },
        {
          label: '明日计划',
          prop: 'plan',
          include: ['table'],
        },
        {
          label: '创建时间',
          prop: 'createdAt',
          sortable: true,
          type: 'date',
          include: ['table'],
        },
      ],
      option: {
        form: {
          inline: true,
          size: 'mini',
          title: false,
        },
        submitBtn: {
          innerText: '搜索',
          type: 'primary',
        },
        onSubmit: formData => {
          this.query({ ...formData })
        },
      },
      formOption: {
        form: {
          size: 'small',
        },
      },
      rule: [
        {
          type: 'select',
          field: 'userId',
          title: '顾问',
          info: '',
          props: {
            _optionType: 2,
            placeholder: '顾问',
            clearable: true,
          },
          options: [],
          _fc_drag_tag: 'select',
          hidden: false,
          display: true,
        },
      ],
      formRule: [
        {
          type: 'datePicker',
          field: 'workTime',
          title: '日期',
          info: '',
          props: {
            placeholder: '日期',
          },
          _fc_drag_tag: 'datePicker',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'keyPosition',
          title: '重点岗位',
          info: '',
          props: {
            placeholder: '重点岗位',
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'secondPosition',
          title: '次要岗位',
          info: '',
          props: {
            placeholder: '次要岗位',
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'company',
          title: '寻访公司',
          info: '',
          props: {
            placeholder: '寻访公司',
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'callCount',
          title: '电话量',
          info: '',
          props: {
            placeholder: '电话量',
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'callCountValid',
          title: '有效电话量',
          info: '',
          props: {
            placeholder: '有效电话量',
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'followCount',
          title: '跟进人数',
          info: '',
          props: {
            placeholder: '跟进人数',
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'newsView',
          title: '资讯浏览量',
          info: '',
          props: {
            placeholder: '资讯浏览量',
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'knowledgeContent',
          title: '知识收获',
          info: '',
          props: {
            placeholder: '知识收获',
            type: 'textarea',
            rows: 4,
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
        {
          type: 'input',
          field: 'plan',
          title: '明日计划',
          info: '',
          props: {
            placeholder: '明日计划',
            type: 'textarea',
            rows: 4,
          },
          _fc_drag_tag: 'input',
          hidden: false,
          display: true,
          $required: '不能为空',
        },
      ],
    }
  },
  methods: {
    onClose() {
      this.currentItem = {}
      this.formRef.resetFields()
      setTimeout(() => {
        this.formRef.clearValidateState()
      }, 0)
    },
    showNew() {
      this.isShow = true
    },
    onPageChange(page) {
      this.currentPage = page
      this.query()
    },
    onSubmit(formData) {
      if (this.currentItem?.id) {
        this.$http
          .put(`/work/${this.currentItem.id}`, { ...formData })
          .then(res => {
            if (res.code === 200) {
              this.isShow = false
              this.$message.success('提交成功 !')
              this.query()
            }
          })
        return
      }
      this.$http.post('/work', { ...formData }).then(res => {
        if (res.code === 200) {
          this.isShow = false
          this.$message.success('提交成功 !')
          this.query()
        }
      })
    },
  },
  mounted() {
    this.value = {
      userId: this.userInfo.id,
    }
    this.query({
      userId: this.userInfo.id,
    })
    this.setTitle()
  },
}
</script>
