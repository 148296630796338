/**
 * 项目字段
 */
export default function (context) {
  return [
    {
      label: '基础信息',
      type: 'divide',
      include: ['form']
    },
    {
      label: '项目名称',
      prop: 'name',
      rule: 'text',
      sortable: true,
      fixed: 'left',
      tableType: 'link',
      formWidth: 24,
      click: item => {
        context.openLink(`/project/${item.id}/info`)
        // context.$router.push({ path: `/project/${item.id}/info` })
      },
      type: 'input',
      include: ['query', 'table', 'form', 'show']
    },
    {
      label: '所属客户',
      prop: 'company',
      sortable: true,
      tableType: 'link',
      click: item => {
        context.openLink(`/client/${item.companyId}`)
        // context.$router.push({ path: `/client/${item.companyId}` })
      },
      include: ['table', 'show']
    },
    {
      label: '所属客户',
      prop: 'companyId',
      rule: 'select',
      type: 'select',
      include: ['query', 'form'],
      source: () => {
        return context.$http
          .get('/client/list', {
            params: {
              pageSize: 10000,
              pageNo: 1,
            },
          })
          .then(res => {
            context.companyList = res.data.list
            return res.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
              }
            })
          })
      },
      relate: [
        {
          prop: 'contactId',
          source: item => {
            const company = context.companyList.find(n => n.id === item.companyId)
            let ret = []
            if (company.contact && company.contact.length) {
              ret = company.contact.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            }
            return Promise.resolve(ret)
          }
        },
        {
          prop: 'postId',
          source: item => {
            const projectId = context.currentItem.id
            return context.$http
              .get('/post/list', {
                params: {
                  companyId: item.companyId
                }
              })
              .then(res => {
                const ret = res.data.list
                  .filter(n => {
                    return !n.projectId || n.projectId === projectId
                  })
                  .map(n => {
                    return {
                      label: n.name,
                      value: n.id
                    }
                  })
                return ret
              })
          }
        },
        {
          prop: 'followerId',
          source: item => {
            return context.waitUntil(() => {
              if (context.companyList && context.companyList.length) {
                const company = context.companyList.find(
                  n => n.id === item.companyId
                )
                let ret = []
                if (company) {
                  const { follower, adviserList } = company
                  ret = [
                    {
                      label: follower.name,
                      value: follower.id
                    }
                  ]
                  if (adviserList && adviserList.length) {
                    adviserList.forEach(n => {
                      // 排重
                      if (!ret.find(m => m.value === n.id)) {
                        ret.push({
                          label: n.name,
                          value: n.id
                        })
                      }
                    })
                  }
                }
                return ret
              }
              return false
            })
          }
        },
        {
          prop: 'adviserId',
          source: item => {
            return context.waitUntil(() => {
              if (context.companyList && context.companyList.length) {
                const company = context.companyList.find(
                  n => n.id === item.companyId
                )
                let ret = []
                if (company) {
                  const { follower, adviserList } = company
                  ret = [
                    {
                      label: follower.name,
                      value: follower.id
                    }
                  ]
                  if (adviserList && adviserList.length) {
                    adviserList.forEach(n => {
                      // 排重
                      if (!ret.find(m => m.value === n.id)) {
                        ret.push({
                          label: n.name,
                          value: n.id
                        })
                      }
                    })
                  }
                }
                return ret
              }
              return false
            })
          }
        }
      ]
    },
    {
      label: '客户联系人',
      prop: 'contactId',
      type: 'select',
      rule: 'select',
      include: ['form']
    },
    {
      label: '客户联系人',
      prop: 'contact',
      type: 'select',
      rule: 'select',
      include: ['table', 'show']
    },
    {
      label: '关联岗位',
      prop: 'postId',
      rule: 'multiSelect',
      type: 'multiSelect',
      include: ['form'],
      noDataText: '当前客户无可关联岗位'
    },
    {
      label: '项目状态',
      prop: 'status',
      rule: 'select',
      optionKey: 'projectStatus',
      type: 'select',
      include: ['query', 'table', 'form', 'show'],
      source: () => {
        return Promise.resolve(context.enumOptions.projectStatus)
      },
      format: item => {
        const key = 'status'
        const ret = context.enumOptions.projectStatus.find(
          n => n.value === item[key]
        )
        return ret ? ret.label : item[key]
      }
    },
    {
      label: '跟进信息',
      type: 'divide',
      include: ['form']
    },
    {
      label: '项目负责人',
      prop: 'followerId',
      type: 'select',
      rule: 'select',
      include: ['table', 'form', 'show'],
      source: () => Promise.resolve(context.userList)
    },
    {
      label: '项目参与人',
      prop: 'adviserId',
      type: 'multiSelect',
      include: ['table', 'form', 'show'],
      source: () => Promise.resolve(context.userList)
    },
    {
      label: '其它信息',
      type: 'divide',
      include: ['form']
    },
    {
      label: '项目背景',
      prop: 'background',
      type: 'textarea',
      include: ['form', 'show']
    },
    {
      label: '项目详情',
      prop: 'detail',
      type: 'textarea',
      include: ['form', 'show']
    },
    {
      label: '项目总结',
      prop: 'summary',
      type: 'textarea',
      include: ['form', 'show']
    },

    {
      label: '创建时间',
      prop: 'createdAt',
      sortable: true,
      include: ['table', 'show']
    }
  ]
}
