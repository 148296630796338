<template>
  <div class="">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/candidate' }" replace>
        人才管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        人才详情
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="ui-title">
      <i class="el-icon-user ui-title-icon"></i>{{ form.name }}
    </div>

    <el-tabs v-model="activeName" tab-position="left" @tab-click="tabClick">
      <el-tab-pane
        :label="tab.label"
        :name="tab.name"
        v-for="tab in tabs"
        :key="tab.name"
        :style="{ maxHeight: maxHeight + 'px', overflow: 'auto' }"
        v-loading="loading"
      >
      </el-tab-pane>
      <router-view :form="form" :formItem="candidateColumns"></router-view>
    </el-tabs>

    <el-dialog
      title="项目详情"
      :visible.sync="isShowDetail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="detailItem"
        :formDefault="form"
        @cancel="isShowDetail = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="岗位信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="formItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="内容记录"
      :visible.sync="isShowHistory"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="historyItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowHistory = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="关联岗位"
      :visible.sync="isShowPosition"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="positionItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowHistory = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    // 当前客户id
    candidateId() {
      return parseInt(this.$route.params.id)
    },
    formItem() {
      return this.tableColumns.filter(
        n => n.include && n.include.includes('form')
      )
    },
    detailItem() {
      return this.infoItem.filter(n => n.include && n.include.includes('form'))
    }
  },
  data() {
    return {
      isShowPosition: false,
      positionItem: [
        {
          label: '客户',
          prop: 'name',
          type: 'select',
          source: () => {
            return this.$http.get('/list').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.name
                }
              })
            })
          }
        },
        {
          label: '项目',
          prop: 'project',
          type: 'select',
          source: () => {
            return this.$http.get('/list').then(res => {
              return res.data.map(n => {
                return {
                  label: n.projectName,
                  value: n.projectName
                }
              })
            })
          }
        },
        {
          label: '岗位',
          prop: 'position',
          type: 'select',
          source: () => {
            return this.$http.get('/list').then(res => {
              return res.data.map(n => {
                return {
                  label: n.positionName,
                  value: n.positionName
                }
              })
            })
          }
        }
      ],
      isShowDetail: false,
      maxHeight: window.innerHeight - 110,
      currentItem: {},
      isShowHistory: false,
      isShow: false,
      tableHeight: window.innerHeight - 190,
      tableData: {},
      history: [],
      historyItem: [
        {
          label: '日期',
          props: 'date',
          type: 'dateTime'
        },
        {
          label: '沟通类型',
          type: 'select',
          options: [
            {
              label: '首次寻访',
              value: '首次寻访'
            },
            {
              label: '定期沟通',
              value: '定期沟通'
            },
            {
              label: '电话沟通',
              value: '电话沟通'
            },
            {
              label: '现场沟通',
              value: '现场沟通'
            }
          ]
        },
        {
          label: '内容记录',
          prop: 'remark',
          type: 'textarea'
        }
      ],
      tableColumns: [
        {
          label: '简历名称',
          prop: 'resumeName',
          // tableType: 'link',
          fixed: 'left',
          type: 'input',
          include: ['form', 'table'],
          click: item => {
            this.$router.push({ path: `/position/${item.id}` })
          }
        },
        {
          label: '最高学历',
          prop: 'education',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '毕业院校',
          prop: 'school',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '专业',
          prop: 'major',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '毕业时间',
          prop: 'graduationTime',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '技术擅长',
          prop: 'skill',
          tableType: 'tag',
          showType: 'tag',
          formType: 'select',
          include: ['form', 'table']
        },
        {
          label: '其他备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '简历附件',
          prop: 'file',
          type: 'upload',
          include: ['form', 'table']
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          include: ['table']
        }
      ],
      positionColumns: [
        {
          label: '岗位名称',
          prop: 'positionName',
          tableType: 'link',
          type: 'input',
          fixed: 'left',
          include: ['form', 'table'],
          click: item => {
            this.$router.push({
              path: `/position/${item.id}`
            })
          }
        },
        {
          label: '紧急程度',
          prop: 'level',
          type: 'select',
          include: ['query', 'table', 'form'],
          options: [
            {
              label: '高',
              value: '高'
            },
            {
              label: '中',
              value: '中'
            },
            {
              label: '低',
              value: '低'
            }
          ]
        },
        {
          label: '岗位状态',
          prop: 'projectStatus',
          type: 'select',
          tableType: 'status',
          include: ['query', 'table', 'form'],
          options: [
            {
              label: '启动',
              value: 'primary'
            },
            {
              label: '进行中',
              value: 'danger'
            },
            {
              label: '已完成',
              value: 'success'
            }
          ]
        },
        {
          label: '客户联系人',
          prop: 'contact',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '负责顾问',
          prop: 'follower',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '所属部门',
          prop: 'department',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '岗位介绍',
          prop: 'desc',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '工作地点',
          prop: 'address',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '薪水范围',
          prop: 'salary',
          type: 'input',
          include: ['form', 'table']
        },

        {
          label: '操作红线信息',
          prop: 'summary',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '招聘原因',
          prop: 'reason',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '其他备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          include: ['table']
        }
      ],
      tableCtrls: [
        {
          label: '修改',
          click: item => {
            this.currentItem = { ...item }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              let idx = this.tableData.data.findIndex(n => n.id === item.id)
              this.tableData.data.splice(idx, 1)
              this.$message.success('删除成功 !')
            })
          }
        }
      ],
      loading: false,
      form: {},
      activeName: 'info',
      infoItem: [
        {
          label: '姓名',
          prop: 'follower',
          tableType: 'link',
          type: 'input',
          include: ['table'],
          click: item => {
            this.$router.push({
              path: `/project/${item.projectId}/${item.projectName}/position/${item.positionId}/${item.positionName}/candidate/${item.id}}`
            })
          }
        },
        {
          label: '手机号码',
          prop: 'mobile',
          type: 'input',
          include: ['table']
        },
        {
          label: '所在城市',
          prop: 'address',
          type: 'input',
          include: ['table']
        },
        {
          label: '人才标签',
          prop: 'tag',
          tableType: 'tag',
          formType: 'tag',
          showType: 'tag',
          type: 'tag',
          include: ['table']
        },
        {
          label: '求职状态',
          prop: 'jobStatus',
          showType: 'status',
          tableType: 'status',
          include: ['form', 'table']
        },
        {
          label: '来源',
          prop: 'source',
          type: 'input',
          include: ['table']
        },
        {
          label: '负责顾问',
          prop: 'follower',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '其他备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          include: ['table']
        }
      ],
      tabs: [
        {
          label: '人才详情',
          name: 'info'
        },
        {
          label: '关联简历',
          name: 'resume'
        },
        {
          label: '教育经历',
          name: 'education'
        },
        {
          label: '工作经历',
          name: 'experience'
        },
        {
          label: '项目经历',
          name: 'project'
        },
        {
          label: '沟通记录',
          name: 'history'
        },
        {
          label: '关联岗位',
          name: 'position'
        }
      ]
    }
  },
  methods: {
    tabClick(item) {
      let { name } = this.tabs[parseInt(item.index)]
      this.$router.push({
        path: `/candidate/${this.candidateId}/${name}`
      })
    },
    goBack() {
      window.history.back()
    },
    onSubmit() {
      this.$message.success('提交成功 !')
      this.isShowDetail = false
      this.isShow = false
    },
    onClosed() {},
    query() {
      this.loading = true
      this.$http
        .get('/talent/candidate/desc', {
          params: {
            id: this.candidateId
          }
        })
        .then(res => {
          this.loading = false
          this.form = res.data
          this.setTitle(this.form.name)
        })
    }
  },
  mounted() {
    let path = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
    this.activeName = path
    this.query()
  },
  destroyed() {
    this.form = {}
  }

}
</script>

<style lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}
.el-timeline {
  padding: 0 0 0 10px;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }
}

.el-page-header {
  margin-bottom: 10px;
}
.ui-info {
  .el-form-item {
    margin: 0 0 8px !important;
  }
}
</style>
