/**
 * 账号字段
 */
export default function (context) {
  return [
    {
      label: '基础信息',
      type: 'divide',
      include: ['form'],
    },
    {
      label: 'ID',
      prop: 'id',
      type: 'input',
      include: ['table'],
    },

    {
      label: '板块名称',
      prop: 'name',
      type: 'input',
      rule: 'text',
      include: ['form', 'table', 'query'],
    },
    {
      label: '背景图',
      prop: 'image',
      type: 'avatar',
      action: 'https://community.rockmind.cn/api/upload',
      include: ['form'],
      name: 'files',
      rule: 'file',
      headers: 'masterKey',
    },
    {
      label: '板块简介',
      prop: 'desc',
      type: 'textarea',
      rule: 'text',
      include: ['form', 'table'],
    },
    {
      label: '会员权益',
      prop: 'equity',
      type: 'textarea',
      rule: 'text',
      include: ['form', 'table'],
    },
    {
      label: '申请费用(元)',
      prop: 'price',
      type: 'input',
      rule: 'text',
      include: ['table', 'form'],
    },
    {
      label: '管理信息',
      type: 'divide',
      include: ['form'],
    },
    {
      label: '版主',
      prop: 'owner_id',
      type: 'select',
      include: ['form', 'table'],
      format: (row)=>{
        return row?.owner_id.nickname || row?.owner_id.username || ''
      },
      source: () => {
        const url = context.baseUrl + '/api/users'
        return context.$http
          .get(url, {
            headers: {
              Authorization: `Bearer ${context.masterKey}`,
            },
          })
          .then(res => {
            return res.map(n => {
              return {
                label: n.nickname || n.username,
                value: n.id,
              }
            })
          })
      },
    },
    {
      label: '管理员',
      prop: 'admin_ids',
      type: 'multiSelect',
      include: ['form'],
      source: () => {
        const url = context.baseUrl + '/api/users'
        return context.$http
          .get(url, {
            headers: {
              Authorization: `Bearer ${context.masterKey}`,
            },
          })
          .then(res => {
            return res.map(n => {
              return {
                label: n.nickname || n.username,
                value: n.id,
              }
            })
          })
      },
    },
    {
      label: '成员数',
      prop: 'member_ids.length',
      type: 'input',
      rule: 'text',
      include: ['table'],
    },
    {
      label: '动态数',
      prop: 'cm_posts.length',
      type: 'input',
      rule: 'text',
      include: ['table'],
    },

    {
      label: '创建人',
      prop: 'user_id.firstname',
      type: 'input',
      rule: 'text',
      include: ['table'],
    },
    {
      label: '创建时间',
      prop: 'createdAt',
      type: 'input',
      rule: 'text',
      include: ['table'],
    },
  ]
}
