<template>
  <div class="">
    <UiTable
      :columns="resumeColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    >
      <template #ctrlHeader>
        <el-button type="primary" @click="showNew" size="mini">
          创建简历
        </el-button>
      </template>
    </UiTable>

    <el-dialog
      title="简历信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="resumeColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  props: {
    form: Object,
  },
  computed: {
    talentId() {
      return parseInt(this.$route.params.id)
    },
  },
  data() {
    return {
      queryUrl: '/talent/resume/list',
      tableCtrls: [
        {
          label: '修改',
          click: (item) => {
            this.currentItem = {
              resumeName: item.resumeName,
              id: item.id,
            }
            this.isShow = true
          },
        },
        {
          label: '删除',
          type: 'danger',
          click: (item) => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              this.$http
                .get('/talent/resume/del', {
                  params: {
                    id: item.id,
                  },
                })
                .then((ret) => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          },
        },
      ],
      tableHeight: window.innerHeight - 190,
    }
  },
  methods: {
    showNew() {
      this.currentItem = {
        name: this.form.name,
        mobile: this.form.mobile,
        email: this.form.email,
        address: this.form.address,
      }
      this.isShow = true
    },
    onSubmit(form) {
      let postData = { ...form, talentId: this.talentId }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      this.$http.post('/talent/resume/save', postData).then((ret) => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
  },
  mounted() {
    this.query({
      talentId: this.talentId,
    })
  },
}
</script>

