<template>
  <div class>
    <UiQuery
      :items="accountColumns"
      :ctrls="ctrls"
      :rightCtrls="rightCtrls"
    ></UiQuery>
    <UiTable
      :columns="accountColumns"
      :data="tableData"
      :selection-ctrls="selectionCtrls"
      :ctrls="tableCtrls"
      :loading="loading"
      :showSelection="true"
      @page-change="onPageChange"
      @selection-change="tabelSelection"
    ></UiTable>
    <el-dialog
      title="账号信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        labelWidth="120px"
        :formItem="accountColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="批量设置角色"
      :visible.sync="isShowSetRole"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @closed="onClosed"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="60px"
        size="small"
      >
        <el-form-item label="角色" prop="role">
          <el-select v-model="form.role" placeholder="请选择角色">
            <el-option
              v-for="n in roleList"
              :key="n.id"
              :label="n.name"
              :value="n.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateUserRole('form')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  data() {
    return {
      queryUrl: '/setting/user/list',
      selectedUser: [],
      params: {},
      roleList: [],
      form: {},
      isShowSetRole: false,
      rightCtrls: [
        {
          label: '创建账号',
          float: 'right',
          plain: false,
          click: () => {
            this.isShow = true
          }
        }
      ],
      selectionCtrls: [
        {
          label: '批量删除',
          type: 'danger',
          role: [1],
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              let idx = this.data.data.findIndex(n => n.id === item.id)
              this.data.data.splice(idx, 1)
              this.$message.success('删除成功 !')
            })
          }
        },
        {
          label: '批量冻结',
          type: 'warning',
          click: item => {
            this.$confirm('确定冻结 ?', {
              type: 'warning'
            }).then(() => {
              let idx = this.data.data.findIndex(n => n.id === item.id)
              this.data.data.splice(idx, 1)
              this.$message.success('冻结成功 !')
            })
          }
        },
        {
          label: '批量启用',
          click: item => {
            this.$confirm('确定启用 ?', {
              type: 'warning'
            }).then(() => {
              let idx = this.data.data.findIndex(n => n.id === item.id)
              this.data.data.splice(idx, 1)
              this.$message.success('启用成功 !')
            })
          }
        },
        {
          label: '批量设置角色',
          click: () => {
            this.isShowSetRole = true
          }
        }
      ],
      tableCtrls: [
        {
          label: '修改',
          click: item => {
            this.currentItem = { ...item }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          role: [1],
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              this.$http
                .get('/setting/user/del', {
                  params: {
                    id: item.id
                  }
                })
                .then(ret => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          }
        },
        {
          label: '冻结',
          type: 'warning',
          click: item => {
            this.$confirm('确定冻结 ?', {
              type: 'warning'
            }).then(() => {
              let idx = this.data.data.findIndex(n => n.id === item.id)
              this.data.data.splice(idx, 1)
              this.$message.success('冻结成功 !')
            })
          }
        },
        {
          label: '启用',
          click: item => {
            this.$confirm('确定启用 ?', {
              type: 'warning'
            }).then(() => {
              let idx = this.data.data.findIndex(n => n.id === item.id)
              this.data.data.splice(idx, 1)
              this.$message.success('启用成功 !')
            })
          }
        }
      ]
    }
  },
  methods: {
    onSubmit(form) {
      let postData = {
        ...form,
        mobile: form.nickname
      }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      this.$http.post('/setting/user/save', postData).then(ret => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
    updateUserRole(form) {
      this.$http
        .get('/setting/user/updateRoles', {
          params: {
            roleId: this[form].role,
            id: this.selectedUser.map(user => user.id).join(',')
          }
        })
        .then(ret => {
          if (ret.code === 200) {
            this.$message.success('提交成功 !')
            this.isShow = false
            this.query()
            return
          }
          this.$message.error(ret.message)
        })
    },
    tabelSelection(val) {
      this.selectedUser = val
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$message.success('设置成功 !')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getRoles() {
      this.$http.get('/setting/role/list').then(res => {
        this.roleList = res.data.list
      })
    }
  },
  mounted() {
    this.query()
    this.getRoles()
  }
}
</script>

<style></style>
