import Vue from 'vue'
import Vuex from 'vuex'
import { Notification } from 'element-ui'

Vue.use(Vuex)

let notifyIns = null
let notifyComplete = null

export default new Vuex.Store({
  state: {
    // 表单校验规则
    rules: {
      text: [{ required: true, message: '不能为空', trigger: 'blur' }],
      file: [{ required: true, message: '不能为空', trigger: 'change' }],
      name: [
        {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if (value.indexOf('/') === -1) {
              callback()
            } else {
              callback(new Error('符号"/"为非法字符,请用"\\"替代"/"'))
            }
          },
        },
      ],
      select: [{ required: true, message: '不能为空', trigger: 'change' }],
      date: [{ required: true, message: '不能为空', trigger: 'blur' }],
      password: [
        {
          min: 6,
          max: 20,
          message: '长度在 6 到 20 个字符',
        },
      ],
      mobile: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value)) {
              callback()
            } else {
              callback(new Error('请填写正确的手机号'))
            }
          },
          trigger: 'blur',
        },
      ],
      emailNotRequired: [
        {
          validator: (rule, value, callback) => {
            if (
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value
              )
            ) {
              callback()
            } else {
              callback(new Error('请填写正确的邮箱地址'))
            }
          },
          trigger: 'blur',
        },
      ],
      email: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value
              )
            ) {
              callback()
            } else {
              callback(new Error('请填写正确的邮箱地址'))
            }
          },
          trigger: 'blur',
        },
      ],
      multiSelect: [
        {
          type: 'array',
          required: true,
          message: '不能为空',
          trigger: 'change',
        },
      ],
    },
    // 所有属性枚举
    enumOptions: {},
    // 属性枚举原始结构
    enumTree: [],
    // 所有用户
    users: [],
    // 所有角色
    roles: [],
    // 当前用户登录信息
    userInfo: {},
    // 所有提醒
    reminders: [],
    masterKey:
      '559c8ae742985a8e7193a637bc5951376a1a80ed2c187293258d719b83a0abfcbde0c0ead1b0df0292a84b561603399affe8fc2c780f32690cadf69564e9d1ec96d9b331e62c3557ebeb11eb20f64fbbeb05dfac4351c261bbdca526351801591669719cc2ae1d1e709db9d42b54142c1fa7f6dd43a2977b343a25e0cc3d6281',
  },
  getters: {
    // 根据属性名称获取属性
    getEnumByLabel: state => (key, label) => {
      const currentEnum = state.enumOptions[key]
      if (currentEnum) {
        const ret = currentEnum.find(n => n.label === label)
        return ret ? ret.value : ''
      }
      return ''
    },
    getEnumByValue: state => (key, value) => {
      const currentEnum = state.enumOptions[key]
      if (currentEnum) {
        const ret = currentEnum.find(n => n.value === value)
        return ret
      }
      return ''
    },
    // 获取所有用户信息
    userList: state => {
      // 12 为财务角色
      return state.users.filter(n => n.roleId !== 12)
    },
    // 标签
    tagGroup: state => {
      const group = [
        { label: '大制造应用场景', value: 'largeScene' },
        { label: '硬件应用场景', value: 'hardwareScene' },
        { label: 'AI应用场景', value: 'aiScene' },
        { label: '互联网应用场景', value: 'internetScene' },
        { label: '地域', value: 'location' },
        { label: '公司', value: 'company' },
        { label: '职能模块', value: 'functional' },
        { label: '编程语言', value: 'programmingLanguage' },
        { label: '技术擅长', value: 'skill' },
        { label: '所在行业', value: 'industry' },
        { label: '产品运营', value: 'productOperate' },
        { label: '资历', value: 'seniority' },
        { label: '求职状态', value: 'jobStatus' },
        { label: '应用场景', value: 'scenes' },
      ]
      const enumTree = state.enumTree
      const newGroup = group.map(n => {
        const { value } = n
        let options = []
        const tree = enumTree.find(m => m.sign === value)
        const obj = tree.children.map(m => {
          return {
            label: m.name,
            value: m.id,
            sign: m.sign,
            status: m.status,
          }
        })
        if (obj) {
          options = obj
        }
        n.options = options
        n.id = tree.id
        return n
      })
      return newGroup
    },
  },
  mutations: {
    setState(state, option) {
      const { key, value } = option
      state[key] = value
    },
  },
  actions: {
    SOCKET_reconnect() {
      if (notifyComplete) {
        return
      }
      notifyComplete = Notification.success({
        title: '提示',
        message: '服务器更新完成，请保存当前数据后刷新页面 !',
        duration: 3000,
        showClose: true,
      })
    },
    SOCKET_connect() {
      if (notifyIns) {
        notifyIns.close()
        notifyIns = null
      }
    },
    SOCKET_disconnect() {
      console.log('disconnect')
      notifyIns = Notification.warning({
        title: '提示',
        message: '服务器更新重启中，请稍后．．．',
        duration: 0,
        showClose: false,
      })
    },
    // 获取所有提醒
    getReminders({ commit, state }, option = {}) {
      const { reload = false } = option
      const key = 'reminders'
      if (reload) {
        return Vue.$http
          .get('/remind/list', {
            params: {
              pageNo: 1,
              pageSize: 1000,
            },
          })
          .then(res => {
            const value = res.data.list
            commit('setState', { key, value })
            return value
          })
      }
      return state[key]
    },
    // 获取所有用户
    getUsers({ commit, state }, option = {}) {
      const { key = 'users', reload = false } = option
      if (reload) {
        return Vue.$http
          .get('/setting/user/list', {
            params: {
              status: 1,
              pageSize: 1000,
            },
          })
          .then(ret => {
            const value = ret.data.list.map(n => {
              return {
                ...n,
                label: n.name,
                value: n.id,
                roleId: n.roleId,
              }
            })
            commit('setState', {
              key,
              value,
            })
            return value
          })
      }
      return state[key]
    },
    getState({ commit, state }, option) {
      const { key, source } = option
      if (source && typeof source === 'function') {
        return source().then(value => {
          commit('setState', {
            key,
            value,
          })
        })
      }
      if (key && state[key]) {
        return state[key]
      }
    },
    // 根据标识获取枚举
    getEnumOptionsBySign({ commit, state }, option) {
      const { key, sign, reload = false } = option
      if (reload) {
        return Vue.$http
          .get('/setting/enum/enumOptionsBySign', { params: { sign } })
          .then(ret => {
            const value = ret.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
                sign: n.sign || '',
              }
            })
            commit('setState', {
              key,
              value,
            })
            return value
          })
      }
      return state[key]
    },
    // 获取所有枚举属性
    getEnumOptions(context, reload = false) {
      const key = 'enumOptions'
      if (reload) {
        return Vue.$http
          .get('/setting/enum/tree', {
            params: {
              pageNo: 1,
              pageSize: 10000,
            },
          })
          .then(res => {
            const { data } = res
            context.commit('setState', {
              key: 'enumTree',
              value: data,
            })
            const obj = {}
            data.forEach(item => {
              const { sign, children } = item
              obj[sign] = children.map(n => {
                return {
                  label: n.name,
                  value: n.id,
                  sign: n.sign,
                  status: n.status,
                }
              })
              if (sign === 'jobIndustry') {
                obj.jobIndustry.push({
                  label: '未设置',
                  value: 'null',
                  sign: '',
                  status: 1,
                })
              }
            })

            context.commit('setState', {
              key,
              value: obj,
            })
            return obj
          })
      }
      return context.state[key]
    },
    getUserInfo(context) {
      return Vue.$http.get('/userInfo').then(userInfo => {
        if (userInfo.code === 200 && userInfo.data) {
          window.localStorage.loginTime = Date.now()
          window.localStorage.userInfo = JSON.stringify(userInfo.data)
          context.commit('setState', {
            key: 'userInfo',
            value: userInfo.data,
          })
          return userInfo.data
        } else {
          window.localStorage.clear()
        }
      })
    },
    getRoles(context) {
      return Vue.$http
        .get('/setting/role/list', {
          params: {
            pageNo: 1,
            pageSize: 1000,
          },
        })
        .then(res => {
          let ret = []
          if (res.data && res.data.list) {
            ret = res.data.list.map(n => {
              let { authority, name, id, sign } = n
              authority = authority
                .split(',')
                .filter(Boolean)
                .map(n => parseInt(n))
              return {
                label: name,
                value: id,
                sign,
                authority,
              }
            })
          }
          context.commit('setState', {
            key: 'roles',
            value: ret,
          })
          return ret
        })
    },
  },
  modules: {},
})
