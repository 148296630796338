<template>
  <div class="">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/position' }" replace>
        岗位管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        岗位详情
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="ui-title">
      <span
        style="color:#888;padding: 0 20px 0 0;font-weight:normal;"
        v-if="form.company && form.company.name"
        ><i class="el-icon-office-building ui-title-icon"></i>
        {{ form.company.name }}
      </span>
      <i class="el-icon-monitor ui-title-icon"></i>{{ form.name }}
    </div>

    <el-tabs v-model="activeName" tab-position="left" @tab-click="tabClick">
      <el-tab-pane
        :label="tab.label"
        :name="tab.name"
        v-for="tab in tabs"
        :key="tab.name"
        :style="{ maxHeight: maxHeight + 'px', overflow: 'auto' }"
        v-loading="loading"
        :disabled="tab.disabled"
      >
      </el-tab-pane>
      <router-view :form="form" :formItem="positionColumns"></router-view>
    </el-tabs>

    <el-dialog
      title="状态历史"
      :visible.sync="isShowHistoryList"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <div style="padding: 0 0 10px;">
        <el-button type="primary" size="mini" @click="isShowHistory = true">
          创建
        </el-button>
      </div>
      <el-timeline>
        <el-timeline-item
          :timestamp="n.createdTime"
          placement="top"
          v-for="n in history"
          :key="n.id"
        >
          <el-card>
            <h4>{{ n.remark }}</h4>
            <p><img :src="n.avatar" class="avatar" />{{ n.name }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>

    <el-dialog
      title="项目详情"
      :visible.sync="isShowDetail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="detailItem"
        :formDefault="form"
        @cancel="isShowDetail = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="人才推荐"
      :visible.sync="isShowRecommend"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="recommendItem"
        :formDefault="form"
        @cancel="isShowRecommend = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="offer信息"
      :visible.sync="isShowOffer"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="offerItem"
        :formDefault="form"
        @cancel="isShowOffer = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="发起开票"
      :visible.sync="isShowInvoicing"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="invoicingItem"
        :formDefault="form"
        @cancel="isShowInvoicing = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="岗位信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="formItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="岗位记录"
      :visible.sync="isShowHistory"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="historyItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowHistory = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    positionId() {
      return parseInt(this.$route.params.id)
    },
    formItem() {
      return this.columns.filter(n => n.include && n.include.includes('form'))
    },
    tableColumns() {
      return this.columns.filter(n => n.include && n.include.includes('table'))
    },
    detailItem() {
      return this.infoItem.filter(n => n.include && n.include.includes('form'))
    }
  },
  data() {
    return {
      isShowOffer: false,
      isShowInvoicing: false,
      isShowRecommend: false,
      isShowHistoryList: false,
      isShowDetail: false,
      maxHeight: window.innerHeight - 110,
      currentItem: {},
      isShowHistory: false,
      isShow: false,
      tableHeight: window.innerHeight - 190,
      tableData: {},
      history: [],
      recommendItem: [
        {
          label: '推荐顾问',
          props: 'follower',
          type: 'select',
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '推荐日期',
          props: 'date',
          type: 'dateTime'
        }
      ],
      offerItem: [
        {
          label: 'offer时间',
          prop: 'date',
          type: 'dateTime'
        },
        {
          label: 'offer薪水',
          prop: 'salary',
          type: 'input'
        },
        {
          label: 'offer岗位',
          prop: 'positionName',
          tableType: 'link',
          fixed: 'left',
          type: 'input',
          include: ['form', 'table', 'query'],
          click: item => {
            this.$router.push({
              path: `/position/${item.id}`
            })
          }
        },
        {
          label: 'offer详情',
          prop: 'salary',
          type: 'input'
        },
        {
          label: 'offer附件',
          prop: 'file',
          type: 'upload'
        }
      ],
      invoicingItem: [
        {
          label: 'offer情况',
          prop: 'positionStatus',
          type: 'select',
          options: [
            {
              label: '成功',
              value: '成功'
            },
            {
              label: '失效',
              value: '失效'
            },
            {
              label: '失败',
              value: '失败'
            }
          ]
        },
        {
          label: '费用计算过程',
          prop: 'remark',
          type: 'textarea'
        },
        {
          label: '开票金额',
          prop: 'salary',
          type: 'input'
        },
        {
          label: '客户确认邮件',
          prop: 'detail',
          type: 'input'
        },
        {
          label: '客户开票信息',
          prop: 'detail',
          type: 'input'
        },
        {
          label: '客户开票信息接收人',
          prop: 'candidateName',
          type: 'input'
        },
        {
          label: '客户收件地址',
          prop: 'address',
          type: 'input'
        }
      ],
      historyItem: [
        {
          label: '日期',
          props: 'date',
          type: 'dateTime'
        },
        {
          label: '状态',
          props: 'positionStatus',
          type: 'select',
          options: [
            {
              label: '不考虑',
              value: '不考虑'
            },
            {
              label: '在跟进',
              value: '在跟进'
            },
            {
              label: '已推荐',
              value: '已推荐'
            },
            {
              label: '推荐成功',
              value: '推荐成功'
            },
            {
              label: '推荐失败',
              value: '推荐失败'
            }
          ]
        },
        {
          label: '内容记录',
          prop: 'remark',
          type: 'textarea'
        }
      ],
      columns: [
        {
          label: '选择人才',
          prop: 'candidate',
          type: 'select',
          include: ['form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '姓名',
          prop: 'follower',
          tableType: 'link',
          type: 'input',
          fixed: 'left',
          include: ['table'],
          click: item => {
            this.$router.push({
              path: `/candidate/${item.id}`
            })
          }
        },
        {
          label: '手机号码',
          prop: 'mobile',
          type: 'input',
          include: ['table']
        },
        {
          label: '所在城市',
          prop: 'address',
          type: 'input',
          include: ['table']
        },
        {
          label: '状态',
          prop: 'positionStatus',
          tableType: 'status',
          type: 'input',
          include: ['table']
        },
        {
          label: '人才标签',
          prop: 'tag',
          tableType: 'tag',
          type: 'input',
          include: ['table']
        },
        {
          label: '来源',
          prop: 'source',
          type: 'input',
          include: ['table']
        },
        {
          label: '负责顾问',
          prop: 'follower',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '其他备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          include: ['table']
        }
      ],
      relateCtrls: [
        {
          label: '关联岗位',
          click: item => {
            this.currentItem = { ...item, candidate: item.follower }
            this.isShow = true
          }
        }
      ],
      tableCtrls: [
        {
          label: '修改',
          click: item => {
            this.currentItem = { ...item }
            this.isShow = true
          }
        },
        {
          label: '历史',
          click: item => {
            this.currentItem = { ...item }
            this.isShowHistoryList = true
          }
        },
        {
          label: '推荐',
          click: item => {
            this.currentItem = { ...item }
            this.isShowRecommend = true
          }
        },
        {
          label: 'offer',
          click: item => {
            this.currentItem = { ...item }
            this.isShowOffer = true
          }
        },
        {
          label: '开票',
          click: item => {
            this.currentItem = { ...item }
            this.isShowInvoicing = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              let idx = this.tableData.data.findIndex(n => n.id === item.id)
              this.tableData.data.splice(idx, 1)
              this.$message.success('删除成功 !')
            })
          }
        }
      ],
      loading: false,
      form: {},
      activeName: 'info',
      infoItem: [
        {
          label: '岗位名称',
          prop: 'positionName',
          tableType: 'link',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '紧急程度',
          prop: 'level',
          type: 'select',
          include: ['query', 'table', 'form'],
          options: [
            {
              label: '高',
              value: '高'
            },
            {
              label: '中',
              value: '中'
            },
            {
              label: '低',
              value: '低'
            }
          ]
        },
        {
          label: '岗位状态',
          prop: 'projectStatus',
          type: 'select',
          showType: 'status',
          tableType: 'status',
          include: ['query', 'table', 'form'],
          options: [
            {
              label: '启动',
              value: 'primary'
            },
            {
              label: '进行中',
              value: 'danger'
            },
            {
              label: '已完成',
              value: 'success'
            }
          ]
        },
        {
          label: '客户联系人',
          prop: 'contact',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '负责顾问',
          prop: 'follower',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '所属部门',
          prop: 'department',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '岗位介绍',
          prop: 'desc',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '工作地点',
          prop: 'address',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '薪水范围',
          prop: 'salary',
          type: 'input',
          include: ['form', 'table']
        },

        {
          label: '操作红线信息',
          prop: 'summary',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '招聘原因',
          prop: 'reason',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '其他备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          include: ['table']
        }
      ],
      tabs: [
        {
          label: '岗位详情',
          name: 'info'
        },
        {
          label: '关联人才',
          name: 'candidate'
        },
        {
          label: '相关人才',
          name: 'relate'
        },
        {
          label: '沟通记录',
          name: 'history'
        }
      ]
    }
  },
  methods: {
    tabClick(item) {
      let { name } = this.tabs[parseInt(item.index)]
      this.$router.push({
        path: `/position/${this.positionId}/${name}`
      })
    },
    goBack() {
      window.history.back()
    },
    onSubmit() {
      this.$message.success('提交成功 !')
      this.isShowDetail = false
      this.isShow = false
    },
    onClosed() {},
    query() {
      this.loading = true
      this.$http
        .get('/post/desc', {
          params: {
            id: this.positionId
          }
        })
        .then(res => {
          this.loading = false
          this.form = res.data
          this.setTitle(this.form.name)
        })
    },
    getPosition() {
      this.$http.get('/list?count=3').then(res => {
        this.tableData = res
      })
    },
    getHitory() {
      this.$http.get('/history').then(res => {
        this.history = res.data
      })
    }
  },
  mounted() {
    let path = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
    this.activeName = path
    this.query()
    this.getPosition()
    this.getHitory()
  }
}
</script>

<style lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}
.el-timeline {
  padding: 0 0 0 10px;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }
}

.el-page-header {
  margin-bottom: 10px;
}
.ui-info {
  .el-form-item {
    margin: 0 0 8px !important;
  }
}
</style>
