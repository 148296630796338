<template>
  <div class="ui-config">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>推荐模板管理</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="ui-config-content">
      <div class="ui-block">
        <h5>推荐模板</h5>
        <div>
          <el-button type="text" size="default" @click="goDownload">
            最新模板下载
          </el-button>
        </div>
        <Uploader
          @input="onchange"
          v-model="config.banner"
          dir="template"
        ></Uploader>
      </div>
    </div>
  </div>
</template>

<script>
import Uploader from '../../components/Uploader.vue'
import mixin from '../../common/index'
import component from '../../common/compoment'
export default {
  mixins: [mixin, component],
  components: {
    Uploader,
  },
  computed: {
    agentList() {
      if (this.allAgent && this.config.agent) {
        return this.allAgent.filter((n) => {
          return this.config.post.split(',').includes(n.value.toString())
        })
      }
      return []
    },
    columns() {
      let maps = ['岗位名称', '所属客户', '负责人', '岗位类别']
      return this.positionColumns.filter((n) => {
        return n.include.includes('table') && maps.includes(n.label)
      })
    },
  },
  data() {
    return {
      form: {},
      config: {},
      allAgent: [],
      isShow: false,
      submitting: false,
      clientList: [],
      postList: [],
      showPostList: [],
    }
  },
  methods: {
    goDownload() {
      let url =
        '/file/download?key=' + encodeURIComponent('template/template.docx')
      window.open(url)
    },
    saveArticle() {
      this.$http
        .post('/wechat/config', {
          key: 'article',
          value: this.config.article,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('保存成功')
            return
          }
          this.$message.error(res.message)
        })
    },
    deleteRow(scope) {
      let arr = this.config.post.split(',')
      let idx = arr.findIndex((n) => parseInt(n) === scope.row.id)
      arr.splice(idx, 1)
      this.$http
        .post('/wechat/config', {
          key: 'post',
          value: arr.join(','),
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.getData().then(() => {
              this.getPost(this.config.post)
            })
            return
          }
          this.$message.error(res.message)
        })
    },
    showNew() {
      this.isShow = true
    },
    getPost(ids) {
      this.$http
        .get('/wechat/post', {
          params: {
            ids,
          },
        })
        .then((res) => {
          this.showPostList = res.data.list
        })
    },
    changeAgent(value) {
      this.$http
        .get('/post/list', {
          params: {
            agentId: value,
            pageSize: 10000,
          },
        })
        .then((res) => {
          this.postList = res.data.list.map((n) => {
            return {
              ...n,
              label: n.name,
              value: n.id,
            }
          })
        })
    },
    getAgent() {
      this.$http
        .get('/wechat/agent', {
          pageSize: 10000,
        })
        .then((res) => {
          this.allAgent = res.data.list.map((n) => {
            return {
              ...n,
              label: n.name,
              value: n.id,
            }
          })
        })
    },
    // 新增热门岗位
    savePost() {
      this.submitting = true
      let currentPost = []
      if (this.config.post) {
        currentPost = this.config.post
          .split(',')
          .map((n) => parseInt(n))
          .filter(Boolean)
      }
      currentPost.push(...this.form.post)
      currentPost = [...new Set(currentPost)]
      this.$http
        .post('/wechat/config', {
          key: 'post',
          value: currentPost.join(','),
        })
        .then((res) => {
          this.submitting = false
          this.isShow = false
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.getData().then(() => {
              this.getPost(this.config.post)
            })
            return
          }
          this.$message.error(res.message)
        })
    },
    getData() {
      return this.$http.get('/wechat/config').then((res) => {
        let config = {}
        res.data.forEach((n) => {
          let { key, value } = n
          config[key] = value
        })
        this.config = config
      })
    },
    onchange(val) {
      this.$http
        .post('/wechat/config', {
          key: 'template',
          value: val,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('上传成功 !')
            return
          }
          this.$message.error(res.message)
        })
    },
    onchangeAbout() {
      this.$http
        .post('/wechat/config', {
          key: 'about',
          value: this.config.about,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('提交成功 !')
            return
          }
          this.$message.error(res.message)
        })
    },
  },
  mounted() {
    this.getData().then(() => {
      this.getPost(this.config.post)
    })
    this.getAgent()
  },
}
</script>

<style lang="less">
.ui-config {
  &-content {
    border: 2px solid #eceff3;
    padding: 20px;
  }

  .ui-block {
    border-bottom: 1px solid #ebebeb;
    padding: 20px 0 30px;
    h5 {
      margin: 0 0 15px;
    }
  }
}
</style>
