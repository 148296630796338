<template>
  <div class="">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/client' }" replace>
        客户列表
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        客户详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="ui-title">
      <i class="el-icon-office-building ui-title-icon"></i>{{ form.name }}
    </div>

    <el-tabs v-model="activeName" tab-position="left" @tab-click="tabClick">
      <el-tab-pane
        :label="tab.label"
        :name="tab.name"
        v-for="tab in tabs"
        :key="tab.name"
        :style="{ maxHeight: maxHeight + 'px', overflow: 'auto' }"
      >
      </el-tab-pane>
      <router-view :form="form" :formItem="clientColumns"></router-view>
    </el-tabs>

    <el-dialog
      title="联系人信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="formItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="岗位信息"
      :visible.sync="isShowPosition"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="positionItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowPosition = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="创建项目"
      :visible.sync="isShowProject"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="ProjectItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowProject = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="沟通记录"
      :visible.sync="isShowHistory"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="historyItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowHistory = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    // 当前客户id
    companyId() {
      return parseInt(this.$route.params.id)
    },
    // 当前客户联系人
    contactList() {
      if (this.contactTableData.list) {
        return this.contactTableData.list.map(n => {
          return {
            label: n.name,
            value: n.id
          }
        })
      }
      return []
    },
    // 当前参与顾问
    adviserList() {
      if (this.form.adviserId) {
        let adviserId = this.form.adviserId.split(',')
        let ret = adviserId.map(n => {
          let user = this.users.find(m => m.value === parseInt(n))
          return user ? user : ''
        })
        return ret
      }
      return []
    },
    formItem() {
      return this.tableColumns.filter(
        n => n.include && n.include.includes('form')
      )
    },
    positionItem() {
      return this.positionColumns.filter(
        n => n.include && n.include.includes('form')
      )
    },
    positionTableColumns() {
      return this.positionColumns.filter(
        n => n.include && n.include.includes('table')
      )
    },
    ProjectItem() {
      return this.projectColumns.filter(
        n => n.include && n.include.includes('form')
      )
    }
  },
  data() {
    return {
      isShowProject: false,
      isShowPosition: false,
      maxHeight: window.innerHeight - 110,
      currentItem: {},
      isShowHistory: false,
      isShow: false,
      tableHeight: window.innerHeight - 190,
      contactTableData: {},
      history: [],
      projectData: [],
      positionData: [],
      historyItem: [
        {
          label: '日期',
          props: 'recordDate',
          type: 'dateTime'
        },
        {
          label: '参与人',
          props: 'participant',
          type: 'multiSelect',
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.name
                }
              })
            })
          }
        },
        {
          label: '沟通类型',
          type: 'select',
          options: [
            {
              label: '电话沟通',
              value: '电话沟通'
            },
            {
              label: '线下沟通',
              value: '线下沟通'
            }
          ]
        },
        {
          label: '内容记录',
          prop: 'remark',
          type: 'textarea'
        }
      ],
      projectColumns: [
        {
          label: '项目名称',
          prop: 'projectName',
          sortable: true,
          fixed: 'left',
          tableType: 'link',
          click: item => {
            this.$router.push({ path: `/project/${item.id}` })
          },
          type: 'input',
          include: ['query', 'table', 'form']
        },
        {
          label: '所属客户',
          prop: 'company',
          type: 'select',
          placeholder: '默认显示当前客户',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/list').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '客户联系人',
          prop: 'contact',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '关联岗位',
          prop: 'positionId',
          type: 'multiSelect',
          multiple: true,
          include: ['form'],
          source: () => {
            return this.$http.get('/list').then(res => {
              return res.data.map(n => {
                return {
                  label: n.positionName,
                  value: n.positionId
                }
              })
            })
          }
        },
        {
          label: '紧急程度',
          prop: 'level',
          type: 'select',
          include: ['query', 'table', 'form'],
          options: [
            {
              label: '高',
              value: '高'
            },
            {
              label: '中',
              value: '中'
            },
            {
              label: '低',
              value: '低'
            }
          ]
        },
        {
          label: '项目负责人',
          prop: 'owner',
          type: 'select',
          include: ['table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '项目参与人',
          prop: 'follower',
          type: 'multiSelect',
          include: ['table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '项目背景',
          prop: 'background',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '项目详情',
          prop: 'detail',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '项目总结',
          prop: 'summary',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '项目状态',
          prop: 'projectStatus',
          type: 'select',
          tableType: 'status',
          include: ['query', 'table', 'form'],
          options: [
            {
              label: '启动',
              value: 'primary'
            },
            {
              label: '进行中',
              value: 'danger'
            },
            {
              label: '已完成',
              value: 'success'
            }
          ]
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          sortable: true,
          type: 'input',
          include: ['table']
        }
      ],
      tableColumns: [
        {
          label: '姓名',
          prop: 'name',
          type: 'input',
          fixed: 'left',
          rule: 'text',
          include: ['form', 'table']
        },
        {
          label: '职位',
          prop: 'post',
          type: 'input',
          rule: 'text',
          include: ['form', 'table']
        },
        {
          label: '联系电话',
          prop: 'mobile',
          type: 'input',
          rule: 'mobile',
          include: ['form', 'table']
        },
        {
          label: '邮箱',
          prop: 'email',
          type: 'input',
          include: ['form', 'table']
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form', 'table']
        },
        {
          label: '创建时间',
          prop: 'createdAt',
          type: 'date',
          include: ['table']
        }
      ],
      positionCtrls: [
        {
          label: '详情',
          type: 'info',
          click: item => {
            this.$router.push({ path: `/position/${item.id}` })
          }
        },
        {
          label: '修改',
          click: item => {
            this.currentItem = { ...item }
            this.isShowPosition = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              this.$http
                .get('/post/del', {
                  params: {
                    id: item.id
                  }
                })
                .then(ret => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.getPosition()
                  }
                })
            })
          }
        }
      ],
      loading: false,
      form: {},
      activeName: 'info',
      infoItem: [
        {
          label: '名称',
          prop: 'name',
          sortable: true,
          width: '220px',
          tableType: 'link',
          click: item => {
            this.$router.push({ path: `/client/${item.id}` })
          },
          type: 'input',
          include: ['query', 'table', 'form']
        },
        {
          label: '联系人',
          prop: 'contact',
          type: 'input',
          include: ['query', 'table'],
          format: item => {
            return item && item.contact
              ? item.contact.map(n => n.name).join(', ')
              : ''
          }
        },
        // {
        //   label: '关联项目',
        //   prop: 'projectName',
        //   type: 'link',
        //   click: item => {
        //     this.$router.push({ path: `/project/${item.id}` })
        //   },
        //   include: ['query', 'table']
        // },
        {
          label: '状态',
          prop: 'status',
          type: 'status',
          include: ['query', 'table', 'form'],
          format: item => {
            let ret = this.clientStatus.find(n => n.value === item.status)
            return ret ? ret : item.status
          }
        },
        {
          label: '客户来源',
          prop: 'source',
          type: 'select',
          include: ['query', 'table', 'form'],
          format: item => {
            let ret = this.source.find(n => n.value === item.source)
            return ret ? ret.label : item.source
          }
        },
        {
          label: '跟进人',
          prop: 'followerId',
          type: 'input',
          include: ['table', 'form'],
          format: item => {
            let ret = this.users.find(n => n.value === item.followerId)
            return ret ? ret.label : item.followerId
          }
        },
        {
          label: '参与顾问',
          prop: 'adviserId',
          type: 'input',
          include: ['table', 'form'],
          format: () => this.adviserList.map(n => n.label).join(', ')
        },
        {
          label: '成立年份',
          prop: 'year',
          sortable: true,
          type: 'input',
          dataType: 'number',
          include: ['table', 'form']
        },
        {
          label: '官网',
          prop: 'website',
          type: 'input',
          include: ['table', 'form']
        },
        {
          label: '行业',
          prop: 'business',
          type: 'input',
          include: ['table', 'form']
        },
        {
          label: '公司简介',
          prop: 'intro',
          type: 'textarea',
          include: ['table', 'form']
        },
        {
          label: '公司产品',
          prop: 'product',
          type: 'textarea',
          include: ['table', 'form']
        },
        {
          label: '规模(人)',
          prop: 'scale',
          sortable: true,
          type: 'input',
          include: ['table', 'form']
        },
        {
          label: '地址',
          prop: 'address',
          type: 'input',
          include: ['table', 'form']
        },
        {
          label: '标签',
          prop: 'label',
          type: 'tag',
          tableType: 'tag',
          format: item => {
            if (item.label) {
              let ret = this.tag.filter(n =>
                item.label.split(',').includes(n.value.toString())
              )
              return ret.map(n => n.label)
            }
          },
          include: ['query', 'table', 'form']
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '录入时间',
          prop: 'createdAt',
          sortable: true,
          type: 'date',
          include: ['table']
        }
      ],
      tabs: [
        {
          label: '客户资料',
          name: 'info'
        },
        {
          label: '联系人',
          name: 'contact'
        },
        {
          label: '关联岗位',
          name: 'position'
        },
        {
          label: '关联项目',
          name: 'project'
        },
        {
          label: '合同信息',
          name: 'contract'
        },
        {
          label: '沟通记录',
          name: 'history'
        }
      ]
    }
  },
  methods: {
    tabClick(item) {
      let { name } = this.tabs[parseInt(item.index)]
      this.$router.push({
        path: `/client/${this.companyId}/${name}`
      })
    },
    showPosition() {
      this.isShowPosition = true
      this.currentItem = {
        companyId: this.companyId
      }
    },
    format(item, form) {
      if (item.format) {
        return item.format(form)
      }
      if (item.type && item.type === 'date' && form[item.prop]) {
        return this.$dayjs(form[item.prop] || form[item.prop]).format(
          item.format || 'YYYY-MM-DD HH:mm'
        )
      }
      return form[item.prop]
    },
    goBack() {
      window.history.back()
    },
    // 创建联系人
    onSubmit(form) {
      let postData = { ...form, companyId: this.companyId }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      if (form.followerId) {
        postData.followerId = parseInt(form.followerId)
      }

      let path = ''
      if (this.isShow) {
        path = '/client/user/save'
      } else if (this.isShowPosition) {
        path = '/post/save'
      }
      this.$http.post(path, postData).then(ret => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.isShowPosition = false
          this.getContact()
          this.getPosition()
          this.query()
        }
      })
    },
    onClosed() {
      this.currentItem = {}
    },
    query() {
      this.loading = true
      this.$http
        .get('/client/desc', {
          params: {
            id: this.companyId
          }
        })
        .then(res => {
          this.loading = false
          this.form = res.data
          this.setTitle(this.form.name)
        })
    },
    getPosition() {
      this.$http
        .get('/post/list', {
          params: {
            companyId: this.companyId
          }
        })
        .then(res => {
          this.positionData = res.data
        })
    },
    getContact() {
      this.$http
        .get('/client/user/list', {
          params: {
            companyId: this.companyId
          }
        })
        .then(res => {
          this.contactTableData = res.data
        })
    },
  },
  // created() {
  //   if (this.$route.query.type && this.$route.query.type === 'contact') {
  //     this.activeName = 'contact'
  //   } else {
  //     this.activeName = 'info'
  //   }
  // },
  mounted() {
    let path = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
    this.activeName = path
    this.query()
  },
  destroyed() {
    this.form = {}
  }
}
</script>

<style lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}
.el-timeline {
  padding: 0 0 0 10px;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }
}

.el-page-header {
  margin-bottom: 10px;
}
.ui-info {
  .el-form-item {
    margin: 0 0 8px !important;
  }
}
</style>
