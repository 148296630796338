export default function (context) {
  return [
    {
      label: '类型',
      prop: 'type',
      type: 'select',
      optionKey: 'reminderType',
      include: ['form', 'show'],
      rule: 'select',
      source: () => {
        return Promise.resolve(context.enumOptions.reminderType)
      }
    },
    {
      label: '提醒时间',
      prop: 'remindDate',
      type: 'dateTime',
      rule: 'select',
      include: ['form', 'show']
    },
    {
      label: '提醒内容',
      prop: 'content',
      type: 'textarea',
      rule: 'text',
      include: ['form', 'show']
    },
    {
      label: '沟通客户',
      prop: 'companyName',
      include: ['show']
    },
    {
      label: '沟通客户',
      prop: 'company',
      type: 'select',
      include: ['form'],
      valueKey: 'id',
      source: () => {
        return context.$http
          .get('/client/list', {
            params: {
              pageSize: 10000,
              pageNo: 1,
            },
          })
          .then(res => {
            context.companyList = res.data.list
            return res.data.list.map(n => {
              return {
                label: n.name,
                value: n,
                key: n.id.toString(),
              }
            })
          })
      },
      relate: [
        {
          prop: 'project',
          source: item => {
            return context.$http
              .get('/project/list', {
                params: {
                  companyId: item.company.id
                }
              })
              .then(res => {
                return res.data.list.map(n => {
                  return {
                    label: n.name,
                    value: n,
                    key: n.id.toString()
                  }
                })
              })
          }
        },
        {
          prop: 'post',
          source: item => {
            return context.$http
              .get('/post/list', {
                params: {
                  companyId: item.company.id
                }
              })
              .then(res => {
                return res.data.list.map(n => {
                  return {
                    label: n.name,
                    value: n,
                    key: n.id.toString()
                  }
                })
              })
          }
        },
        {
          prop: 'contact',
          source: item => {
            return context.$http
              .get('/client/user/list', {
                params: {
                  companyId: item.company.id
                }
              })
              .then(res => {
                return res.data.list.map(n => {
                  return {
                    label: n.name,
                    value: n,
                    key: n.id.toString()
                  }
                })
              })
          }
        }
      ]
    },
    {
      label: '客户联系人',
      prop: 'contact',
      type: 'select',
      valueKey: 'id',
      include: ['form']
    },
    {
      label: '沟通项目',
      prop: 'project',
      type: 'select',
      valueKey: 'id',
      include: ['form']
    },
    {
      label: '沟通项目',
      prop: 'projectName',
      include: ['show']
    },
    {
      label: '沟通岗位',
      prop: 'post',
      type: 'select',
      valueKey: 'id',
      include: ['form']
    },
    {
      label: '沟通岗位',
      prop: 'postName',
      include: ['show']
    },
    {
      label: '沟通人才',
      prop: 'talent',
      type: 'remoteSelect',
      valueKey: 'id',
      include: ['form'],
      loading: false,
      source: value => {
        const item = context.reminderColumns.find(n => n.prop === 'talent')
        item.loading = true
        return context.$http
          .get('/talent/candidate/list', {
            params: {
              name: value,
              pageSize: 10,
              pageNo: 1
            }
          })
          .then(ret => {
            item.loading = true
            const list = ret.data.list.map(n => {
              return {
                label: n.name,
                value: n,
                key: n.id.toString()
              }
            })
            setTimeout(() => {
              context.$set(item, 'options', list)
            }, 100)
          })
      }
    },
    {
      label: '沟通人才',
      prop: 'talentName',
      include: ['show']
    }
  ]
}
