/**
 * 岗位与人才关联字段
 */
export default function (context) {
  return [
    {
      label: '姓名',
      prop: 'talent.name',
      tableType: 'link',
      include: ['table'],
      click: item => {
        context.openLink(`/candidate/${item.talent.id}/info`)
        // context.$router.push({ path: `/candidate/${item.talent.id}/info` })
      },
    },
    {
      label: '姓名',
      prop: 'talentName',
      include: ['query'],
    },
    {
      label: '所属客户',
      prop: 'companyId',
      type: 'select',
      rule: 'select',
      include: ['query'],
      source: () => {
        return context.$http
          .get('/client/list', {
            params: {
              pageSize: 10000,
            },
          })
          .then(ret => {
            context.companyList = ret.data.list
            return ret.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
              }
            })
          })
      },
      relate: [
        {
          prop: 'postId',
          source: form => {
            return context.$http
              .get('/post/list', {
                params: {
                  companyId: form.companyId,
                  pageSize: 10000,
                },
              })
              .then(res => {
                return res.data.list
                  .map(n => {
                    let type = n.followerId === form.userId ? '负责' : '参与'
                    return {
                      value: n.id,
                      key: n.id,
                      label: `${n.name}`,
                      type,
                    }
                  })
                  .sort(a => {
                    return a.type === '负责' ? -1 : 0
                  })
              })
          },
        },
      ],
    },
    {
      label: '推荐岗位名称',
      prop: 'postName',
      include: ['query'],
    },
    // {
    //   label: '推荐岗位',
    //   prop: 'postId',
    //   type: 'select',
    //   source: () => {
    //     return context.$http
    //       .get('/post/list', {
    //         params: {
    //           pageSize: 10000,
    //         },
    //       })
    //       .then(res => {
    //         return res.data.list.map(n => {
    //           return {
    //             value: n.id,
    //             key: n.id,
    //             label: `${n.name}`,
    //           }
    //         })
    //       })
    //   },
    //   include: ['query'],
    // },
    {
      label: '性别',
      prop: 'talent.sex',
      type: 'select',
      optionKey: 'gender',
      include: ['table'],
    },
    {
      label: '负责人',
      prop: 'follower',
      include: ['table'],
    },
    {
      label: '推荐人',
      prop: 'recommendId',
      include: ['table'],
    },
    {
      label: '推荐客户',
      prop: 'company.name',
      tableType: 'link',
      include: ['table'],
      width: '220',
      click: item => {
        context.openLink(`/client/${item.company.id}/info`)
        // context.$router.push({ path: `/client/${item.company.id}/info` })
      },
    },
    {
      label: '推荐岗位',
      prop: 'post',
      type: 'select',
      tableType: 'link',
      include: ['table'],
      width: '220',
      click: item => {
        context.openLink(`/position/${item.post.id}/info`)
        // context.$router.push({
        //   path: `/position/${item.post.id}/info`
        // })
      },
    },
    {
      label: '岗位负责人',
      prop: 'post.follower.name',
      include: ['table'],
    },
    {
      label: '岗位负责人',
      prop: 'postFollowerId',
      type: 'select',
      include: ['query'],
      source: () => {
        return Promise.resolve(context.users)
      },
    },
    {
      label: '推荐状态',
      prop: 'recommendStatus',
      optionKey: 'recommendStatus',
      type: 'select',
      include: ['table', 'query'],
    },
    {
      label: '推荐时间',
      prop: 'recommendDate',
      include: ['table'],
      format: item => {
        return item.recommendDate ? item.recommendDate.substr(0, 10) : ''
      },
    },
    {
      label: 'offer时间',
      prop: 'offerDate',
      include: ['table'],
      format: item => {
        return item.offerDate ? item.offerDate.substr(0, 10) : ''
      },
    },
    {
      label: 'offer薪资',
      prop: 'offerSalary',
      include: ['table'],
    },
    {
      label: '入职时间',
      prop: 'entryDate',
      include: ['table'],
      format: item => {
        return item.entryDate ? item.entryDate.substr(0, 10) : ''
      },
    },
    {
      label: '开票状态',
      prop: 'finance',
      include: ['table'],
    },
    // {
    //   label: '退费状态',
    //   prop: 'offerSalary',
    //   include: ['table']
    // },
    {
      label: '选择人才',
      prop: 'talentId',
      rule: 'select',
      type: 'remoteSelect',
      include: ['form'],
      loading: false,
      formWidth: 24,
      source: value => {
        const item = context.positionCandidateColumns.find(
          n => n.prop === 'talentId'
        )
        item.loading = true
        return context.$http
          .get('/talent/candidate/list', {
            params: {
              name: value,
              pageSize: 10,
              pageNo: 1,
            },
          })
          .then(ret => {
            item.loading = true
            const list = ret.data.list.map(n => {
              return {
                label: n.name + ` (${n.mobile})`,
                value: n.id,
              }
            })
            setTimeout(() => {
              context.$set(item, 'options', list)
            }, 100)
          })
      },
    },
    // {
    //   label: '负责顾问',
    //   prop: 'followerId',
    //   rule: 'select',
    //   type: 'select',
    //   include: ['form'],
    //   formWidth: 24,
    //   source: () => {
    //     return Promise.resolve(context.validUser)
    //   }
    // },
    {
      label: '负责顾问',
      prop: 'followerId',
      rule: 'select',
      type: 'select',
      include: ['query'],
      formWidth: 24,
      source: () => {
        return Promise.resolve(context.users)
      },
    },
    {
      label: '推荐人',
      prop: 'recommendId',
      rule: 'select',
      type: 'select',
      include: ['query'],
      formWidth: 24,
      source: () => {
        return Promise.resolve(context.users)
      },
    },
    {
      label: '备注',
      prop: 'remark',
      type: 'textarea',
      formWidth: 24,
      include: ['form'],
    },
  ]
}
