<template>
  <div class="ui-reminder">
    <el-dialog
      title="提醒信息"
      :visible.sync="isShow"
      width="80%"
      top="2%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <UiForm
        :formItem="reminderColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        v-if="isShow"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../common/index'
export default {
  mixins: [mixin],
  name: 'UiReminder',
  props: {
    visible: {
      type: Boolean
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.isShow = val
      }
    },
    isShow: {
      handler(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    onSubmit(form) {
      let postData = {
        type: form.type,
        remindDate: form.remindDate,
        content: form.content,
        status: 2,
        talentId: form.talent.id,
        talentName: form.talent.name,
        postId: form.post.id,
        postName: form.post.name,
        companyId: form.company.id,
        companyName: form.company.name,
        projectId: form.project.id,
        projectName: form.project.name,
        contactId: form.contact.id,
        contactName: form.contact.name
      }

      this.$http.post('/remind/save', postData).then(ret => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.$store.dispatch('getReminders', { reload: true })
        }
      })
    }
  }
}
</script>

<style></style>
