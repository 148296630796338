/**
 * 项目经历字段
 */
export default function () {
  return [
    {
      label: '项目名称',
      prop: 'projectName',
      type: 'input',
      rule: 'text',
      formWidth: 24,
      include: ['form', 'table']
    },
    {
      label: '起始时间',
      props: ['startDate', 'endDate'],
      type: 'monthrange',
      rule: 'date',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      include: ['form']
    },
    {
      label: '开始时间',
      prop: 'startDate',
      type: 'date',
      format: 'YYYY-MM',
      include: ['table']
    },
    {
      label: '结束时间',
      prop: 'endDate',
      type: 'date',
      format: 'YYYY-MM',
      include: ['table']
    },
    {
      label: '公司名称',
      prop: 'object',
      type: 'input',
      rule: 'text',
      formWidth: 24,
      include: ['form', 'table']
    },
    {
      label: '项目详情',
      prop: 'wordContent',
      type: 'textarea',
      formWidth: 24,
      include: ['form', 'table']
    },
    {
      label: '项目职责',
      prop: 'projectResp',
      type: 'textarea',
      formWidth: 24,
      include: ['form', 'table']
    },
  ]
}
