import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Share from '../views/Share/index.vue'
import Forum from '../views/Share/forum.vue'

// 客户
import Client from '../views/Client/Client.vue'
import ClientDetail from '../views/Client/ClientDetail.vue'
import ClientInfo from '../views/Client/ClientInfo.vue'
import ClientContact from '../views/Client/ClientContact.vue'
import ClientHistory from '../views/Client/ClientHistory.vue'
import ClientPosition from '../views/Client/ClientPosition.vue'
import ClientProject from '../views/Client/ClientProject.vue'
import ClientContract from '../views/Client/ClientContract.vue'

// 岗位
import Position from '../views/Position/Position.vue'
import PositionDetail from '../views/Position/PositionDetail.vue'
import PositionInfo from '../views/Position/PositionInfo.vue'
import PositionCandidate from '../views/Position/PositionCandidate.vue'
import PositionRelate from '../views/Position/PositionRelate.vue'
import PositionHistory from '../views/Position/PositionHistory.vue'

// 客户联系人
import Contact from '../views/Client/Contact.vue'
import Contract from '../views/Client/Contract.vue'

// 人才
import Candidate from '../views/Candidate/Candidate.vue'
import CandidateDetail from '../views/Candidate/CandidateDetail.vue'
import CandidateResume from '../views/Candidate/CandidateResume.vue'
import CandidateInfo from '../views/Candidate/CandidateInfo.vue'
import CandidateEducation from '../views/Candidate/CandidateEducation.vue'
import CandidateExperience from '../views/Candidate/CandidateExperience.vue'
import CandidateHistory from '../views/Candidate/CandidateHistory.vue'
import CandidatePosition from '../views/Candidate/CandidatePosition.vue'
import CandidateProject from '../views/Candidate/CandidateProject.vue'

// 项目
import Project from '../views/Project/Project.vue'
import ProjectInfo from '../views/Project/ProjectInfo.vue'
import ProjectPosition from '../views/Project/ProjectPosition.vue'
import ProjectDetail from '../views/Project/ProjectDetail.vue'
import ProjectCandidateDetail from '../views/Project/ProjectCandidateDetail.vue'

// 推荐管理
import Recommend from '../views/Recommend/Recommend.vue'

// 财务管理
import Finance from '../views/Finance/Finance.vue'

// 数据报表
import Data from '../views/Data/Data.vue'
import DataCandidate from '../views/Data/Candidate.vue'

// 邮件模板
import Email from '../views/Email/Email.vue'

// 首页报表
import Dashboard from '../views/Dashboard.vue'

// 管理设置
import Setting from '../views/setting/Setting.vue'
import Role from '../views/setting/Role.vue'
import Account from '../views/setting/Account.vue'
import Attribute from '../views/setting/Attribute.vue'
import Temp from '../views/setting/Template.vue'

// 小程序
import Agent from '../views/Wechat/Agent.vue'
import Wechat from '../views/Wechat/Index.vue'
import Community from '../views/Wechat/Community.vue'

// 日志列表
import Log from '../views/Log/Log.vue'

// 工作日志
import WorkLog from '../views/Work/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        component: Dashboard,
        meta: {
          title: '首页'
        }
      },
      {
        path: 'work',
        component: WorkLog,
        meta: {
          title: '工作日志',
        }
      },
      {
        path: 'contact',
        component: Contact,
        meta: {
          title: '客户联系人',
        }
      },
      {
        path: 'contract',
        component: Contract,
        meta: {
          title: '合同管理',
        }
      },
      {
        path: 'client',
        component: Client,
        meta: {
          title: '客户管理',
        }
      },
      {
        path: 'client/:id',
        component: ClientDetail,
        redirect: to => to.path + 'info',
        children: [
          {
            path: 'info',
            component: ClientInfo,
          },
          {
            path: 'contact',
            component: ClientContact,
          },
          {
            path: 'project',
            component: ClientProject,
          },
          {
            path: 'contract',
            component: ClientContract,
          },
          {
            path: 'position',
            component: ClientPosition,
          },
          {
            path: 'history',
            component: ClientHistory,
          },
        ],
      },
      {
        path: 'project',
        component: Project,
        meta: {
          title: '项目管理',
        }
      },
      {
        path: 'project/:id',
        component: ProjectDetail,
        redirect: to => to.path + 'info',
        children: [
          {
            path: 'info',
            component: ProjectInfo,
          },
          {
            path: 'position',
            component: ProjectPosition,
          },
        ],
      },
      {
        path: 'position/:id',
        component: PositionDetail,
        redirect: to => to.path + 'info',
        children: [
          {
            path: 'info',
            component: PositionInfo,
          },
          {
            path: 'candidate',
            component: PositionCandidate,
          },
          {
            path: 'relate',
            component: PositionRelate,
          },
          {
            path: 'history',
            component: PositionHistory,
          },
        ],
      },
      {
        path: 'recommend',
        component: Recommend,
        meta: {
          title: '推荐管理',
        }
      },
      {
        path:
          'project/:projectId/:projectName/position/:positionId/:positionName/candidate/:id',
        component: ProjectCandidateDetail,
      },
      {
        path: 'finance',
        component: Finance,
        meta: {
          title: '财务管理',
        }
      },
      {
        path: 'candidate',
        component: Candidate,
        meta: {
          title: '人才管理',
        }
      },
      {
        path: 'candidate/type',
        component: Candidate,
        meta: {
          title: '互联网行业人才管理',
        }
      },
      {
        path: 'candidate/:id',
        component: CandidateDetail,
        redirect: to => to.path + 'info',
        children: [
          {
            path: 'info',
            component: CandidateInfo,
          },
          {
            path: 'resume',
            component: CandidateResume,
          },
          {
            path: 'education',
            component: CandidateEducation,
          },
          {
            path: 'experience',
            component: CandidateExperience,
          },
          {
            path: 'history',
            component: CandidateHistory,
          },
          {
            path: 'position',
            component: CandidatePosition,
          },
          {
            path: 'project',
            component: CandidateProject,
          },
        ],
      },
      {
        path: 'setting',
        component: Setting,
        meta: {
          title: '管理设置',
        }
      },
      {
        path: 'wechat/agent',
        component: Agent,
        meta: {
          title: '微信代理',
        }
      },
      {
        path: 'wechat/index',
        component: Wechat,
        meta: {
          title: '微信管理',
        }
      },
      {
        path: 'wechat/Community',
        component: Community,
        meta: {
          title: '社区管理',
        }
      },
      {
        path: 'data',
        component: Data,
        meta: {
          title: '数据报表',
        }
      },
      {
        path: 'data/candidate',
        component: DataCandidate,
        meta: {
          title: '人才报表',
        }
      },
      {
        path: 'setting/role',
        component: Role,
        meta: {
          title: '角色管理',
        }
      },
      {
        path: 'setting/account',
        component: Account,
        meta: {
          title: '账号管理',
        }
      },
      {
        path: 'setting/attribute',
        component: Attribute,
        meta: {
          title: '属性管理',
        }
      },
      {
        path: 'setting/template',
        component: Temp,
        meta: {
          title: '模板管理',
        }
      },
      {
        path: 'setting/email',
        component: Email,
        meta: {
          title: '邮件模板',
        }
      },
      {
        path: 'login',
        component: Login,
        meta: {
          title: '登录',
        }
      },
      {
        path: 'position',
        component: Position,
        meta: {
          title: '职位管理',
        }
      },
      {
        path: 'log',
        component: Log
      }
    ],
  },
  {
    path: '/share',
    component: Share,
  },
  {
    path: '/forum',
    component: Forum,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
})

export default router
