<template>
  <div class="">
    <UiQuery
      :items="roleColumns"
      :ctrls="ctrls"
      :rightCtrls="rightCtrls"
    ></UiQuery>
    <UiTable
      :columns="roleColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    ></UiTable>
    <el-dialog
      title="角色信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="roleColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
// import gql from 'graphql-tag'
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  data() {
    return {
      queryUrl: '/setting/role/list',
      rightCtrls: [
        {
          label: '创建',
          click: () => {
            this.isShow = true
          },
        },
      ],
      tableCtrls: [
        {
          label: '修改',
          click: (item) => {
            this.currentItem = { ...item }
            this.isShow = true
          },
        },
        {
          label: '删除',
          type: 'danger',
          role: [1],
          click: (item) => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              this.$http
                .get('/setting/role/del', {
                  params: {
                    id: item.id,
                  },
                })
                .then((ret) => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          },
        },
      ],
    }
  },
  methods: {
    onSubmit(form) {
      if (this.currentItem.id) {
        form.id = this.currentItem.id
      }
      this.$http
        .post('/setting/role/save', {
          ...form,
        })
        .then((ret) => {
          if (ret.code === 200) {
            this.$message.success('提交成功 !')
            this.isShow = false
            this.query()
            return
          }
          this.$message.error(ret.message)
        })
    },
  },
  mounted() {
    this.query()
    this.setTitle()

    // this.$apollo
    //   .query({
    //     query: gql`
    //       {
    //         role {
    //           id
    //           createdAt
    //           modifiedAt
    //           creator
    //           modifier
    //           isDeleted
    //           name
    //           sign
    //           authority
    //           remark
    //         }
    //         roleCount
    //       }
    //     `,
    //   })
    //   .then((res) => {
    //     console.log('res', res)
    //   })
  },
}
</script>