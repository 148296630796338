<template>
  <div>
    <UiTable
      :columns="experienceColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    >
      <template #ctrlHeader>
        <el-button type="primary" @click="isShow = true" size="small">
          创建经历
        </el-button>
      </template>
    </UiTable>

    <el-dialog
      title="工作经历"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="experienceColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        v-if="isShow"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    talentId() {
      return parseInt(this.$route.params.id)
    }
  },
  data() {
    return {
      queryUrl: '/talent/experience/list',
      tableCtrls: [
        {
          label: '修改',
          click: item => {
            this.currentItem = { ...item }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              this.$http
                .get('/talent/experience/del', {
                  params: {
                    id: item.id
                  }
                })
                .then(ret => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          }
        }
      ],
      tableHeight: window.innerHeight - 190
    }
  },
  methods: {
    onSubmit(form) {
      let postData = {
        ...form,
        type: 2,
        talentId: this.talentId
      }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      this.$http.post('/talent/experience/save', postData).then(ret => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    }
  },
  mounted() {
    this.query({
      talentId: this.talentId,
      type: 2
    })
  }
}
</script>

<style></style>
