/**
 * 账号字段
 */
export default function (context) {
  return [
    {
      label: '面试信息',
      type: 'divide',
      include: ['form'],
    },
    {
      label: '面试时间',
      prop: 'viewTime',
      type: 'dateTime',
      rule: 'select',
      include: ['form'],
    },
    {
      label: '面试方式',
      prop: 'viewType',
      type: 'select',
      rule: 'select',
      optionKey: 'interviewType',
      include: ['form'],
      relate: [
        {
          prop: 'viewInfo',
          type: 'string',
          setValue: () => {
            const content = `关于面试准备，以下建议可供参考：<br/>
1. 怎么做好一个面试准备。<a href="https://mp.weixin.qq.com/s/LPH1d7DhDy1qbXPJVCvr2g" target="_blank">https://mp.weixin.qq.com/s/LPH1d7DhDy1qbXPJVCvr2g</a><br/>
2. 现场面试Tips。<a href="https://mp.weixin.qq.com/s/NDONWgHABIvmJvy-XcBunQ" target="_blank">https://mp.weixin.qq.com/s/NDONWgHABIvmJvy-XcBunQ</a>`
              return Promise.resolve(content)
          },
        },
        {
          prop: 'viewAddress',
          type: 'address',
          setValue: (params, item) => {
            const { viewType } = params
            const ret = item.options.find(n => n.value === viewType)
            const label = ret ? ret.label : ''
            if (label === '现场面试') {
              return Promise.resolve(context.currentItem.viewAddressStore)
            } else {
              return Promise.resolve('')
            }
          },
        },
      ],
    },
    {
      label: '面试地点',
      prop: 'viewAddress',
      type: 'address',
      include: ['form'],
    },
    {
      label: '面试链接',
      prop: 'viewLink',
      type: 'input',
      include: ['form'],
    },
    {
      label: '联系人',
      prop: 'viewUser',
      type: 'select',
      valueKey: 'id',
      include: ['form'],
      source: () => {
        return context.$http
          .get('/client/user/list', {
            params: {
              companyId: context.form.companyId,
            },
          })
          .then(res => {
            return res.data && res.data.list
              ? res.data.list.map(n => {
                  return {
                    label: n.name,
                    value: n,
                    key: n.id,
                  }
                })
              : []
          })
      },
    },
    {
      label: '附加信息',
      prop: 'viewInfo',
      type: 'textarea',
      include: ['form'],
      rows: 9,
    },
    {
      label: '人才邮箱',
      prop: 'viewEmail',
      type: 'input',
      rule: 'text',
      include: ['form'],
    },
    {
      label: '邮件模板',
      prop: 'emailTemplate',
      rule: 'select',
      type: 'select',
      include: ['form'],
      source: () => {
        return context.$http
          .get('/email/list', {
            params: {
              pageSize: 10000,
              scene: 687,
            },
          })
          .then(ret => {
            context.emailTemplateList = ret.data.list
            context.currentItem.emailTemplate = ret.data.list[0].id
            return ret.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
                key: n.id,
                obj: n,
              }
            })
          })
      },
    },
    {
      label: '抄送联系人',
      prop: 'viewContactEmail',
      type: 'select',
      include: ['form'],
      source: () => {
        return context.$http
          .get('/client/user/list', {
            params: {
              companyId: context.form.companyId,
            },
          })
          .then(body => {
            return body.data.list.map(n => {
              return {
                label: n.name + ' (' + n.email + ')',
                value: n.email,
              }
            })
          })
      },
    },
    {
      label: '抄送邮箱',
      prop: 'viewCopyEmail',
      type: 'input',
      include: ['form'],
    },
    {
      label: '面试通知记录',
      type: 'divide',
      include: ['form'],
    },
  ]
}
