<template>
  <div class="">
    <UiQuery
      :items="clientColumns"
      :ctrls="ctrls"
      :defaultParams="defaultParams"
      :rightCtrls="rightCtrls"
    >
    </UiQuery>
    <UiTable
      :columns="clientColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    >
      <template #ctrlHeader>
        <el-button type="info" size="mini" plain="" @click="isShowLog = true">
          操作日志
        </el-button>
      </template>
    </UiTable>
    <el-dialog
      title="客户信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="clientColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <UiLogTable :visible.sync="isShowLog"></UiLogTable>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  data() {
    return {
      queryUrl: '/client/list',
      rightCtrls: [
        {
          label: '创建客户',
          float: 'right',
          plain: false,
          click: () => {
            this.isShow = true
          }
        }
      ],
      tableCtrls: [
        {
          label: '修改',
          role: [1, 6, 7, 8],
          click: item => {
            this.currentItem = {
              ...item,
              adviserId: item.adviserId.split(',').map(n => parseInt(n)),
              label: item.label.split(',').map(n => parseInt(n))
            }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          role: [1],
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              this.$http
                .get('/client/del', {
                  params: {
                    id: item.id
                  }
                })
                .then(ret => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          }
        }
      ]
    }
  },
  methods: {
    onSubmit(form) {
      const _form = this.$formatObj(form)
      this.$http
        .post('/client/save', {
          ..._form,
          id: this.currentItem.id
        })
        .then(ret => {
          if (ret.code === 200) {
            this.$message.success('提交成功 !')
            this.isShow = false
            this.query()
            return
          }
          this.$message.error(ret.message)
        })
    }
  },
  mounted() {
    // 读取默认参数
    const key = `path${this.$route.path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    }
    this.query({ ...this.defaultParams, ...this.$route.query })
    this.setTitle()
  }
}
</script>

