<template>
  <div class="">
    <el-row>
      <el-col :span="6">
        <el-radio-group v-model="type" @change="onRadioChange">
          <el-radio :label="1">长期</el-radio>
          <el-radio :label="2">短期</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="12">
        <el-date-picker
          v-model="dates"
          :disabled="disabled"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="onChange"
        >
        </el-date-picker>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    startTime: [Number, String],
    endTime: [Number, String]
  },
  watch: {
    startTime: {
      immediate: true,
      handler () {
        // this.handleDefaul()
      }
    },
    endTime: {
      immediate: true,
      handler () {
        this.handleDefaul()
      }
    },
    type: {
      // immediate: true,
      handler () {
        // if (val === 1) {
        //   this.$emit('change', [
        //     this.$dayjs().format('YYYY-MM-DD'),
        //     this.$dayjs('2050-01-01').format('YYYY-MM-DD')
        //   ])
        //   this.dates = []
        //   this.disabled = true
        // } else {
        //   this.$emit('change', [])
        //   this.disabled = false
        // }
      }
    }
  },
  data () {
    return {
      disabled: false,
      type: 1,
      dates: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '未来一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '未来一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '未来三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  methods: {
    onRadioChange (value) {
      if (value === 2) {
        this.disabled = false
      } else {
        this.disabled = true
        this.dates = []
        this.$emit('change', [
          this.$dayjs().format('YYYY-MM-DD'),
          this.$dayjs('2050-01-01').format('YYYY-MM-DD')
        ])
      }
    },
    handleDefaul () {
      if (this.endTime) {
        let target = this.$dayjs('2040-01-01')
        let cur = this.$dayjs(this.endTime)
        if (cur.isAfter(target)) {
          this.type = 1
          this.dates = []
          this.$emit('change', [
            this.$dayjs(this.startTime).format('YYYY-MM-DD'),
            this.$dayjs('2050-01-01').format('YYYY-MM-DD')
          ])
          this.disabled = true
        } else {
          this.type = 2
          this.disabled = false
          this.dates = [this.startTime, this.endTime]
          this.$emit('change', [
            this.$dayjs(this.startTime).format('YYYY-MM-DD'),
            this.$dayjs(this.endTime).format('YYYY-MM-DD')
          ])
        }
      } else {
        this.type = 1
        this.$emit('change', [
          this.$dayjs().format('YYYY-MM-DD'),
          this.$dayjs('2050-01-01').format('YYYY-MM-DD')
        ])

        this.dates = []
        this.disabled = true
      }
    },
    onChange (value) {
      let [st, et] = value
      this.$emit('change', [
        this.$dayjs(st).format('YYYY-MM-DD'),
        this.$dayjs(et).format('YYYY-MM-DD')
      ])
    }
  },
  mounted () {}
}
</script>
