<template>
  <div>
    <UiTable
      :columns="candidateColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    >
    </UiTable>

    <el-dialog
      title="关联人才"
      :visible.sync="isShowRelate"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="columns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowRelate = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  props: {
    form: Object,
  },
  computed: {
    postId() {
      return parseInt(this.$route.params.id)
    },
    columns() {
      return this.positionCandidateColumns.filter(n => n.prop !== 'talentId')
    },
    // 有权关联的用户
    validUser() {
      let ret = []
      // 岗位负责人
      if (this.form.followerId) {
        ret.push(this.userList.find(n => n.value === this.form.followerId))
      }
      // 岗位跟进人
      if (this.form.adviserId) {
        let adviser = this.form.adviserId
          .split(',')
          .filter(n => n !== '')
          .map(n => Number(n))
        this.userList.forEach(n => {
          if (adviser.includes(n.value)) {
            ret.push(n)
          }
        })
      }
      return ret
    },
  },
  data() {
    return {
      queryUrl: '/related/talent',
      isShowRelate: false,
      tableCtrls: [
        {
          label: '关联',
          click: item => {
            this.currentItem = { ...item }
            this.isShowRelate = true
          },
        },
      ],
      tableHeight: window.innerHeight - 190,
    }
  },
  methods: {
    onSubmit(form) {
      let postData = {
        talentId: this.currentItem.id,
        postId: this.postId,
        followerId: form.followerId,
        remark: form.remark,
      }
      this.$http.post('/post/talent/save', postData).then(ret => {
        this.isShowRelate = false
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          return
        }
        this.$message.error(ret.message)
      })
    },
  },
  mounted() {
    this.query({
      postId: this.postId,
    })
  },
}
</script>

<style></style>
