<template>
  <div class="ui-share">
    <div class="inner">
      <div class="banner" :style="bg"></div>
      <div class="title">
        {{ content.title || '热招岗位' }}
        <em style="padding: 0 10px; font-style: normal">|</em>
        {{ content.name }}
      </div>
      <div class="tag">
        <el-tag
          type="info"
          size="small"
          v-for="t in content.labels"
          :key="t.id"
        >
          {{ t.name }}
        </el-tag>
      </div>
      <div class="code">
        <el-row type="flex" align="middle">
          <el-col :span="11" :offset="0">
            <img :src="code" alt="" class="qrcode" />
          </el-col>
          <el-col :span="13" :offset="0">
            <p>长按识别小程序码</p>
            <p>查看更多详细信息</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footer">
      <img src="../../assets/logo-white.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    bg() {
      return {
        backgroundImage: `url(${this.avatar})`,
      }
    },
    avatar() {
      if (!this.content.avatar) {
        return 'https://rockmind-next-1259377074.cos.ap-guangzhou.myqcloud.com/public/postBanner.png'
      }
      return `/file/download?key=${this.content.avatar}`
    },
    url() {
      return this.$route.query.url
    },
    code() {
      return `/share/getwxacodeunlimit?url=${encodeURIComponent(this.url)}`
    },
  },
  data() {
    return {
      content: {},
    }
  },
  methods: {
    getContent() {
      let [page, query] = this.url.split('?')
      let params = {}
      let url =
        page === 'pages/consultant/detail' ? '/wechat/agent' : '/post/desc'
      query.split('&').forEach((item) => {
        let [key, value] = item.split('=')
        params[key] = value
      })
      if (url) {
        this.$http
          .get(url, {
            params: {
              id: params.id,
            },
          })
          .then((res) => {
            this.content = res.data
          })
      }
    },
  },
  created() {
    this.getContent()
  },
}
</script>

<style lang="less">
.ui-share {
  max-width: 640px;
  margin: 0 auto;
  background: #ecb745;
  overflow: hidden;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;

  .banner {
    height: 170px;
    background-position: center;
    background-size: cover;

    img {
      height: 100%;
    }
  }

  .footer {
    color: #fff;
    text-align: center;
    padding: 0 0 15px;
    font-size: 10px;

    img {
      display: block;
      width: 30%;
      margin: 0 auto;
    }

    h5 {
      font-size: 14px;
      margin: 0;
    }
  }

  .code {
    margin: 0 30px;
    padding: 20px 0;
    line-height: 1.5;
  }

  .title {
    margin: 30px 30px 10px;
    white-space: nowrap;
    color: #e4b85a;
    font-size: 20px;
    font-weight: bold;
  }

  .qrcode {
    width: 90px;
    height: 90px;
  }

  .tag {
    margin: 0 30px;
    height: 70px;
    overflow: hidden;
    padding: 0 0 20px;
    border-bottom: 1px solid #bbb;

    .el-tag {
      margin: 0 10px 10px 0;
      color: #fff;
      background: #343434;
    }
  }

  .inner {
    margin: 20px 10px 10px;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  img {
    max-width: 100%;
  }
}
</style>