<template>
  <div class="ui-query">
    <el-form :model="params" size="small">
      <el-row :gutter="10">
        <template v-for="item in formItem">
          <el-col :span="item.span || 2" :key="item.prop">
            <el-form-item :key="item.prop">
              <template
                v-if="item.type === 'input' || item.type === 'textarea'"
              >
                <el-input
                  v-model="params[item.prop]"
                  :placeholder="item.label"
                  clearable
                ></el-input>
              </template>

              <template v-else-if="item.type === 'tagGroup'">
                <Tag
                  v-model="params[item.prop]"
                  showType="input"
                  :placeholder="item.label"
                ></Tag>
              </template>

              <template v-else-if="item.type === 'tag'">
                <el-select
                  v-model="params[item.prop]"
                  :placeholder="item.label"
                  :clearable="handleClear(item)"
                  filterable
                  @change="onChange(item, $event)"
                >
                  <el-option
                    v-for="o in item.options"
                    :key="o.value"
                    :label="o.label"
                    :value="o.value"
                  ></el-option>
                </el-select>
              </template>

              <template v-else-if="item.type == 'select'">
                <el-select
                  v-model="params[item.prop]"
                  :placeholder="item.label"
                  :clearable="handleClear(item)"
                  filterable
                  @change="onChange(item, $event)"
                >
                  <el-option
                    v-for="o in item.options"
                    :key="o.value"
                    :label="o.label"
                    :value="o.value"
                  ></el-option>
                </el-select>
              </template>

              <template v-else-if="item.type == 'multiSelect'">
                <el-select
                  v-model="params[item.prop]"
                  :placeholder="item.label"
                  collapse-tags
                  clearable
                  filterable
                >
                  <el-option
                    v-for="o in item.options"
                    :key="o.value"
                    :label="o.label"
                    :value="o.value"
                  ></el-option>
                </el-select>
              </template>

              <template v-else-if="item.type == 'date'">
                <DatePicker
                  :startTime.sync="params[item.props[0]]"
                  :endTime.sync="params[item.props[1]]"
                ></DatePicker>
              </template>

              <template v-else-if="item.type == 'datetimerange'">
                <DatePicker
                  :startTime.sync="params[item.props[0]]"
                  :endTime.sync="params[item.props[1]]"
                  type="datetimerange"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                ></DatePicker>
              </template>

              <template v-else-if="item.type === 'level'">
                <Levels
                  :valueId.sync="params[item.prop]"
                  :placeholder="item.placeholder"
                ></Levels>
              </template>

              <template v-else>
                <el-input
                  v-model="params[item.prop]"
                  :placeholder="item.label"
                  clearable
                ></el-input>
              </template>
            </el-form-item>
          </el-col>
        </template>

        <el-col :span="showCtrls.length > 3 ? 7 : 4">
          <el-form-item class="btn-group">
            <template v-for="item in showCtrls">
              <Analysis
                style="display: inline-block; margin: 0 0 0 10px"
                v-if="item.type === 'upload'"
                :key="item.label"
                placeholder="导入简历"
                @on-complete="onComplete"
              ></Analysis>
              <el-button
                v-else
                :key="item.label"
                :type="item.type || 'primary'"
                :plain="item.plain !== undefined ? item.plain : true"
                @click="handleCtrl(item)"
                :disabled="handleDisabled(item)"
                :loading="item.loading"
                style="display: inline-block; margin: 0 0 0 10px"
              >
                {{ item.label }}
              </el-button>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form inline size="mini">
      <template v-for="item in showTableCtrl">
        <el-form-item :key="item.label">
          <template v-if="item.type == 'upload'">
            <Uploader
              :btn="item.label"
              :typeStyle="item.style"
              :accept="item.accept"
              :disabled="item.disabled(params)"
              @on-success="handleCtrl(item, $event)"
            ></Uploader>
          </template>

          <template v-else>
            <el-tooltip
              :content="item.tooltip"
              placement="top"
              effect="light"
              :disabled="!!!item.tooltip"
            >
              <div style="display: inline-block">
                <el-button
                  :type="item.style || 'primary'"
                  plain
                  :loading="item.loading"
                  :disabled="handleDisabled(item)"
                  @click="handleCtrl(item)"
                >
                  {{ item.label }}
                </el-button>
              </div>
            </el-tooltip>
          </template>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import Analysis from '../components/Analysis.vue'
import Uploader from '../components/Uploader.vue'
import DatePicker from '../components/DatePicker.vue'
import Tag from '../components/Tag.vue'
import mixin from '../common/compoment'
export default {
  name: 'UiQuery',
  mixins: [mixin],
  components: {
    DatePicker,
    Tag,
    Uploader,
    Analysis,
  },
  props: {
    items: {
      type: Array,
    },
    defaultParams: Object,
    ctrls: {
      type: Array,
    },
    rightCtrls: {
      type: Array,
    },
    tableCtrl: {
      type: Array,
    },
  },
  watch: {
    defaultParams: {
      immediate: true,
      handler(obj) {
        if (obj) {
          let keys = Object.keys(obj)
          if (keys && keys.length) {
            keys.forEach((key) => {
              let value = obj[key]
              this.$set(this.params, key, value)
            })
          } else {
            this.params = {}
          }
        }
      },
    },
    items: {
      deep: true,
      handler(items) {
        items.forEach((item) => {
          if (item.value) {
            this.$set(this.params, item.prop, item.value)
          }
        })
      },
    },
  },
  computed: {
    // 格式化查询字段
    formItem() {
      return this.items
        .filter((n) => {
          if (n.include && n.include.length) {
            return n.include.includes('query')
          }
        })
        .map((n) => {
          if (n.optionKey) {
            n.options = this[n.optionKey] || this.enumOptions[n.optionKey]
          }
          return n
        })
    },
    showCtrls() {
      let ctrls = this.ctrls
        ? this.ctrls.filter((n) => (n.show ? n.show() : true))
        : []
      let rightCtrls = this.rightCtrls
        ? this.rightCtrls.filter((n) => (n.show ? n.show() : true))
        : []
      return [...ctrls, ...rightCtrls]
    },
    showTableCtrl() {
      return this.tableCtrl
        ? this.tableCtrl.filter((n) => (n.show ? n.show() : true))
        : []
    },
  },
  data() {
    return {
      params: {},
    }
  },
  methods: {
    onComplete(state) {
      this.$emit('on-complete', state)
    },
    onChange(item, value) {
      if (item.relate && item.relate.length) {
        item.relate.forEach((n) => {
          let target = this.formItem.find((i) => i.prop === n.prop)
          this.$set(this.params, n.prop, '')
          if (target) {
            n.source(this.params).then((data) => {
              this.$set(target, 'options', data)
            })
          }
        })
      }
      item.onChange && item.onChange(value)
    },
    setValue({ key, value }) {
      this.$set(this.params, key, value)
    },
    handleDisabled(item) {
      return item.disabled ? item.disabled(this.params) : false
    },
    handleClear(item) {
      return item.clearable !== false
    },
    handleCtrl(item, ret) {
      item.click && item.click(this.params, ret)
    },
  },
  mounted() {
    if (this.formItem) {
      this.formItem.forEach((item) => {
        if (item.source) {
          item.source(this.params).then((data) => {
            this.$set(item, 'options', data)
          })
        }
        if (item.value) {
          this.$set(this.params, item.prop, item.value)
        }
      })
    }
  },
}
</script>

<style lang="less">
.ui-query {
  .el-form-item {
    margin-bottom: 10px !important;
  }
  .el-input__inner {
    border: 1px solid #94969c;

    &::placeholder {
      color: #94969c;
    }
  }
  .btn-group {
    .el-button {
      padding: 9px 10px;
    }
  }
}
</style>
