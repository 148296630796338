<template>
  <div>
    <el-upload
      class="ui-uploader"
      ref="upload"
      :action="action"
      :multiple="multiple"
      :data="postData"
      :file-list="fileList"
      :show-file-list="showFileList"
      :limit="limit"
      :on-success="onSuccess"
      :on-progress="onProgress"
      :on-remove="onRemove"
      :on-error="onError"
      :headers="uploadHeaders"
      :name="name"
      :class="type === 'avatar' ? 'ui-uploader-avatar' : ''"
    >
      <template v-if="type === 'avatar'">
        <el-tooltip content="点击上传" placement="top" effect="dark">
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-tooltip>
      </template>
      <template v-else>
        <el-button size="small" :type="btnType">
          {{ btn }}
        </el-button>
      </template>
    </el-upload>

    <el-dialog
      title="提示"
      :visible.sync="loading"
      width="500px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center
      append-to-body
    >
      <div style="text-align: center; font-size: 16px">
        <i class="el-icon-loading"></i> 加载中...
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Object],
    },
    type: {
      default: 'file',
    },
    dir: String,
    btnType: {
      default: 'primary',
    },
    placeholder: String,
    multiple: {
      default: false,
      type: Boolean,
    },
    action: {
      default: '/file',
      type: String,
    },
    headers: {
      default: '',
      type: String,
    },
    name: {
      default: 'file',
      type: String,
    },
  },
  computed: {
    uploadHeaders() {
      if (this.headers && this.headers === 'masterKey') {
        return {
          Authorization: `Bearer ${this.masterKey}`,
        }
      }
      return {}
    },
    masterKey() {
      return this.$store.state.masterKey
    },
    limit() {
      return this.multiple ? 0 : 1
    },
    showFileList() {
      return this.type === 'file'
    },
    btn() {
      return this.placeholder
        ? this.placeholder
        : this.type === 'analysis'
        ? '上传简历'
        : '点击上传'
    },
    postData() {
      return {
        type: this.type,
        dir: this.dir || '',
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          if (value.id) {
            this.imageUrl = value.url
          } else {
            const pathname = value.startsWith('http')
              ? new URL(value).pathname
              : value
            this.imageUrl = '/file/download?key=' + pathname

            if (this.multiple) {
              this.fileList = value.split('||').map(n => {
                return {
                  name: n,
                }
              })
            }
          }
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      imageUrl: '',
      fileList: [],
    }
  },
  methods: {
    onRemove(file, fileList) {
      let urls = fileList
        .map(n => {
          if (n.response) {
            return n.response.data
          }
          return n.name
        })
        .join('||')
      this.$emit('input', urls)
    },
    onProgress() {
      this.loading = true
    },
    onSuccess(res, file, fileList) {
      this.loading = false
      console.log('fileList', fileList)
      if (res.code === 200) {
        if (file.raw.type.match('image')) {
          this.imageUrl = URL.createObjectURL(file.raw)
        }
        let urls = fileList
          .map(n => {
            if (n.response) {
              return n.response.data
            }
            return n.name
          })
          .join('||')
        this.$emit('input', urls)
        this.$emit('on-success', res)
        if (this.type === 'analysis') {
          this.$refs.upload.clearFiles()
        }
      }

      if (Array.isArray(res)) {
        if (file.raw.type.match('image')) {
          this.imageUrl = URL.createObjectURL(file.raw)
        }
        let urls = fileList.map(n => {
          if (n.response) {
            return n.response[0]
          }
          return n.name
        })
        this.$emit('input', urls[0])
        this.$emit('on-success', res)
        if (this.type === 'analysis') {
          this.$refs.upload.clearFiles()
        }
      }
    },
    onError(err) {
      console.log('err', err)
    },
  },
}
</script>

<style lang="less">
.ui-uploader-avatar {
  border: 1px dashed #d9d9d9;
  width: 178px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px !important;
  text-align: center;
}
.avatar {
  max-width: 178px;
  max-height: 178px;
  display: block;
  margin: 0 auto;
}
</style>
