/**
 * 客户字段
 */
export default function () {
  return [
    {
      label: '基本信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: 'ID',
      prop: 'id',
      include: ['table'],
    },
    {
      label: '标识',
      prop: 'scene',
      include: ['table'],
    },
    {
      label: '模板名称',
      prop: 'name',
      type: 'input',
      include: ['form', 'table', 'query'],
    },
    {
      label: '场景',
      prop: 'scene',
      optionKey: 'emailScene',
      type: 'select',
      include: ['form', 'table', 'query'],
    },
    {
      label: '标题',
      prop: 'subject',
      type: 'input',
      include: ['form'],
    },
    {
      label: '备注',
      prop: 'remark',
      type: 'input',
      include: ['form', 'table'],
    },
    {
      label: '模板内容',
      prop: 'content',
      type: 'textarea',
      rows: 20,
      include: ['form'],
    },
    {
      label: '邮件附件',
      prop: 'file',
      type: 'multiple-upload',
      rows: 20,
      include: ['form'],
    },
  ]
}
