<template>
  <div class="">
    <UiQuery
      :items="projectColumns"
      :defaultParams="defaultParams"
      :ctrls="ctrls"
      :rightCtrls="rightCtrls"
    ></UiQuery>
    <UiTable
      :columns="projectColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    ></UiTable>
    <el-dialog
      title="项目信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="projectColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  data() {
    return {
      queryUrl: '/project/list',
      rightCtrls: [
        {
          label: '创建项目',
          float: 'right',
          plain: false,
          click: () => {
            this.isShow = true
          }
        }
      ],
      tableCtrls: [
        {
          label: '详情',
          type: 'info',
          click: item => {
            this.$router.push({ path: `/project/${item.id}/info` })
          }
        },
        {
          label: '修改',
          click: item => {
            this.currentItem = { ...item, postId: item.post.map(n => n.id) }
            this.isShow = true
          }
        },
        {
          label: '删除',
          type: 'danger',
          role: [1],
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning'
            }).then(() => {
              this.$http
                .get('/project/del', {
                  params: {
                    id: item.id
                  }
                })
                .then(ret => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          }
        }
      ]
    }
  },
  methods: {
    onSubmit(form) {
      let postData = { ...form }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      this.$http.post('/project/save', postData).then(ret => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    }
  },
  mounted() {
    // 读取默认参数
    const key = `path${this.$route.path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    }
    this.query({ ...this.defaultParams, ...this.$route.query })
    this.setTitle()
  }
}
</script>