export default function (context) {
  return [
    {
      label: '岗位',
      prop: 'postId',
      type: 'select',
      include: ['form'],
      source: () => {
        return context.$http.get('/post/list').then(ret => {
          return ret.data.list.map(n => {
            return {
              label: n.name,
              value: n.id
            }
          })
        })
      }
    }
  ]
}
