import Vue from 'vue'
import store from '../store'
import VueSocketIO from 'vue-socket.io'

let url = location.href.match('rockmind.cn')
  ? location.origin
  : 'http://127.0.0.1:7001'

Vue.use(
  new VueSocketIO({
    // debug: true,
    connection: url,
    vuex: {
      store,
      actionPrefix: 'SOCKET_'
    },
    options: {
      transports: ['websocket']
    } //Optional options
  })
)
