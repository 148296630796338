/**
 * 数据报表
 */
export default function (context) {
  return [
    {
      label: '日期范围',
      prop: 'type',
      type: 'select',
      include: ['query'],
      source: () => {
        const options = [
          {
            label: '日报',
            value: 'day',
          },
          {
            label: '周报',
            value: 'week',
          },
          {
            label: '月报',
            value: 'month',
          },
          {
            label: '季报',
            value: 'quarter',
          },
          {
            label: '年报',
            value: 'year',
          },
        ]
        return Promise.resolve(options)
      },
    },
    {
      label: '日期范围',
      prop: 'date',
      fixed: 'left',
      include: ['table'],
      width: '200px',
    },
    {
      label: '人才类型',
      prop: 'talentType',
      type: 'select',
      include: ['query'],
      source: () => {
        const talentType = context.enumOptions.talentType
        const t1 = talentType.find(n => n.label === '互联网行业')
        const options = [
          {
            label: 'AI行业',
            value: -1,
          },
          t1,
        ]
        return Promise.resolve(options)
      },
    },
    {
      label: '顾问',
      prop: 'followerId',
      type: 'select',
      include: ['query'],
      source: () => Promise.resolve(context.userList),
    },
    {
      label: '创建人才',
      prop: 'creatorCount',
      include: ['table'],
      tableType: 'link',
      click: row => {
        context.openLink(`/candidate`, {
          startDate: row.startDate,
          endDate: row.endDate,
          talentType: -1,
          ...(context.currentParams.followerId
            ? { followerId: context.currentParams.followerId }
            : {}),
        })
        // context.$router.push({
        //   path: '/candidate',
        //   query: {
        //     startDate: row.startDate,
        //     endDate: row.endDate,
        //     talentType: -1,
        //     ...(context.currentParams.followerId
        //       ? { followerId: context.currentParams.followerId }
        //       : {}),
        //   },
        // })
      },
    },
    {
      label: '推荐人才',
      prop: 'recommendCount',
      include: ['table'],
      tableType: 'link',
      click: row => {
        context.openLink(`/recommend`, {
          startDate: row.startDate,
          endDate: row.endDate,
          ...(context.currentParams.followerId
            ? { recommendId: context.currentParams.followerId }
            : {}),
        })
        // context.$router.push({
        //   path: '/recommend',
        //   query: {
        //     startDate: row.startDate,
        //     endDate: row.endDate,
        //     ...(context.currentParams.followerId
        //       ? { recommendId: context.currentParams.followerId }
        //       : {}),
        //   },
        // })
      },
    },
    {
      label: '面试安排',
      prop: 'reviewCount',
      include: ['table'],
      tableType: 'link',
      click: row => {
        context.openLink(`/recommend`, {
          startDate: row.startDate,
          endDate: row.endDate,
          isInterview: 1,
          ...(context.currentParams.followerId
            ? { recommendId: context.currentParams.followerId }
            : {}),
        })
        // context.$router.push({
        //   path: '/recommend',
        //   query: {
        //     startDate: row.startDate,
        //     endDate: row.endDate,
        //     isInterview: 1,
        //     ...(context.currentParams.followerId
        //       ? { recommendId: context.currentParams.followerId }
        //       : {}),
        //   },
        // })
      },
    },
    {
      label: 'offer成功',
      prop: 'offerCount',
      include: ['table'],
      tableType: 'link',
      click: row => {
        context.openLink(`/recommend`, {
          startDate: row.startDate,
          endDate: row.endDate,
          isOffer: 1,
          ...(context.currentParams.followerId
            ? { recommendId: context.currentParams.followerId }
            : {}),
        })
        // context.$router.push({
        //   path: '/recommend',
        //   query: {
        //     startDate: row.startDate,
        //     endDate: row.endDate,
        //     isOffer: 1,
        //     ...(context.currentParams.followerId
        //       ? { recommendId: context.currentParams.followerId }
        //       : {}),
        //   },
        // })
      },
    },
    {
      label: '开票数',
      prop: 'financeCount',
      include: ['table'],
      tableType: 'link',
      click: row => {
        context.openLink(`/finance`, {
          startDate: row.startDate,
          endDate: row.endDate,
          ...(context.currentParams.followerId
            ? { recommendId: context.currentParams.followerId }
            : {}),
        })
        // context.$router.push({
        //   path: '/finance',
        //   query: {
        //     startDate: row.startDate,
        //     endDate: row.endDate,
        //     ...(context.currentParams.followerId
        //       ? { recommendId: context.currentParams.followerId }
        //       : {}),
        //   },
        // })
      },
    },
    {
      label: '开票总金额',
      prop: 'financeCountAmount',
      include: ['table'],
    },
  ]
}
