<template>
  <div class="">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/project' }" replace>
        项目管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>项目详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="ui-title">
      <i class="el-icon-data-line ui-title-icon"></i>{{ form.name }}
    </div>

    <el-tabs v-model="activeName" tab-position="left" @tab-click="tabClick">
      <el-tab-pane
        :label="tab.label"
        :name="tab.name"
        v-for="tab in tabs"
        :key="tab.name"
        :style="{ maxHeight: maxHeight + 'px', overflow: 'auto' }"
        v-loading="loading"
      >
        <!-- <template v-if="activeName === 'info'">
          <el-form
            :model="form"
            ref="form"
            label-width="100px"
            size="mini"
            label-position="left"
            style="padding: 20px;margin:0 auto;"
            class="ui-info"
          >
            <template v-for="item in infoItem">
              <el-form-item :label="item.label" :key="item.prop">
                <template v-if="item.showType === 'tag'">
                  <el-tag
                    v-for="t in form[item.prop]"
                    :key="t"
                    size="small"
                    style="margin: 0 10px 0 0;"
                  >
                    {{ t }}
                  </el-tag>
                </template>

                <template v-else-if="item.showType === 'status'">
                  <el-tag
                    size="small"
                    style="margin: 0 10px 0 0;"
                    :type="form[item.prop].type"
                    v-if="form[item.prop]"
                  >
                    {{ form[item.prop].name }}
                  </el-tag>
                </template>
                <template v-else>
                  {{ form[item.prop] }}
                </template>
              </el-form-item>
            </template>

            <el-form-item>
              <el-button type="primary" @click="isShowDetail = true"
                >修改</el-button
              >
            </el-form-item>
          </el-form>
        </template>
        <template v-if="activeName === 'position'">
          <div style="padding: 0 0 10px;float:right;">
            <el-button type="primary" size="mini" @click="isShow = true">
              关联岗位
            </el-button>
          </div>
          <UiTable
            :columns="tableColumns"
            :data="tableData"
            :ctrls="tableCtrls"
            :loading="loading"
            :height="tableHeight"
          ></UiTable>
        </template>
        <template v-if="activeName === 'history'">
          <div style="padding: 0 0 10px;">
            <el-button type="primary" size="mini" @click="isShowHistory = true">
              创建
            </el-button>
          </div>
          <el-timeline>
            <el-timeline-item
              :timestamp="n.createdTime"
              placement="top"
              v-for="n in history"
              :key="n.id"
            >
              <el-card>
                <h4>{{ n.remark }}</h4>
                <p><img :src="n.avatar" class="avatar" />{{ n.name }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </template> -->
      </el-tab-pane>
      <router-view :form="form" :formItem="projectColumns"></router-view>
    </el-tabs>

    <el-dialog
      title="项目详情"
      :visible.sync="isShowDetail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="detailItem"
        :formDefault="form"
        @cancel="isShowDetail = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="关联岗位"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="formItem"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    projectId() {
      return parseInt(this.$route.params.id)
    },
    formItem() {
      return this.tableColumns.filter(
        n => n.include && n.include.includes('form')
      )
    },
    detailItem() {
      return this.infoItem.filter(n => n.include && n.include.includes('form'))
    }
  },
  data() {
    return {
      isShowDetail: false,
      maxHeight: window.innerHeight - 110,
      currentItem: {},
      isShowHistory: false,
      isShow: false,
      tableHeight: window.innerHeight - 190,
      tableData: {},
      history: [],
      historyItem: [
        {
          label: '日期',
          props: 'date',
          type: 'dateTime'
        },
        {
          label: '内容记录',
          prop: 'remark',
          type: 'textarea'
        }
      ],
      tableColumns: [
        {
          label: '关联岗位',
          prop: 'positionId',
          type: 'multiSelect',
          include: ['form'],
          source: () => {
            return this.$http.get('/list').then(res => {
              return res.data.map(n => {
                return {
                  label: n.positionName,
                  value: n.positionId
                }
              })
            })
          }
        },
        {
          label: '岗位名称',
          prop: 'positionName',
          tableType: 'link',
          fixed: 'left',
          type: 'input',
          include: ['table'],
          click: item => {
            this.$router.push({
              path: `/position/${item.id}`
            })
          }
        },
        {
          label: '紧急程度',
          prop: 'level',
          type: 'select',
          include: ['query', 'table'],
          options: [
            {
              label: '高',
              value: '高'
            },
            {
              label: '中',
              value: '中'
            },
            {
              label: '低',
              value: '低'
            }
          ]
        },
        {
          label: '岗位状态',
          prop: 'projectStatus',
          type: 'select',
          tableType: 'status',
          include: ['query', 'table'],
          options: [
            {
              label: '启动',
              value: 'primary'
            },
            {
              label: '进行中',
              value: 'danger'
            },
            {
              label: '已完成',
              value: 'success'
            }
          ]
        },
        {
          label: '客户联系人',
          prop: 'contact',
          type: 'select',
          include: ['query', 'table'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '负责顾问',
          prop: 'follower',
          type: 'multiSelect',
          include: ['query', 'table'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '所属部门',
          prop: 'department',
          type: 'input',
          include: ['table']
        },
        {
          label: '岗位介绍',
          prop: 'desc',
          type: 'textarea',
          include: ['table']
        },
        {
          label: '工作地点',
          prop: 'address',
          type: 'input',
          include: ['table']
        },
        {
          label: '薪水范围',
          prop: 'salary',
          type: 'input',
          include: ['table']
        },

        {
          label: '操作红线信息',
          prop: 'summary',
          type: 'textarea',
          include: ['table']
        },
        {
          label: '招聘原因',
          prop: 'reason',
          type: 'textarea',
          include: ['table']
        },
        {
          label: '其他备注',
          prop: 'remark',
          type: 'textarea',
          include: ['table']
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          include: ['table']
        }
      ],
      tableCtrls: [
        // {
        //   label: '修改',
        //   click: item => {
        //     this.currentItem = { ...item }
        //     this.isShow = true
        //   }
        // },
        // {
        //   label: '删除',
        //   type: 'danger',
        //   click: item => {
        //     this.$confirm('确定删除 ?', {
        //       type: 'warning'
        //     }).then(() => {
        //       let idx = this.tableData.data.findIndex(n => n.id === item.id)
        //       this.tableData.data.splice(idx, 1)
        //       this.$message.success('删除成功 !')
        //     })
        //   }
        // }
      ],
      loading: false,
      form: {},
      activeName: 'info',
      infoItem: [
        {
          label: '项目名称',
          prop: 'projectName',
          sortable: true,
          tableType: 'link',
          click: item => {
            this.$router.push({ path: `/project/${item.id}` })
          },
          type: 'input',
          include: ['query', 'table', 'form']
        },
        {
          label: '关联客户',
          prop: 'client',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/list').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '客户联系人',
          prop: 'contact',
          type: 'select',
          include: ['query', 'table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '紧急程度',
          prop: 'level',
          type: 'select',
          include: ['query', 'table', 'form'],
          options: [
            {
              label: '高',
              value: '高'
            },
            {
              label: '中',
              value: '中'
            },
            {
              label: '低',
              value: '低'
            }
          ]
        },
        {
          label: '项目负责人',
          prop: 'owner',
          type: 'select',
          include: ['table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '项目跟进人',
          prop: 'follower',
          type: 'select',
          include: ['table', 'form'],
          source: () => {
            return this.$http.get('/user').then(res => {
              return res.data.map(n => {
                return {
                  label: n.name,
                  value: n.id
                }
              })
            })
          }
        },
        {
          label: '项目背景',
          prop: 'background',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '项目详情',
          prop: 'detail',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '项目总结',
          prop: 'summary',
          type: 'textarea',
          include: ['form']
        },
        {
          label: '项目状态',
          prop: 'projectStatus',
          type: 'select',
          showType: 'status',
          tableType: 'status',
          include: ['query', 'table', 'form'],
          options: [
            {
              label: '启动',
              value: 'primary'
            },
            {
              label: '进行中',
              value: 'danger'
            },
            {
              label: '已完成',
              value: 'success'
            }
          ]
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          sortable: true,
          type: 'input',
          include: ['table']
        }
      ],
      tabs: [
        {
          label: '项目详情',
          name: 'info'
        },
        {
          label: '项目岗位',
          name: 'position'
        }
      ]
    }
  },
  methods: {
    tabClick(item) {
      let { name } = this.tabs[parseInt(item.index)]
      this.$router.push({
        path: `/project/${this.projectId}/${name}`
      })
    },
    onSubmit() {
      this.$message.success('提交成功 !')
      this.isShowDetail = false
      this.isShow = false
    },
    query() {
      this.loading = true
      this.$http
        .get('/project/desc', {
          params: {
            id: this.projectId
          }
        })
        .then(res => {
          this.loading = false
          this.form = res.data
          this.setTitle(this.form.name)
        })
    },
  },
  mounted() {
    let path = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
    this.activeName = path
    this.query()
  }
}
</script>

<style lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}
.el-timeline {
  padding: 0 0 0 10px;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }
}

.el-page-header {
  margin-bottom: 10px;
}
.ui-info {
  .el-form-item {
    margin: 0 0 8px !important;
  }
}
</style>
