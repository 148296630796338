<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  methods: {
    initSocket() {
      setInterval(() => {
        this.$socket.emit('server', 'hello')
      }, 5000)
    }
  },
  mounted() {
    this.initSocket()
  }
}
</script>