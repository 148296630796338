<template>
  <div class="">
    <UiQuery
      :items="communityColumns"
      :defaultParams="defaultParams"
      :ctrls="ctrls"
      :rightCtrls="rightCtrls"
    ></UiQuery>
    <UiTable
      :columns="communityColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    ></UiTable>
    <el-dialog
      title="顾问信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="communityColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        :disabled="isShowDetail"
        labelWidth="120px"
        v-if="isShow"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
import qs from 'qs'
export default {
  mixins: [mixin],
  data() {
    return {
      baseUrl: location.host.match('rockmind.cn')
        ? 'https://community.rockmind.cn'
        : 'http://127.0.0.1:1337',
      ctrls: [
        {
          label: '查询',
          click: params => {
            this.getData(params)
          },
        },
      ],
      queryUrl: '/wechat/agent',
      isShowDetail: false,
      rightCtrls: [
        {
          label: '创建板块',
          float: 'right',
          plain: false,
          click: () => {
            this.isShow = true
          },
        },
      ],
      tableCtrls: [
        {
          label: '详情',
          type: 'info',
          click: item => {
            let currentItem = { ...item }
            if (item.post && item.post.length) {
              currentItem.postId = item.post.map(n => n.id)
            }
            this.currentItem = currentItem
            this.isShowDetail = true
            this.isShow = true
          },
        },
        {
          label: '修改',
          click: item => {
            let currentItem = { ...item }
            if (item.posts && item.posts.length) {
              currentItem.postId = item.posts.map(n => n.id)
            }
            if (item.owner_id) {
              currentItem.owner_id = item.owner_id.id
            }
            if (item.admin_ids && item.admin_ids.length) {
              currentItem.admin_ids = item.admin_ids.map(n => n.id)
            }
            this.currentItem = currentItem
            this.isShow = true
            this.isShowDetail = false
          },
        },
        {
          label: '删除',
          type: 'danger',
          role: [1],
          click: item => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              const url = this.baseUrl + '/api/cm-sectors/' + item.id
              this.$http
                .put(
                  url,
                  {
                    data: {
                      is_deleted: true,
                    },
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${this.masterKey}`,
                    },
                  }
                )
                .then(() => {
                  this.getData()
                })
            })
          },
        },
      ],
    }
  },
  methods: {
    async getData() {
      this.loading = true
      const url = this.baseUrl + '/api/cm-sectors'
      const ret = await this.$http.get(url, {
        params: {
          populate: '*',
          filters: {
            is_deleted: false,
          },
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
        headers: {
          Authorization: `Bearer ${this.masterKey}`,
        },
      })
      this.tableData = {
        data: ret.data,
        total: ret.meta.pagination.total,
      }
      this.loading = false
    },
    async onSubmit(form) {
      console.log('🚀 ~ file: Community.vue:139 ~ onSubmit ~ form:', form)
      const postData = { ...form }
      // 背景图
      if (form.image && form.image.id) {
        postData.image = form.image.id
      } else {
        delete postData.image
      }
      // 管理员
      if (form.admin_ids) {
        postData.admin_ids = form.admin_ids.split(',').map(n => Number(n))
      } else {
        delete postData.admin_ids
      }
      try {
        if (this.currentItem && this.currentItem.id) {
          const url = this.baseUrl + '/api/cm-sectors/' + this.currentItem.id
          await this.$http.put(
            url,
            {
              data: postData,
            },
            {
              headers: {
                Authorization: `Bearer ${this.masterKey}`,
              },
            }
          )
        } else {
          const url = this.baseUrl + '/api/cm-sectors/'
          await this.$http.post(
            url,
            {
              data: postData,
            },
            {
              headers: {
                Authorization: `Bearer ${this.masterKey}`,
              },
            }
          )
        }

        this.$message.success('提交成功 !')
        this.isShow = false
        this.getData()
      } catch (e) {
        this.$message.error(e.message)
      }
    },
  },
  mounted() {
    // 读取默认参数
    const key = `path${this.$route.path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    }
    this.setTitle()
    this.getData()
  },
}
</script>
