<template>
  <div class="">
    <template v-if="timeRange">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/data' }">
          数据报表
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/finance' }">
          财务管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ timeRange.startDate }} 至 {{ timeRange.endDate }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <template v-else>
      <UiQuery :items="financeColumns" :ctrls="ctrls"></UiQuery>
    </template>

    <UiTable
      :columns="financeColumns"
      :data="tableData"
      :ctrls="tableCtrls"
      :loading="loading"
      @page-change="onPageChange"
    ></UiTable>
    <el-dialog
      title="岗位信息"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="financeItems"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    financeItems() {
      let include = [
        'status',
        'cost',
        'type',
        'name',
        'invoiceCode',
        'deliveryStatus',
        'payStatus',
        'paySum',
        'remark',
        'payDate',
        'entryDate',
      ]
      return this.financeColumns.filter((n) => include.includes(n.prop))
    },
    timeRange() {
      if (this.$route.query.startDate && this.$route.query.endDate) {
        return {
          startDate: this.$route.query.startDate.substr(0, 10),
          endDate: this.$route.query.endDate.substr(0, 10),
        }
      }
      return false
    },
  },
  data() {
    return {
      queryUrl: '/finance/list',
      tableCtrls: [
        {
          label: '修改',
          click: (item) => {
            this.currentItem = {
              ...item,
              entryDate: this.$dayjs(item.entryDate).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              payDate:
                item.payDate !== 'Invalid date'
                  ? this.$dayjs(item.payDate).format('YYYY-MM-DD HH:mm:ss')
                  : '',
            }
            this.isShow = true
          },
        },
        // {
        //   label: '删除',
        //   type: 'danger',
        //   click: item => {
        //     this.$confirm('确定删除 ?', {
        //       type: 'warning'
        //     }).then(() => {
        //       this.$http
        //         .get('/post/del', {
        //           params: {
        //             id: item.id
        //           }
        //         })
        //         .then(ret => {
        //           if (ret.code === 200) {
        //             this.$message.success('删除成功 !')
        //             this.query()
        //           }
        //         })
        //     })
        //   }
        // }
      ],
    }
  },
  methods: {
    onSubmit(form) {
      let postData = { ...form }
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
      }
      this.$http.post('/finance/save', postData).then((ret) => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShow = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
  },
  mounted() {
    // 读取默认参数
    const key = `path${this.$route.path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    }
    this.query({ ...this.defaultParams, ...this.$route.query })
    this.setTitle()
  }
}
</script>
