<template>
  <el-container class="ui-home">
    <el-header height="50px">
      <el-row>
        <el-col :span="4">
          <div class="logo">
            <img :src="`${publicPath}logo.png`" />
          </div>
        </el-col>
        <el-col :span="16">
          <img class="slogan" :src="`${publicPath}slogan.png`" />
        </el-col>
        <el-col :span="4">
          <el-menu
            mode="horizontal"
            class="menu"
            background-color="#2e313a"
            text-color="#fff"
            active-text-color="#fff"
            @select="onMenuSelect"
          >
            <el-submenu index="sub">
              <template slot="title">{{ userInfo.name }}</template>
              <el-menu-item index="setting">账号设置</el-menu-item>
              <el-menu-item index="logout">退出</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </el-header>
    <el-container>
      <el-aside
        :width="isCollapse ? '66px' : '160px'"
        v-if="!isLogin"
        class="ui-aside"
      >
        <el-menu
          :default-active="active"
          :collapse="isCollapse"
          background-color="#2e313a"
          text-color="#fff"
          active-text-color="#ecb745"
          @select="onSelect"
        >
          <el-menu-item index="collapse">
            <i
              :class="
                isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'
              "
            ></i>
            <span slot="title">{{ isCollapse ? '展开' : '' }}</span>
          </el-menu-item>
          <template v-for="menu in showMenus">
            <template v-if="menu.subMenu">
              <el-submenu :index="menu.path" :key="menu.path">
                <template slot="title">
                  <i :class="menu.icon" v-if="menu.icon"></i>
                  <span>{{ menu.title }}</span>
                </template>
                <el-menu-item-group>
                  <!-- <template slot="title">分组一</template> -->
                  <template v-for="child in menu.subMenu">
                    <el-menu-item
                      :key="child.path"
                      :index="child.path"
                      v-if="child.isShow === undefined || child.isShow === true"
                    >
                      {{ child.title }}
                    </el-menu-item>
                  </template>
                </el-menu-item-group>
              </el-submenu>
            </template>
            <el-menu-item :index="menu.path" :key="menu.path" v-else>
              <i :class="menu.icon" v-if="menu.icon"></i>
              <span slot="title">{{ menu.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <el-main v-loading="loading">
        <!-- <keep-alive exclude="Candidate"> -->
        <router-view v-if="isInited"></router-view>
        <!-- </keep-alive> -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    roles() {
      return this.$store.state.roles
    },
    authority() {
      let item = this.roles.find(n => this.userInfo.roleId === n.value)
      if (item && item.authority) {
        return item.authority.map(n => {
          let item = this.$store.getters.getEnumByValue('authority', n)
          return item ? item.label : null
        })
      }
      return []
    },
    reminders() {
      return this.$store.state.reminders
    },
    isLogin() {
      return this.$route.path.match('login') ? true : false
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    showMenus() {
      let { roleId } = this.userInfo
      return this.menus
        .filter(n => {
          if (n.role && n.role.length) {
            return n.role.includes(roleId)
          }
          return true
        })
        .map(n => {
          if (n.subMenu) {
            n.subMenu.forEach(m => {
              if (m.role && m.role.length) {
                m.isShow =
                  m.role.includes(roleId) ||
                  m.role.some(a => this.authority.includes(a))
              }
            })
          }
          return n
        })
    }
  },
  data() {
    return {
      active: this.$route.path,
      isInited: false,
      running: false,
      publicPath: process.env.BASE_URL,
      loading: false,
      isCollapse: true,
      menus: [
        {
          title: '首页',
          icon: 'el-icon-s-home',
          path: '/'
        },
        {
          title: '工作日志',
          icon: 'el-icon-date',
          path: '/work',
          role: [1, 6, 7, 8, 9, 10, 11]
        },
        {
          title: '客户管理',
          icon: 'el-icon-office-building',
          path: '/client',
          role: [1, 6, 7, 8, 9, 12],
          subMenu: [
            {
              title: '客户管理',
              path: '/client'
            },
            {
              title: '联系人管理',
              path: '/contact'
            },
            {
              title: '合同信息管理',
              path: '/contract'
            }
          ]
        },
        {
          title: '岗位管理',
          icon: 'el-icon-monitor',
          path: '/position',
          role: [1, 6, 7, 8, 9, 10, 11]
        },
        {
          title: '人才管理',
          icon: 'el-icon-user',
          path: '/candidate',
          role: [1, 6, 7, 8, 9, 10, 11],
          subMenu: [
            {
              title: '人才管理',
              path: '/candidate'
            },
            {
              title: '互联网行业',
              path: '/candidate/type'
            }
          ]
        },
        {
          title: '项目管理',
          icon: 'el-icon-data-line',
          path: '/project',
          role: [1, 6, 7, 8, 9, 10]
        },
        {
          title: '推荐管理',
          icon: 'el-icon-thumb',
          path: '/recommend'
          // role: [1, 7]
        },
        {
          title: '财务管理',
          icon: 'el-icon-money',
          path: '/finance',
          role: [1, 12]
        },
        {
          title: '小程序管理',
          icon: 'el-icon-mobile-phone',
          path: '/wechat',
          subMenu: [
            {
              title: '首页管理',
              path: '/wechat/index',
              role: ['小程序管理']
            },
            {
              title: '顾问管理',
              path: '/wechat/agent'
            },
            {
              title: '社区管理',
              path: '/wechat/community'
            }
          ]
        },
        {
          title: '数据统计',
          icon: 'el-icon-data-analysis',
          path: '/data',
          role: [1],
          subMenu: [
            {
              title: '数据报表',
              path: '/data'
            },
            {
              title: '人才统计',
              path: '/data/candidate'
            }
          ]
        },
        {
          title: '系统设置',
          icon: 'el-icon-setting',
          path: '/setting',
          role: [1, 17],
          subMenu: [
            {
              title: '角色管理',
              path: '/setting/role'
            },
            {
              title: '账号管理',
              path: '/setting/account'
            },
            {
              title: '属性管理',
              path: '/setting/attribute'
            },
            {
              title: '邮件模板',
              path: '/setting/email'
            },
            {
              title: '模板管理',
              path: '/setting/template'
            },
            {
              title: '操作日志',
              path: '/log'
            }
          ]
        }
      ]
    }
  },
  methods: {
    schedule() {
      if (!this.running) {
        this.running = true
        const check = () => {
          this.reminders.forEach(n => {
            let { remindDate, content } = n
            let date = this.$dayjs(remindDate)
            let now = this.$dayjs()
            // 提前15分钟提醒
            if (date.diff(now, 'm') === 15 || date.diff(now, 'm') === 0) {
              this.$notify({
                title: `待办提醒: ${remindDate}`,
                type: 'warning',
                message: `${content}`,
                duration: 0,
                onClick: () => {
                  this.$router.push('/')
                }
              })
            }
          })
        }
        let run = () => {
          check()
          setTimeout(run, 1000 * 60)
        }
        run()
      }
    },
    // 今日待办
    checkReminder() {
      this.reminders.forEach(n => {
        let { remindDate, content } = n
        let date = this.$dayjs(remindDate)
        let now = this.$dayjs()
        if (date.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
          setTimeout(() => {
            this.$notify({
              title: `今日待办: ${remindDate}`,
              type: 'warning',
              message: `${content}`,
              duration: 0,
              onClick: () => {
                this.$router.push('/')
              }
            })
          }, 300)
        }
      })
    },
    onMenuSelect(index) {
      if (index === 'logout') {
        this.$http.get('/logout').then(() => {
          window.localStorage.clear()
          window.location.reload()
        })
      }

      if (index === 'setting') {
        this.$router.push({ path: '/setting' })
      }
    },
    onSelect(index) {
      if (index === 'collapse') {
        this.active = ''
        this.isCollapse = !this.isCollapse
        setTimeout(() => {
          this.active = this.$route.path
        }, 340)
        return
      }
      this.$router.push({ path: index })
    }
  },
  created() {
    this.loading = true
    Promise.all([
      this.$store.dispatch('getUsers', { reload: true }),
      this.$store.dispatch('getEnumOptions', { reload: true }),
      this.$store.dispatch('getUserInfo'),
      this.$store.dispatch('getRoles'),
      this.$store.dispatch('getReminders', { reload: true })
    ])
      .then(() => {
        this.loading = false
        this.isInited = true
        this.schedule()
        this.checkReminder()
      })
      .catch(() => {
        this.loading = false
        this.isInited = true
      })
  }
}
</script>

<style lang="less">
html,
body,
#app,
.ui-home {
  height: 100%;
}
.el-menu-item {
  font-size: 12px!important;
}
.el-menu--popup-bottom-start {
  margin-top: 0;
}
.el-header {
  background: #2e313a;
  color: #fff;
  overflow: hidden;

  .menu {
    float: right;
  }

  .el-menu--horizontal > .el-menu-item {
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    font-size: 12px;
  }

  .el-menu--horizontal > .el-submenu .el-submenu__title {
    line-height: 50px;
    height: 50px;
    border: none !important;
    font-size: 12px;
  }

  .logo {
    float: left;
    line-height: 47px;
    img {
      height: 35px;
      vertical-align: middle;
    }
  }
  .slogan {
    display: block;
    height: 20px;
    line-height: 47px;
    margin: 14px auto 0;
  }
}
.el-main {
  padding: 10px !important;
  position: relative;
}
.ui-home {
  .el-icon-d-arrow-right,
  .el-icon-d-arrow-left {
    color: #909399 !important;
  }
}
.ui-rightBtn {
  position: absolute;
  right: 0;
  top: -30px;
  z-index: 10;
}
.ui-table-wrap {
  position: relative;
}

.ui-aside {
  background: #2e313a;

  .el-menu {
    border-right: none;
  }
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #87c1eb;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
  border-radius: 10px;
}
</style>
