/**
 * 岗位与人才关联字段
 */
export default function (context) {
  return [
    {
      label: '所属客户',
      prop: 'companyId',
      rule: 'select',
      type: 'select',
      include: ['query', 'form'],
      source: () => {
        return context.$http
          .get('/client/list', {
            params: {
              pageSize: 10000,
              pageNo: 1,
            },
          })
          .then(res => {
            context.companyList = res.data.list
            return res.data.list.map(n => {
              return {
                label: n.name,
                value: n.id,
              }
            })
          })
      },
      relate: [
        {
          prop: 'postId',
          source: item => {
            // const projectId = context.currentItem.id
            return context.$http
              .get('/post/list', {
                params: {
                  companyId: item.companyId,
                  pageSize: 10000
                }
              })
              .then(res => {
                const ret = res.data.list
                  // .filter(n => {
                  //   return !n.projectId || n.projectId === projectId
                  // })
                  .map(n => {
                    return {
                      label: n.name,
                      value: n.id
                    }
                  })
                return ret
              })
          }
        },
        {
          prop: 'followerId',
          source: item => {
            return context.waitUntil(() => {
              if (context.companyList && context.companyList.length) {
                const company = context.companyList.find(
                  n => n.id === item.companyId
                )
                let ret = []
                if (company) {
                  const { follower, adviserList } = company
                  ret = [
                    {
                      label: follower.name,
                      value: follower.id
                    }
                  ]
                  if (adviserList && adviserList.length) {
                    adviserList.forEach(n => {
                      // 排重
                      if (!ret.find(m => m.value === n.id)) {
                        ret.push({
                          label: n.name,
                          value: n.id
                        })
                      }
                    })
                  }
                }
                return ret
              }
              return false
            })
          }
        }
      ]
    },
    {
      label: '关联岗位',
      prop: 'postId',
      rule: 'select',
      type: 'select',
      include: ['form'],
      noDataText: '当前客户无可关联岗位'
    },
    {
      label: '负责顾问',
      prop: 'followerId',
      rule: 'select',
      type: 'select',
      include: ['form'],
      formWidth: 24,
      source: () => {
        return Promise.resolve(context.validUser)
      }
    },
    {
      label: '备注',
      prop: 'remark',
      type: 'textarea',
      formWidth: 24,
      include: ['form']
    }
  ]
}
