<template>
  <div class="">
    <UiTable
      :columns="positionColumns"
      :data="tableData"
      :hasCtrl="true"
      :loading="loading"
      @page-change="onPageChange"
    >
      <template #ctrlHeader>
        <el-button type="primary" @click="showNew" size="mini">
          关联岗位
        </el-button>
      </template>
    </UiTable>

    <el-dialog
      title="岗位信息"
      :visible.sync="isShowRelate"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="candidatePositionColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowRelate = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    talentId() {
      return this.$route.params.id
    },
  },
  props: {
    form: Object,
  },
  data() {
    return {
      queryUrl: '/post/list',
      isShowRelate: false,
      tableCtrls: [{}],
      tableHeight: window.innerHeight - 190,
    }
  },
  methods: {
    showNew() {
      this.isShowRelate = true
    },
    onSubmit(form) {
      let postData = {
        ...form,
        talentId: this.form.id,
      }
      return this.$http.post('/post/talent/save', postData).then((ret) => {
        this.isShowRelate = false
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShowRelate = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
  },
  created() {
    this.defaultParams = {
      talentId: this.talentId,
    }
  },
  mounted() {
    this.query({
      talentId: this.talentId,
    })
  },
}
</script>

<style></style>
