/**
 * 账号字段
 */
export default function(context) {
  return [
    {
      label: '顾问信息',
      type: 'divide',
      include: ['form'],
    },
    {
      label: 'ID',
      prop: 'id',
      type: 'input',
      include: ['table'],
    },
    {
      label: '头像',
      prop: 'avatar',
      type: 'avatar',
      include: ['form'],
    },
    {
      label: '顾问名称',
      prop: 'name',
      type: 'input',
      rule: 'text',
      include: ['form', 'table', 'query'],
    },
    {
      label: '顾问职称',
      prop: 'title',
      type: 'input',
      rule: 'text',
      include: ['form', 'table', 'query'],
    },
    
    {
      label: '关联账号',
      prop: 'userId',
      type: 'select',
      rule: 'select',
      include: ['form', 'query'],
      source: () => {
        return context.waitUntil(() => {
          if (context.userList && context.userList.length) {
            return context.userList
          }
          return false
        })
      },
      relate: [
        {
          prop: 'postId',
          source: form => {
            return context.$http
              .get('/post/list', {
                params: {
                  followerId: form.userId,
                  adviserId: form.userId,
                  queryType: 'or',
                  pageSize: 10000,
                },
              })
              .then(res => {
                return res.data.list
                  .map(n => {
                    let type = n.followerId === form.userId ? '负责' : '参与'
                    return {
                      value: n.id,
                      key: n.id,
                      label: `${n.company.name} | ${n.name} (${type})`,
                      type,
                    }
                  })
                  .sort(a => {
                    return a.type === '负责' ? -1 : 0
                  })
              })
          },
        },
      ],
    },
    {
      label: '关联账号',
      prop: 'user.name',
      include: ['table'],
    },
    {
      label: '标签',
      prop: 'label',
      tableType: 'tagGroup',
      type: 'tagGroup',
      include: ['form', 'table'],
    },
    {
      label: '微信账号',
      prop: 'wechat',
      type: 'input',
      include: ['form', 'table'],
    },
    {
      label: '邮箱',
      prop: 'email',
      type: 'input',
      include: ['form', 'table'],
    },
    {
      label: '介绍',
      prop: 'desc',
      type: 'textarea',
      include: ['form'],
    },
    {
      label: '负责岗位',
      prop: 'postId',
      type: 'transfer',
      include: ['form'],
      formWidth: 24
    },
    {
      label: '是否首页显示',
      prop: 'status',
      type: 'switch',
      include: ['form', 'table'],
      options: [
        {
          label: '显示',
          value: '1',
        },
        {
          label: '隐藏',
          value: '2',
        },
      ],
      format: item => {
        let value = item.status
        let maps = [
          {
            label: '显示',
            value: '1',
          },
          {
            label: '隐藏',
            value: '2',
          },
        ]
        return maps.find(n => n.value === value).label
      },
    },
  ]
}
