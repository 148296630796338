/**
 * 岗位字段
 */
export default function (context) {
  return [
    {
      label: '基础信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '岗位名称',
      prop: 'name',
      tableType: 'link',
      fixed: 'left',
      type: 'input',
      rule: 'name',
      include: ['form', 'table', 'query'],
      click: item => {
        context.openLink(`/position/${item.id}/info`)
        // context.$router.push({
        //   path: `/position/${item.id}/info`
        // })
      }
    },
    {
      label: '所属客户',
      prop: 'company',
      include: ['table', 'show'],
      tableType: 'link',
      click: item => {
        context.openLink(`/client/${item.companyId}/info`)
        // context.$router.push({
        //   path: `/client/${item.companyId}/info`
        // })
      }
    },
    {
      label: '所属客户',
      prop: 'companyId',
      type: 'select',
      rule: 'select',
      include: ['form', 'query'],
      source: () => {
        return context.$http
          .get('/client/list', {
            params: {
              pageSize: 10000,
              pageNo: 1
            }
          })
          .then(ret => {
            context.companyList = ret.data.list
            return ret.data.list.map(n => {
              return {
                label: n.name,
                value: n.id
              }
            })
          })
      },
      relate: [
        {
          prop: 'contactId',
          source: item => {
            return context.$http
              .get('/client/user/list', {
                params: {
                  companyId: item.companyId
                }
              })
              .then(ret => {
                if (ret.code === 200) {
                  return ret.data.list.map(n => {
                    return {
                      label: n.name,
                      value: n.id
                    }
                  })
                }
              })
          }
        }
        // {
        //   prop: 'followerId',
        //   source: item => {
        //     return context.waitUntil(() => {
        //       if (context.companyList && context.companyList.length) {
        //         const company = context.companyList.find(
        //           n => n.id === item.companyId
        //         )
        //         let ret = []
        //         if (company) {
        //           const { follower, adviserList } = company
        //           ret = [
        //             {
        //               label: follower.name,
        //               value: follower.id,
        //             },
        //           ]
        //           if (adviserList && adviserList.length) {
        //             adviserList.forEach(n => {
        //               // 排重
        //               if (!ret.find(m => m.value === n.id)) {
        //                 ret.push({
        //                   label: n.name,
        //                   value: n.id,
        //                 })
        //               }
        //             })
        //           }
        //         }
        //         return ret
        //       }
        //       return false
        //     })
        //   },
        // },
        // {
        //   prop: 'adviserId',
        //   source: item => {
        //     return context.waitUntil(() => {
        //       if (context.companyList && context.companyList.length) {
        //         const company = context.companyList.find(
        //           n => n.id === item.companyId
        //         )
        //         let ret = []
        //         if (company) {
        //           const { follower, adviserList } = company
        //           ret = [
        //             {
        //               label: follower.name,
        //               value: follower.id,
        //             },
        //           ]
        //           if (adviserList && adviserList.length) {
        //             adviserList.forEach(n => {
        //               // 排重
        //               if (!ret.find(m => m.value === n.id)) {
        //                 ret.push({
        //                   label: n.name,
        //                   value: n.id,
        //                 })
        //               }
        //             })
        //           }
        //         }
        //         return ret
        //       }
        //       return false
        //     })
        //   },
        // },
      ]
    },

    {
      label: '所属部门',
      prop: 'department',
      type: 'input',
      include: ['form', 'show']
    },

    {
      label: '岗位状态',
      prop: 'status',
      optionKey: 'postStatus',
      type: 'select',
      tableType: 'status',
      showType: 'status',
      include: ['query', 'table', 'form', 'show'],
      rule: 'select',
      source: () => {
        return Promise.resolve(context.enumOptions.postStatus)
      },
      format: item => {
        const key = 'status'
        const ret = context.enumOptions.postStatus.find(
          n => n.value === item[key]
        )
        return ret || item[key]
      }
    },

    {
      label: '紧急程度',
      prop: 'level',
      optionKey: 'level',
      type: 'select',
      include: ['query', 'form', 'show'],
      rule: 'select',
      sortable: 'custom'
    },
    {
      label: '跟进信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '负责人',
      prop: 'followerId',
      type: 'select',
      rule: 'select',
      include: ['table', 'form', 'show', 'query'],
      source: () => Promise.resolve(context.userList)
    },
    {
      label: '参与人',
      prop: 'adviserId',
      type: 'multiSelect',
      include: ['table', 'form', 'show', 'query'],
      source: () => Promise.resolve(context.userList)
    },
    {
      label: '客户联系人',
      prop: 'contactId',
      type: 'select',
      rule: 'select',
      include: ['form']
    },
    {
      label: '客户联系人',
      prop: 'contact',
      type: 'select',
      rule: 'select',
      include: ['table', 'show']
    },
    {
      label: '所属项目',
      prop: 'project',
      type: 'select',
      include: ['table', 'show']
    },
    // {
    //   label: '所属项目',
    //   prop: 'projectId',
    //   type: 'select',
    //   include: ['form']
    // },
    {
      label: '其他信息',
      type: 'divide',
      include: ['form', 'show'],
    },
    {
      label: '岗位类别',
      prop: 'type',
      optionKey: 'postLabel',
      type: 'select',
      rule: 'select',
      include: ['table', 'form', 'show', 'query'],
      source: () => {
        return Promise.resolve(context.enumOptions.postLabel)
      }
    },
    {
      label: '限制范围',
      prop: 'onlyShowMe',
      type: 'select',
      include: ['query'],
      options: [
        {
          label: '显示参与的',
          value: true
        },
        {
          label: '显示全部',
          value: false
        }
      ]
    },
    {
      label: '年薪范围',
      prop: 'salary',
      type: 'select',
      rule: 'select',
      optionKey: 'salaryRange',
      include: ['form', 'table', 'show']
    },
    {
      label: '岗位标签',
      prop: 'label',
      tableType: 'tagGroup',
      showType: 'tag',
      optionKey: 'tag',
      type: 'tagGroup',
      include: ['table', 'form', 'show']
    },
    {
      label: '工作地点',
      prop: 'address',
      type: 'address',
      rule: 'text',
      width: '220px',
      tableType: 'line',
      include: ['form', 'table', 'show']
    },
    {
      label: '岗位介绍',
      prop: 'intro',
      type: 'textarea',
      rule: 'text',
      include: ['form', 'show']
    },
    {
      label: '操作红线信息',
      prop: 'info',
      type: 'textarea',
      include: ['form', 'show']
    },
    {
      label: '招聘原因',
      prop: 'reason',
      type: 'textarea',
      include: ['form', 'show']
    },
    {
      label: '其它备注',
      prop: 'remark',
      type: 'textarea',
      include: ['form', 'show']
    },
    {
      label: '岗位职责和要求字段',
      prop: 'duty',
      type: 'textarea',
      rows: 20,
      formWidth: 24,
      include: ['form', 'show']
    },
    {
      label: '已关联人才',
      prop: 'middle',
      include: ['table'],
      format: item => {
        return item.middle.length
      }
    },
    {
      label: '创建时间',
      prop: 'createdAt',
      type: 'date',
      include: ['table', 'show']
    }
  ]
}
