<template>
  <div class="ui-table">
    <el-table
      :data="dataList"
      :height="tableHeight"
      ref="table"
      size="mini"
      header-row-class-name="ui-table-th"
      header-cell-class-name="ui-table-th"
      border
      @selection-change="onSelection"
      @sort-change="onSortChange"
      v-loading="loading"
      :empty-text="emptyText"
      :tree-props="treeProps"
      row-key="id"
    >
      <el-table-column type="selection" v-if="showSelection"></el-table-column>
      <!-- <el-table-column type="index" align="center"></el-table-column> -->
      <template v-for="column in tableColumns">
        <el-table-column
          :label="column.label"
          :key="column.label"
          :prop="column.prop"
          :sortable="column.sortable"
          :min-width="column.width || 150"
          :fixed="column.fixed || false"
        >
          <template slot="header">
            <template v-if="column.tip">
              {{ column.label }}
              <el-tooltip effect="dark" :content="column.tip" placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </template>
            <template v-else-if="column.btn">
              <el-button
                :type="column.btn.type || 'primary'"
                size="small"
                @click="column.btn.click"
              ></el-button>
            </template>
            <template v-else>{{ column.label }}</template>
          </template>

          <template slot-scope="{ row }">
            <!-- 状态 -->
            <template v-if="column.type === 'status'">
              <el-tag
                v-if="row[column.prop] !== undefined"
                :type="
                  column.options[row[column.prop]]
                    ? column.options[row[column.prop]].type
                    : 'primary'
                "
                size="mini"
              >
                {{
                  column.options[row[column.prop]]
                    ? column.options[row[column.prop]].label
                    : row[column.prop]
                }}
              </el-tag>
            </template>

            <!-- 日期范围 -->
            <template v-else-if="column.type === 'datePicker'">
              <p>
                <el-tag type="primary" size="mini">始</el-tag>
                {{ row[column.props[0]].substr(0, 10) }}
              </p>
              <p>
                <el-tag type="primary" size="mini">束</el-tag>
                {{ row[column.props[1]].substr(0, 10) }}
              </p>
            </template>

            <!-- 长文 -->
            <template v-else-if="column.type === 'textarea'">
              <el-tooltip
                effect="light"
                placement="top"
                :disabled="format(column, row).length < 10"
              >
                <div slot="content" style="max-width: 330px; line-height: 1.5">
                  {{ format(column, row) }}
                </div>
                <span>
                  {{
                    format(column, row).substr(0, 10) +
                    (format(column, row).length >= 10 ? '...' : '')
                  }}
                </span>
              </el-tooltip>
            </template>

            <!-- 标签类 -->
            <template
              v-else-if="
                column.tableType === 'tag' || column.tableType === 'tagGroup'
              "
            >
              <el-tag
                type="primary"
                size="mini"
                v-for="t in format(column, row)"
                :key="t"
                >{{ t }}</el-tag
              >
            </template>

            <!-- 状态 -->
            <template v-else-if="column.tableType === 'status'">
              <el-tag :type="format(column, row).sign" size="mini">{{
                format(column, row).label
              }}</el-tag>
            </template>

            <!-- 链接 -->
            <template v-else-if="column.tableType === 'link'">
              <el-link
                type="primary"
                @click="onLinkClick(column, row)"
                :underline="false"
                >{{ format(column, row) }}</el-link
              >
            </template>

            <!-- 附件下载 -->
            <template v-else-if="column.tableType === 'file'">
              <el-link
                type="primary"
                :href="'/file/download?key=' + format(column, row)"
                :underline="false"
                v-if="format(column, row)"
                >点击下载</el-link
              >
            </template>

            <template v-else-if="column.tableType === 'line'">
              <div
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ format(column, row) }}
              </div>
            </template>

            <template v-else-if="column.tableType === 'prism'">
              <pre><code class="language-json">{{format(column, row)}}</code></pre>
            </template>

            <template v-else>{{ format(column, row) }}</template>
          </template>
        </el-table-column>
      </template>

      <template v-if="hasCtrl || (showCtrls && showCtrls.length)">
        <el-table-column
          label="操作"
          :width="ctrlWidth || showCtrls.length * 90"
          fixed="right"
        >
          <template slot="header">
            <slot name="ctrlHeader">操作</slot>
          </template>
          <template slot-scope="{ row }">
            <template v-for="btn in showCtrls">
              <el-button
                :key="btn.label"
                size="mini"
                plain
                v-show="btn.isShow ? btn.isShow(row) : true"
                :type="btn.type || 'primary'"
                @click="handleCtrl(btn, row)"
                >{{ btn.label }}</el-button
              >
            </template>
          </template>
        </el-table-column>
      </template>

      <template slot="append">
        <slot></slot>
      </template>
    </el-table>
    <el-row class="ui-page" type="flex" justify="space-between" v-if="showPage">
      <el-col :span="25">
        <template v-if="selectionCtrls">
          <span class="selection-text"
            >已选择 {{ selectionList.length }} 条</span
          >
          <div
            class="selection-ctrls"
            v-for="btn in selectionCtrls"
            :key="btn.label"
          >
            <el-button
              size="small"
              plain
              :type="btn.type || 'primary'"
              @click="handleSelectionCtrl(btn)"
              >{{ btn.label }}</el-button
            >
          </div>
        </template>
      </el-col>
      <el-col :span="25">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-sizes="[50, 100, 200]"
          :page-size="pageSize"
          :current-page="pageNo"
          :total="data.total"
          @current-change="onPageChange"
          @size-change="onSizeChange"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import 'prismjs/themes/prism-coy.css'
import 'prismjs/components/prism-json'
import mixin from '../common/compoment'
export default {
  name: 'UiTable',
  mixins: [mixin],
  // components: {
  //   Prism,
  // },
  props: {
    ctrlWidth: Number,
    emptyText: String,
    hasCtrl: Boolean,
    data: {
      type: Object,
    },
    autoHeight: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
    },
    height: {
      type: Number,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ctrls: {
      type: Array,
    },
    selectionCtrls: {
      type: Array,
    },
    showPage: {
      default: true,
    },
    currentPage: Number,
    treeProps: {
      type: Object,
    },
  },
  computed: {
    // 格式化数据
    dataList() {
      return this.data.list || this.data.data || []
    },
    tableColumns() {
      return this.columns.filter((n) => {
        if (n.include && n.include.length) {
          return n.include.includes('table')
        }
      })
    },
    tableHeight() {
      return this.height || window.innerHeight - this.queryHeight - 50 - 40 - 25
    },
    showCtrls() {
      let { roleId } = this.$store.state.userInfo
      return this.ctrls
        ? this.ctrls.filter((n) => {
            if (n.role && n.role.length) {
              return n.role.includes(roleId)
            }
            return n.show ? n.show() : true
          })
        : []
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.pageNo = val
      }
    },
  },

  data() {
    return {
      selectionList: [],
      pageNo: 1,
      pageSize: 50,
      queryHeight: 40,
    }
  },
  methods: {
    onSortChange(params) {
      this.$emit('onSortChange', params)
    },
    onLinkClick(column, row) {
      column.click && column.click(row)
    },
    onSelection(selection) {
      this.selectionList = selection
      this.$emit('selection-change', selection)
    },
    handleSelectionCtrl(btn) {
      btn.click && btn.click(this.selectionList)
    },
    handleCtrl(btn, item) {
      btn.click && btn.click(item)
    },
    onSizeChange(size) {
      this.pageSize = size
      this.pageNo = 1
      this.$emit('page-change', {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      })
    },
    onPageChange(page) {
      this.pageNo = page
      this.$emit('page-change', {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      })
    },
  },
  updated() {
    if (document.querySelector('.ui-query') && this.autoHeight) {
      setTimeout(() => {
        this.queryHeight = document.querySelector('.ui-query')
          ? document.querySelector('.ui-query').offsetHeight
          : 44
      }, 300)
    }
  },
}
</script>

<style lang="less">
.ui-table {
  &-th {
    background: #fafafa !important;
    font-weight: bold !important;
    color: #000;
  }

  .el-button--mini {
    padding: 3px 8px;
  }

  .el-tag {
    cursor: pointer;
    margin: 0 3px 0 0;
  }

  .el-link {
    font-size: 12px;
    font-weight: normal;
  }
  .el-button + .el-button {
    margin-left: 0;
  }
  .el-button {
    margin-right: 10px;
  }
}
.ui-page {
  padding: 10px 10px 0;
  .selection {
    &-text {
      font-size: 14px;
      color: #333;
      margin-right: 10px;
    }
    &-ctrls {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
</style>
