<template>
  <div class>
    <el-card class="ui-login">
      <h1>Rockmind 洛克蒙德人力资源服务有限公司</h1>
      <el-form :model="form" ref="form" label-width="80px" label-position="top">
        <el-form-item label="手机号" :rules="rules.mobile" prop="nickname">
          <el-input
            v-model="form.nickname"
            placeholder="输入手机号"
            type="tel"
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="type === 'reg'"
          label="姓名"
          :rules="rules.text"
          prop="name"
        >
          <el-input v-model="form.name" placeholder="输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" :rules="rules.password" prop="password">
          <el-input
            v-model="form.password"
            placeholder="输入登录密码"
            show-password
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="type === 'reg'"
          label="重复密码"
          :rules="rules.repassword"
          prop="repassword"
        >
          <el-input
            v-model="form.repassword"
            placeholder="输入重复密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item v-if="type === 'reg'" label="邮箱">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
            style="width: 100%"
            :loading="loading"
            >{{ type === 'login' ? '登 录' : '注 册' }}</el-button
          >
        </el-form-item>
        <!-- <el-form-item style="text-align:right">
                    <el-link
                        :underline="false"
                        type="primary"
                        @click="type = type === 'login' ? 'reg' : 'login'"
                    >{{ type === 'login' ? '没有账号? 点击注册' : '已有账号, 点击登录' }}</el-link>
                </el-form-item> -->
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 'login',
      loading: false,
      form: {},
      isView: false,
      rules: {
        text: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile: [
          {
            required: true,
            min: 11,
            max: 11,
            message: '请输入正确手机号',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '不能为空',
            trigger: 'blur',
          },
        ],
        repassword: [
          {
            required: true,
            validator: this.validatePass,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    validatePass(rule, value, callback) {
      if (value !== this.form.password) {
        callback(new Error('两次密码不一致, 请重试'))
      } else {
        callback()
      }
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 点击注册
          if (this.type === 'reg') {
            this.$http
              .post('/register', {
                name: this.form.name,
                nickname: this.form.nickname,
                password: this.form.password,
                email: this.form.email,
                mobile: this.form.nickname,
              })
              .then((ret) => {
                if (ret.code === 200) {
                  this.$message.success('注册成功, 请登录 !')
                  this.type = 'login'
                  return
                }
                this.$message.error(ret.message)
              })
          }

          // 点击登录
          if (this.type === 'login') {
            this.loading = true
            this.$http
              .post('/login', {
                nickname: this.form.nickname,
                password: this.form.password,
              })
              .then((ret) => {
                if (ret.code === 200) {
                  Promise.all([
                    this.$store.dispatch('getUsers', {
                      reload: true,
                    }),
                    this.$store.dispatch('getEnumOptions', {
                      reload: true,
                    }),
                    this.$store.dispatch('getUserInfo'),
                    this.$store.dispatch('getRoles'),
                    this.$store.dispatch('getUserInfo'),
                  ]).then(() => {
                    this.loading = false
                    this.$message.success('登录成功 !')
                    this.$router.push({ path: '/' })
                  })

                  return
                }
                this.loading = false
                this.$message.error('账号或密码错误, 请重试 !')
              })
          }
        }
      })

      // return
      // window.localStorage.isLogin = 1
      // this.$router.push({ path: '/' })
    },
  },
}
</script>

<style lang="less">
.ui-login {
  width: 520px;
  margin: 40px auto 0;
  padding-bottom: 20px;

  .el-form-item__label {
    font-size: 16px;
    padding: 0 !important;
  }

  h1 {
    font-size: 22px;
    text-align: center;
    border-bottom: 1px solid #eee;
    padding: 20px 0 30px;
  }
}
</style>
