<template>
  <div class>
    <UiQuery
      :items="dataColumns"
      :defaultParams="defaultParams"
      :ctrls="ctrls"
    ></UiQuery>
    <UiTable
      :columns="dataColumns"
      :data="showData"
      :loading="loading"
      :showPage="false"
      @page-change="onPageChange"
      @onSortChange="onSortChange"
    ></UiTable>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  computed: {
    showData() {
      let keys = Object.keys(this.tableData)
      let dataList = []
      keys.forEach((key) => {
        let value = this.tableData[key]
        if (Array.isArray(value)) {
          value.forEach((item) => {
            let curDate = this.$dayjs(item.date)
            let fm = 'YYYY-MM-DD HH:mm:ss'
            // 处理周报
            if (this.currentParams.type === 'week') {
              let week = curDate.week()
              let year = curDate.year()
              let currentItem = dataList.find(
                (n) => n.week === week && n.year === year
              )
              if (currentItem) {
                currentItem[key] = (currentItem[key] || 0) + item.count
                if (item.amount) {
                  currentItem[key + 'Amount'] =
                    (currentItem[key + 'Amount'] || 0) + item.amount
                }
              } else {
                let st = curDate
                  .startOf(this.currentParams.type)
                  .format('YYYY-MM-DD')
                let et = curDate
                  .endOf(this.currentParams.type)
                  .format('YYYY-MM-DD')
                let newData = {
                  week,
                  year,
                  date: `${st} 至 ${et}`,
                  startDate: `${st} 00:00:00`,
                  endDate: `${et} 23:59:59`,
                }
                newData[key] = item.count
                if (item.amount) {
                  newData[key + 'Amount'] = item.amount
                }
                dataList.push(newData)
              }
              return
            }

            // 处理季报
            if (this.currentParams.type === 'quarter') {
              let quarter = curDate.quarter()
              let year = curDate.year()
              let currentItem = dataList.find(
                (n) => n.quarter === quarter && n.year === year
              )
              if (currentItem) {
                currentItem[key] = (currentItem[key] || 0) + item.count
                if (item.amount) {
                  currentItem[key + 'Amount'] =
                    (currentItem[key + 'Amount'] || 0) + item.amount
                }
              } else {
                let newData = {
                  year,
                  quarter,
                  date: `${year}年 第${quarter}季度`,
                }
                newData.startDate = curDate
                  .startOf(this.currentParams.type)
                  .format(fm)
                newData.endDate = curDate
                  .endOf(this.currentParams.type)
                  .format(fm)
                newData[key] = item.count
                if (item.amount) {
                  newData[key + 'Amount'] = item.amount
                }
                dataList.push(newData)
              }
              return
            }

            let currentItem = dataList.find((n) => n.date === item.date)
            if (currentItem) {
              currentItem[key] = item.count
              if (item.amount) {
                currentItem[key + 'Amount'] = item.amount
              }
            } else {
              let newData = {
                date: item.date,
              }
              newData.startDate = curDate
                .startOf(this.currentParams.type)
                .format(fm)
              newData.endDate = curDate
                .endOf(this.currentParams.type)
                .format(fm)
              newData[key] = item.count
              if (item.amount) {
                newData[key + 'Amount'] = item.amount
              }
              dataList.push(newData)
            }
          })
        }
      })
      return {
        data: dataList,
        total: dataList.length,
      }
    },
  },
  data() {
    return {
      queryUrl: '/dateset/list',
      defaultQueryParams: {
        type: 'month',
        startDate: '2019-01-01 00:00:00',
        endDate: '2120-12-30 00:00:00',
      },
      defaultParams: {
        type: 'month',
        startDate: '2019-01-01 00:00:00',
        endDate: '2120-12-30 00:00:00',
      },
    }
  },
  methods: {
    query(params) {
      if (!this.queryUrl) {
        return
      }
      this.loading = true
      if (params) {
        this.currentParams = { ...this.currentParams, ...params }
      }
      let type =
        this.currentParams.type === 'week'
          ? 'day'
          : this.currentParams.type === 'quarter'
          ? 'month'
          : this.currentParams.type
      this.$http
        .get(this.queryUrl, {
          params: {
            ...this.currentParams,
            ...this.currentPage,
            ...this.queryOrder,
            type,
          },
        })
        .then((res) => {
          this.loading = false
          this.tableData = res.data
        })
    },
  },
  mounted() {
    // 读取默认参数
    const key = `path${this.$route.path}`
    const params = window.localStorage.getItem(key)
    this.currentParams = {}
    if (params && !this.$route.query.startDate) {
      this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
    }
    this.query({ ...this.defaultParams, ...this.$route.query })
    this.setTitle()
  }
}
</script>
