<template>
  <div class="ui-dashboard">
    <h1 class="welcome">欢迎使用 Rockmind 管理系统 !</h1>
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card :body-style="{ padding: '0px' }">
          <div slot="header">
            <span>进行中项目</span>
          </div>
          <el-row>
            <el-col
              :span="12"
              v-for="item in items"
              :key="item.path"
              style="height: 200px"
            >
              <dl class="item">
                <dt>{{ item.label }}</dt>
                <dd v-for="o in item.list" :key="o.id">
                  <router-link :to="o.to" class="ui-link">{{
                    o.showContent
                  }}</router-link>
                </dd>
              </dl>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card :body-style="{ padding: '0px' }">
          <div slot="header">
            <span>最新提醒</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              @click="isShowReminder = true"
            >
              创建提醒
            </el-button>
          </div>
          <el-calendar v-model="month" :first-day-of-week="7">
            <template slot-scope="{ date, data }" slot="dateCell">
              <div
                class="date"
                :class="data.isSelected ? 'is-selected' : ''"
                @click="onSelect({ date, data })"
              >
                <span style="display: block">{{
                  data.day
                    .split('-')
                    .slice(-1)
                    .join('-')
                }}</span>
                <span
                  style="display: block; text-align: center"
                  v-if="checkDate(data.day)"
                  ><i
                    class="el-icon-alarm-clock"
                    style="color: #f56c6c; font-size: 12px"
                  ></i
                ></span>
              </div>
            </template>
          </el-calendar>
        </el-card>
      </el-col>
    </el-row>

    <UiReminder :visible.sync="isShowReminder"></UiReminder>

    <el-dialog
      :title="currentTitle"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="2%"
    >
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in currentReminder"
          :key="index"
          :timestamp="item.remindDate"
          placement="top"
        >
          <el-card class="reminder-list">
            <h4>{{ item.content }}</h4>
            <p v-for="i in columnsList" :key="i.prop">
              <label for="">{{ i.label }} :</label>
              <router-link
                class="ui-link"
                :to="`${i.path}/${item[i.prop + 'Id']}/info`"
                >{{ item[i.prop + 'Name'] }}</router-link
              >
            </p>
            <p><label for="">时间 :</label> {{ item.remindDate }}</p>
            <p><label for="">创建于 :</label> {{ item.createdAt }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import mixin from '../common/index'
import UiReminder from '../components/Reminder.vue'
export default {
  mixins: [mixin],
  components: {
    UiReminder
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    formItems() {
      return this.reminderColumns.filter(n => n.include.includes('show'))
    },
    currentReminder() {
      if (this.currentDay) {
        return this.reminderList.filter(n =>
          n.remindDate.match(this.currentDay)
        )
      }
      return []
    }
  },
  watch: {
    '$store.state.userInfo'() {
      this.initData()
    },
    '$store.state.reminders': {
      immediate: true,
      handler(val) {
        this.reminderList = val
      }
    }
  },
  apollo: {
    allUser: gql`
      query {
        allUser(id: 2) {
          id
          name
        }
      }
    `
  },
  data() {
    return {
      currentTitle: '',
      currentDay: '',
      month: '',
      reminderList: [],
      isShowReminder: false,
      isShow: false,
      list: [],
      columnsList: [
        {
          label: '客户',
          prop: 'company',
          path: '/client'
        },
        {
          label: '联系人',
          prop: 'contact',
          path: '/client'
        },
        {
          label: '岗位',
          prop: 'post',
          path: '/position'
        },
        {
          label: '人才',
          prop: 'talent',
          path: '/candidate'
        },
        {
          label: '项目',
          prop: 'project',
          path: '/project'
        }
      ],
      items: [
        {
          label: '当前沟通客户',
          url: '/client/list',
          path: '/client',
          key: 'followerId'
        },
        {
          label: '当前沟通岗位',
          url: '/post/list',
          path: '/position',
          key: 'followerId'
        },
        {
          label: '当前进行项目',
          url: '/project/list',
          path: '/project',
          key: 'followerId'
        },
        {
          label: '当前沟通人才',
          url: '/talent/candidate/list',
          path: '/candidate',
          key: 'followerId'
        }
      ]
    }
  },
  methods: {
    onSelect({ data }) {
      this.currentTitle = data.day + '提醒'
      this.currentDay = data.day
      this.isShow = true
    },
    checkDate(day) {
      return this.reminderList.some(n => n.remindDate.match(day))
    },
    getData() {
      this.items.forEach(item => {
        let params = {
          pageNo: 1,
          pageSize: 5
        }
        if (item.key) {
          params[item.key] = this.userInfo.id
        }
        this.$http
          .get(item.url, {
            params
          })
          .then(res => {
            let list = res.data.list.map(n => {
              if (item.type === 'reminder') {
                n.showContent = n.content
                n.showInfo = this.$dayjs(n.remindDate).format(
                  'YYYY-MM-DD HH:mm'
                )
              } else {
                n.showContent = n.name
              }
              n.to = `${item.path}/${n.id}/info`
              return n
            })
            this.$set(item, 'list', list)
          })
      })
    }
  },
  mounted() {
    if (this.userInfo.id) {
      this.getData()
    }
  },
  destroyed() {
    this.isShow = false
  }
}
</script>

<style lang="less">
.ui-dashboard {
  h5 {
    border-left: 1px solid #409eff;
  }
  .welcome {
    font-size: 15px;
    margin: 0 0 10px;
    line-height: 35px;
    padding: 0 10px;
  }
  .title {
    font-size: 16px;
  }
  .item {
    font-size: 14px;
    margin: 0 0 10px;
    padding: 20px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    height: 100%;
    box-sizing: border-box;

    dt {
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 10px;
    }
    dd {
      margin: 0 0 5px;
    }
  }
  .box-card {
    margin: 0 0 20px;
  }
  .el-col {
    // max-width: 420px;
  }
  .el-calendar-table .el-calendar-day {
    height: auto;
    min-height: 56px;
  }

  .date {
    min-height: 40px;
  }

  .ui-link {
    color: #409eff;
    text-decoration: none;
  }
  .reminder-list {
    line-height: 2;

    label {
      display: inline-block;
      width: 70px;
    }

    h4 {
      margin: 0 0 10px;
    }
  }
}
</style>
