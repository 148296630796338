<template>
  <div style="display: inline-block">
    <Uploader
      type="analysis"
      @on-success="onSuccess"
      :placeholder="placeholder"
    ></Uploader>

    <el-dialog
      title="导入进度"
      :visible.sync="isShowProgress"
      :show-close="false"
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      @closed="onClosed"
    >
      <div>
        <el-form
          ref="form"
          :model="progress"
          label-width="100px"
          label-position="left"
          v-if="isShowProgress"
        >
          <template v-for="item in progressList">
            <el-form-item :label="item.label" :key="item.label">
              <el-progress
                style="margin: 10px 0 0"
                :percentage="item.value"
                :stroke-width="8"
                :status="
                  item.error
                    ? 'exception'
                    : item.value === 100
                    ? 'success'
                    : null
                "
              ></el-progress>
              <p class="error" style="color: #f56c6c">{{ item.error }}</p>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="onComplete"> 完 成 </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="导入简历预览"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      append-to-body
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        inline
        class="ui-form"
      >
        <el-row :gutter="10">
          <template v-for="(item, index) in items">
            <template v-if="item.type === 'title'">
              <el-col :span="24" :offset="0" :key="item.label + index">
                <div class="ui-sub-title" style="margin: 30px 0; clear: both">
                  {{ item.label }}
                </div>
              </el-col>
            </template>
            <template v-else-if="item.type === 'tag'">
              <div :key="item.label + index">
                <el-tag
                  style="margin: 0 10px 10px 0"
                  type="primary"
                  v-for="t in form[item.prop]"
                  :key="t.tag_name"
                >
                  {{ t.tag_name }}
                </el-tag>
              </div>
            </template>
            <template v-else-if="item.type === 'longText'">
              <el-col :span="24" :offset="0" :key="item.label + index">
                <div v-html="form[item.prop]" style="line-height: 1.5"></div>
              </el-col>
            </template>
            <template v-else-if="item.child">
              <el-table
                border
                :data="form[item.prop]"
                :key="item.label"
                style="width: 100%"
              >
                <el-table-column
                  :prop="c.prop"
                  :label="c.label"
                  v-for="c in item.child"
                  :key="c.prop"
                >
                </el-table-column>
              </el-table>
            </template>
            <template v-else>
              <el-col :span="6" :offset="0" :key="item.prop">
                <el-form-item :label="item.label" :key="item.prop">
                  {{ form[item.prop] }}
                </el-form-item>
              </el-col>
            </template>
          </template>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="isShow = false">关 闭</el-button>
        <el-button type="primary" @click="onSave">确认导入</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Uploader from '../components/Uploader.vue'
export default {
  components: {
    Uploader,
  },
  props: {
    placeholder: String,
    formDefault: Object,
  },
  computed: {
    enumOptions() {
      return this.$store.state.enumOptions
    },
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  data() {
    return {
      finally: false,
      talentData: {},
      fileData: {},
      progress: {},
      progressList: [
        {
          label: '创建人才',
          value: 0,
        },
        {
          label: '关联简历',
          value: 0,
        },
        {
          label: '教育经历',
          value: 0,
        },
        {
          label: '工作经历',
          value: 0,
        },
        {
          label: '项目经历',
          value: 0,
        },
      ],
      isShow: false,
      isShowProgress: false,
      form: {},
      items: [
        {
          label: '基本信息',
          type: 'title',
        },
        {
          label: '姓名',
          prop: 'name',
        },
        {
          label: 'Email',
          prop: 'email',
        },
        {
          label: '联系电话',
          prop: 'phone',
        },
        {
          label: 'QQ号码',
          prop: 'qq',
        },
        {
          label: '微信',
          prop: 'weixin',
        },
        {
          label: '性别',
          prop: 'gender',
        },
        {
          label: '所在城市',
          prop: 'city',
        },
        {
          label: '所在省市区',
          prop: 'city_norm',
        },
        {
          label: '身高',
          prop: 'height',
        },
        {
          label: '体重',
          prop: 'weight',
        },
        {
          label: '婚姻状态',
          prop: 'marital_status',
        },
        {
          label: '出生时间',
          prop: 'birthday',
        },
        {
          label: '当前所在城市',
          prop: 'living_address',
        },
        {
          label: '当前所在省市区',
          prop: 'living_address_norm',
        },
        {
          label: '户口所在地',
          prop: 'hukou_address',
        },
        {
          label: '户口所在省市区',
          prop: 'hukou_address_norm',
        },
        {
          label: '籍贯',
          prop: 'hometown_address',
        },
        {
          label: '籍贯省市区',
          prop: 'hometown_address_norm',
        },
        {
          label: '身份证',
          prop: 'id_card',
        },
        {
          label: '民族',
          prop: 'race',
        },
        {
          label: '国籍',
          prop: 'nationality',
        },
        {
          label: '政治面貌',
          prop: 'polit_status',
        },
        {
          label: '语言能力',
          prop: 'languages',
        },
        {
          label: '英语水平',
          prop: 'english_level',
        },
        {
          label: '计算机水平',
          prop: 'computer_level',
        },
        {
          label: '个人主页',
          prop: 'blog',
        },
        {
          label: '工作年限(年)',
          prop: 'work_year_norm',
        },
        {
          label: '参加工作时间',
          prop: 'work_start_time',
        },
        {
          label: '当前岗位',
          prop: 'work_position',
        },
        {
          label: '当前公司',
          prop: 'work_company',
        },
        {
          label: '所在行业',
          prop: 'work_industry',
        },
        {
          label: '在职状态',
          prop: 'work_status',
        },
        {
          label: '当前薪资',
          prop: 'work_salary',
        },
        {
          label: '预估月薪(元)',
          prop: 'salary',
        },
        {
          label: '工作地点',
          prop: 'work_location',
        },
        {
          label: '工作地点省市区',
          prop: 'work_location_norm',
        },
        {
          label: '工作性质',
          prop: 'work_job_nature',
        },
        {
          label: '是否海外留学',
          prop: 'has_oversea_edu',
          options: [
            {
              label: '否',
              value: '0',
            },
            {
              label: '是',
              value: '1',
            },
          ],
        },
        {
          label: '是否海外工作',
          prop: 'has_oversea_exp',
          options: [
            {
              label: '否',
              value: '0',
            },
            {
              label: '是',
              value: '1',
            },
          ],
        },
        {
          label: '毕业时间',
          prop: 'grad_time',
        },
        {
          label: '毕业学校',
          prop: 'college',
        },
        {
          label: '毕业学校类型',
          prop: 'college_type',
          options: [
            {
              label: '普通院校',
              value: '0',
            },
            {
              label: '985',
              value: '1',
            },
            {
              label: '211',
              value: '2',
            },
            {
              label: '港澳台院校',
              value: '3',
            },
            {
              label: '海外院校',
              value: '4',
            },
            {
              label: '中学',
              value: '5',
            },
            {
              label: '职业教育',
              value: '6',
            },
            {
              label: '培训机构',
              value: '7',
            },
          ],
        },
        {
          label: '所学专业',
          prop: 'major',
        },
        {
          label: '学历',
          prop: 'degree',
        },
        {
          label: '是否统招',
          prop: 'recruit',
        },
        {
          label: '期望工作',
          prop: 'expect_job',
        },
        {
          label: '期望薪资',
          prop: 'expect_salary',
        },
        {
          label: '期望工作地址',
          prop: 'expect_jlocation',
        },
        {
          label: '简历类型',
          prop: 'resume_type',
          options: [
            {
              label: '中文',
              value: '0',
            },
            {
              label: '英文',
              value: '1',
            },
            {
              label: '前中后英',
              value: '2',
            },
            {
              label: '前英后中',
              value: '3',
            },
            {
              label: '无',
              value: '4',
            },
          ],
        },
        {
          label: '简历来源',
          prop: 'resume_source',
        },
        {
          label: '简历完整度',
          prop: 'resume_integrity',
        },
        {
          label: '基本信息',
          type: 'title',
        },
        {
          label: '基本信息',
          prop: 'cont_basic_info',
          type: 'longText',
        },
        {
          label: '自我评价',
          type: 'title',
        },
        {
          label: '自我评价',
          prop: 'cont_my_desc',
          type: 'longText',
        },
        {
          label: '个人技能',
          type: 'title',
        },
        {
          label: '个人技能',
          prop: 'cont_job_skill',
          type: 'longText',
        },
        {
          label: '教育信息',
          type: 'title',
        },
        {
          label: '教育信息',
          prop: 'cont_education',
          type: 'longText',
        },
        // {
        //   label: "工作经历",
        //   type: "title",
        // },
        // {
        //   label: "工作经历",
        //   prop: "cont_job_exp",
        //   type: "longText",
        // },
        // {
        //   label: "项目经历",
        //   type: "title",
        // },
        // {
        //   label: "项目经历",
        //   prop: "cont_proj_exp",
        //   type: "longText",
        // },
        {
          label: '兴趣爱好',
          type: 'title',
        },
        {
          label: '兴趣爱好',
          prop: 'cont_hobby',
          type: 'longText',
        },
        {
          label: '语言技能',
          type: 'title',
        },
        {
          label: '语言技能',
          prop: 'cont_language',
          type: 'longText',
        },
        {
          label: '所获证书',
          type: 'title',
        },
        {
          label: '所获证书',
          prop: 'cont_certificate',
          type: 'longText',
        },
        {
          label: '所获奖励',
          type: 'title',
        },
        {
          label: '所获奖励',
          prop: 'cont_award',
          type: 'longText',
        },
        {
          label: '培训经历',
          type: 'title',
        },
        {
          label: '培训经历',
          prop: 'cont_training',
          type: 'longText',
        },
        {
          label: '所学课程',
          type: 'title',
        },
        {
          label: '所学课程',
          prop: 'cont_course',
          type: 'longText',
        },
        {
          label: '求职信',
          type: 'title',
        },
        {
          label: '求职信',
          prop: 'cont_cover_letter',
          type: 'longText',
        },
        {
          label: '附加信息',
          type: 'title',
        },
        {
          label: '附加信息',
          prop: 'cont_extra_info',
          type: 'longText',
        },
        {
          label: '教育经历',
          type: 'title',
        },
        {
          label: '教育经历',
          prop: 'education_objs',
          child: [
            {
              label: '学校',
              prop: 'edu_college',
            },
            {
              label: '院系',
              prop: 'edu_college_dept',
            },
            {
              label: '专业',
              prop: 'edu_major',
            },
            {
              label: '学历',
              prop: 'edu_degree',
            },
            {
              label: '起始时间',
              prop: 'time',
            },
          ],
        },
        {
          label: '工作实习经历',
          type: 'title',
        },
        {
          label: '工作实习经历',
          prop: 'job_exp_objs',
          child: [
            {
              label: '公司',
              prop: 'job_cpy',
            },
            {
              label: '部门',
              prop: 'job_dept',
            },
            {
              label: '职位',
              prop: 'job_position',
            },
            {
              label: '内容',
              prop: 'job_content',
            },
            {
              label: '起始时间',
              prop: 'time',
            },
          ],
        },
        {
          label: '项目经历',
          type: 'title',
        },
        {
          label: '项目经历',
          prop: 'proj_exp_objs',
          child: [
            {
              label: '公司',
              prop: 'proj_cpy',
            },
            {
              label: '项目名称',
              prop: 'proj_name',
            },
            {
              label: '职位',
              prop: 'proj_position',
            },
            {
              label: '内容',
              prop: 'proj_content',
            },
            {
              label: '职责',
              prop: 'proj_resp',
            },
            {
              label: '起始时间',
              prop: 'time',
            },
          ],
        },
        {
          label: '培训经历',
          type: 'title',
        },
        {
          label: '培训经历',
          prop: 'training_objs',
          child: [
            {
              label: '培训机构',
              prop: 'train_org',
            },
            {
              label: '所获证书',
              prop: 'train_cert',
            },
            {
              label: '培训内容',
              prop: 'train_cont',
            },
            {
              label: '起始时间',
              prop: 'time',
            },
          ],
        },
        {
          label: '技能',
          type: 'title',
        },
        {
          label: '技能',
          prop: 'skills_objs',
          child: [
            {
              label: '技能名称',
              prop: 'skills_name',
            },
            {
              label: '熟练程度',
              prop: 'skills_level',
            },
          ],
        },
        {
          label: '语言能力',
          type: 'title',
        },
        {
          label: '语言能力',
          prop: 'lang_objs',
          child: [
            {
              label: '语言名称',
              prop: 'language_name',
            },
            {
              label: '熟练程度',
              prop: 'language_level',
            },
          ],
        },
        {
          label: '证书',
          type: 'title',
        },
        {
          label: '证书',
          prop: 'cert_objs',
          child: [
            {
              label: '语言名称',
              prop: 'langcert_lang',
            },
            {
              label: '证书',
              prop: 'langcert_name',
            },
            {
              label: '成绩',
              prop: 'langcert_score',
            },
          ],
        },
        {
          label: '行业标签',
          type: 'title',
        },
        {
          label: '行业标签',
          prop: 'industries',
          type: 'tag',
        },
        {
          label: '职位信息',
          type: 'title',
        },
        {
          label: '职位信息',
          prop: 'pos_tags',
          type: 'tag',
        },
        {
          label: '职位类型',
          type: 'title',
        },
        {
          label: '职位类型',
          prop: 'pos_types',
          type: 'tag',
        },
        {
          label: '技能标签',
          type: 'title',
        },
        {
          label: '技能标签',
          prop: 'skills_tags',
          type: 'tag',
        },
      ],
    }
  },
  methods: {
    onClosed() {
      this.finally = false
      this.talentData = {}
      this.fileData = {}
      this.progress = {}
      this.progressList.forEach(n => {
        n.value = 0
        n.error = null
      })
    },
    onComplete() {
      this.isShowProgress = false
      this.$emit('on-complete', this.finally)
    },
    // 创建人才
    createTalent() {
      // 格式化介绍
      let intro = [
        this.fileData.analysisResult.result.cont_my_desc,
        this.fileData.analysisResult.result.cont_job_skill,
      ]
        .filter(Boolean)
        .join('\n')

      // 格式化标签
      // let label = this.form.skills_tags.map((n) => n.tag_name);

      // 格式化备注
      let remark = ''
      if (this.fileData.analysisResult.result.cont_extra_info) {
        remark = this.fileData.analysisResult.result.cont_extra_info
      }

      // 格式化性别
      let sex = ''
      if (this.form.gender) {
        let gender = this.enumOptions.gender.find(
          n => n.label === this.form.gender
        )
        sex = gender ? gender.value : ''
      }

      let edu = ''
      if (this.form.degree) {
        let degree = this.enumOptions.education.find(
          n => n.label === this.form.degree
        )
        edu = degree ? degree.value : ''
      }

      let maritalStatus = ''
      if (this.form.marital_status) {
        let status = this.enumOptions.maritalStatus.find(
          n => n.label === this.form.marital_status
        )
        maritalStatus = status ? status.value : ''
      }

      let political = ''
      if (this.form.political) {
        let status = this.enumOptions.political.find(
          n => n.label === this.form.political
        )
        political = status ? status.value : ''
      }

      let postData = {}

      // 修改只针对介绍和备注叠加, 其他字段不变
      if (this.talentData.id) {
        remark = this.talentData.remark + '\n' + remark
        intro = this.talentData.intro + '\n' + intro
        postData = {
          id: this.talentData.id,
          remark,
          intro,
        }
      } else {
        postData = {
          name: this.form.name,
          sex,
          remark,
          edu,
          intro,
          mobile: this.form.phone,
          email: this.form.email,
          wechat: this.form.weixin,
          year: this.form.birthday,
          address: this.form.living_address_norm,
          hometown: this.form.hometown_address,
          language: this.form.languages,
          political,
          maritalStatus,
          // label,
          followerId: this.userInfo.id,
          post: this.form.work_position,
          // salaryRange: this.form.work_salary,
        }
      }

      return this.$http.post('/talent/candidate/save', postData).then(ret => {
        if (ret.code === 200) {
          if (!this.talentData.id) {
            this.talentData = ret.data
          }
          return {
            code: 200,
            data: ret.message,
          }
        }
        this.$message.error('创建人才失败: ' + ret.message)
        return {
          code: 500,
          data: '创建人才失败: ' + ret.message,
        }
      })
    },

    // 关联简历
    relateResume() {
      let resumeName =
        this.talentData.name + '-导入简历-' + this.$dayjs().format('YYYYMMDD')
      let postData = {
        resumeName,
        file: this.fileData.data,
        talentId: this.talentData.id,
      }
      return this.$http.post('/talent/resume/save', postData).then(ret => {
        if (ret.code === 200) {
          return {
            code: 200,
          }
        }
        this.$message.error(ret.message)
        return {
          code: 500,
          data: ret.message,
        }
      })
    },

    // 教育经历
    relateEducation() {
      let postData = []
      if (this.form.education_objs && this.form.education_objs.length) {
        postData = this.form.education_objs.map(n => {
          let edu = ''
          if (n.edu_degree) {
            let degree = this.enumOptions.education.find(
              m => m.label === n.edu_degree
            )
            edu = degree ? degree.value : ''
          }
          return {
            startDate: this.$dayjs(new Date(n.start_date)).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            endDate: this.$dayjs(new Date(n.end_date)).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            edu,
            object: n.edu_college,
            profession: n.edu_major,
            talentId: this.talentData.id,
            type: 1,
          }
        })
      }
      let ps = postData.map(n => {
        return this.$http.post('/talent/experience/save', n).then(ret => {
          if (ret.code === 200) {
            return {
              code: 200,
            }
          }
          this.$message.error(ret.message)
          return {
            code: 500,
            data: ret.message,
          }
        })
      })
      return Promise.all(ps)
    },

    // 工作经历
    relateJob() {
      function isValidDate(d) {
        const dd = new Date(d)
        return dd instanceof Date && !isNaN(dd.getTime())
      }
      let postData = []
      if (this.form.job_exp_objs && this.form.job_exp_objs.length) {
        postData = this.form.job_exp_objs.map(n => {
          const end_date = isValidDate(n.end_date)
            ? n.end_date
            : '2100-02-01 00:00:00'
          return {
            startDate: this.$dayjs(new Date(n.start_date)).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            endDate: this.$dayjs(new Date(end_date)).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            object: n.job_cpy,
            department: n.job_dept,
            post: n.job_position,
            wordContent: n.job_content,
            address: n.job_location,
            talentId: this.talentData.id,
            type: 2,
          }
        })
      }
      let ps = postData.map(n => {
        return this.$http.post('/talent/experience/save', n).then(ret => {
          if (ret.code === 200) {
            return {
              code: 200,
            }
          }
          this.$message.error(ret.message)
          return {
            code: 500,
            data: ret.message,
          }
        })
      })
      return Promise.all(ps)
    },

    // 项目经历
    relateProject() {
      let postData = []
      if (this.form.proj_exp_objs && this.form.proj_exp_objs.length) {
        postData = this.form.proj_exp_objs.map(n => {
          return {
            startDate: this.$dayjs(new Date(n.start_date)).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            endDate: this.$dayjs(new Date(n.end_date)).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            projectName: n.proj_name,
            object: n.proj_cpy,
            wordContent: n.proj_content,
            projectResp: n.proj_resp,
            talentId: this.talentData.id,
            type: 3,
          }
        })
      }
      let ps = postData.map(n => {
        return this.$http.post('/talent/experience/save', n).then(ret => {
          if (ret.code === 200) {
            return {
              code: 200,
            }
          }
          this.$message.error(ret.message)
          return {
            code: 500,
            data: ret.message,
          }
        })
      })
      return Promise.all(ps)
    },

    // 确定导入
    async onSave() {
      this.isShow = false
      this.isShowProgress = true
      let index = 0
      let max = this.progressList.length
      let loop = async () => {
        let result = {
          code: 200,
        }
        let target = this.progressList[index]
        target.value = 10
        // 创建人才
        if (index === 0) {
          result = await this.createTalent()
        }
        // 关联简历
        if (index === 1) {
          result = await this.relateResume()
        }
        // 教育经历
        if (index === 2) {
          result = await this.relateEducation()
        }
        // 工作经历
        if (index === 3) {
          result = await this.relateJob()
        }
        // 项目经历
        if (index === 4) {
          result = await this.relateProject()
        }

        target.value = 100

        if (result.code === 500) {
          this.$set(target, 'error', result.data)
          this.$message.error('导入失败')
          return
        }

        index++
        if (index < max) {
          loop()
        } else {
          this.finally = true
          this.$message.success('导入成功 !')
        }
      }
      loop()
    },
    // 上传成功并返回解析数据
    onSuccess(data) {
      this.fileData = data
      let { analysisResult } = data
      let { result: r, tags } = analysisResult
      r.salary = analysisResult.eval.salary
      for (const key in tags) {
        r[key] = tags[key]
      }

      this.items.forEach(item => {
        let { prop, options } = item
        let value = r[prop]
        if (options) {
          let target = options.find(n => n.value === value)
          value = target ? target.label : value
        }
        // 多经历,对时间进行格式化
        if (item.child) {
          value.forEach(v => {
            if (v.start_date && v.end_date) {
              v.time = `${v.start_date} - ${v.end_date}`
            }
          })
        }

        // 长文本格式化
        if (item.type === 'longText' && value) {
          value = value.replace(/\n/g, '<br/>')
        }

        this.$set(this.form, prop, value)
      })

      this.isShow = true
    },
  },
  mounted() {
    if (this.formDefault && this.formDefault.id) {
      this.talentData = { ...this.formDefault }
    }
  },
}
</script>

<style lang="less">
.ui-form {
  padding: 0 30px;
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
