<template>
  <el-form
    :model="form"
    ref="form"
    :label-width="labelWidth || '100px'"
    size="small"
    label-position="right"
    :disabled="disabled"
    class="ui-dialog-form"
  >
    <el-row :gutter="20">
      <template v-for="item in formItems">
        <div
          v-if="item.type === 'divide'"
          class="ui-sub-title"
          :key="item.label"
        >
          {{ item.label }}
        </div>
        <el-col
          :span="colWidth(item)"
          :key="item.prop"
          v-if="item.type !== 'divide' && (item.show ? item.show() : true)"
        >
          <el-form-item
            :label="item.label"
            :rules="item.rule ? rules[item.rule] : null"
            :prop="item.prop || ''"
          >
            <template slot="label" v-if="item.tooltip">
              {{ item.label }}
              <el-tooltip :content="item.tooltip" placement="top" effect="dark">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>

            <template v-if="item.type === 'input'">
              <el-input
                v-model="form[item.prop]"
                :placeholder="item.placeholder || '请填写'"
                clearable
                :disabled="handlerDisabled(item)"
                @change="onSelectChange(item, $event)"
                @keyup.enter.native="onSubmit"
              ></el-input>
            </template>

            <template v-else-if="item.type === 'number'">
              <el-input
                v-model.number="form[item.prop]"
                :placeholder="item.placeholder || '请填写'"
                clearable
                :disabled="handlerDisabled(item)"
                @change="onSelectChange(item, $event)"
                @keyup.enter.native="onSubmit"
              ></el-input>
            </template>

            <template v-else-if="item.type === 'password'">
              <el-input
                v-model="form[item.prop]"
                :placeholder="item.placeholder || '请填写'"
                clearable
                show-password
                :disabled="handlerDisabled(item)"
                @keyup.enter.native="onSubmit"
              ></el-input>
            </template>

            <template v-else-if="item.type === 'radio'">
              <el-radio-group v-model="form[item.prop]">
                <el-radio
                  :label="n.value"
                  :key="n.value"
                  v-for="n in item.options"
                  :disabled="n.disabled"
                  >{{ n.label }}</el-radio
                >
              </el-radio-group>
            </template>

            <template v-else-if="item.type === 'remoteSelect'">
              <el-select
                v-model="form[item.prop]"
                :placeholder="item.placeholder || '请选择'"
                :value-key="item.valueKey || 'value'"
                :no-data-text="item.noDataText || '无数据'"
                clearable
                filterable
                remote
                :loading="loading"
                :remote-method="item.source"
              >
                <el-option
                  v-for="o in item.options"
                  :key="o.key || o.value"
                  :label="o.label"
                  :value="o.value"
                ></el-option>
              </el-select>
            </template>

            <template v-else-if="item.type === 'select'">
              <el-select
                v-model="form[item.prop]"
                :placeholder="item.placeholder || '请选择'"
                clearable
                filterable
                :value-key="item.valueKey || 'value'"
                :no-data-text="item.noDataText || '无数据'"
                :disabled="handlerDisabled(item)"
                @change="onSelectChange(item, $event)"
              >
                <el-option
                  v-for="o in item.options"
                  :key="o.key || o.value"
                  :label="o.label"
                  :value="o.value"
                ></el-option>
              </el-select>
            </template>

            <template v-else-if="item.type === 'checkboxGroup'">
              <el-checkbox-group v-model="form[item.prop]">
                <el-checkbox
                  :label="o.value"
                  v-for="o in item.options"
                  :key="o.value"
                  >{{ o.label }}</el-checkbox
                >
              </el-checkbox-group>
            </template>

            <template v-else-if="item.type === 'multiSelect'">
              <el-select
                v-model="form[item.prop]"
                :no-data-text="item.noDataText || '无数据'"
                placeholder="请选择"
                clearable
                filterable
                multiple
                :disabled="handlerDisabled(item)"
              >
                <el-option
                  v-for="o in item.options"
                  :key="o.value"
                  :label="o.label"
                  :value="o.value"
                ></el-option>
              </el-select>
            </template>

            <template v-else-if="item.type === 'transfer'">
              <el-transfer
                v-model="form[item.prop]"
                filterable
                :data="item.options"
                target-order="push"
                :titles="['可选岗位', '已选岗位']"
              ></el-transfer>
            </template>

            <!-- 远程搜索下拉 -->
            <!-- <template v-else-if="item.type === 'remoteSelect'">
              <el-select
                v-model="form[item.prop]"
                placeholder="请输入进行搜索"
                clearable
                filterable
                remote
                :remote-method="item.remoteMethod"
                :disabled="handlerDisabled(item)"
              >
                <el-option
                  v-for="o in item.options"
                  :key="o.value"
                  :label="o.label"
                  :value="o.value"
                ></el-option>
              </el-select>
            </template> -->

            <template v-else-if="item.type === 'textarea'">
              <el-input
                v-model="form[item.prop]"
                :placeholder="item.placeholder || '请填写'"
                type="textarea"
                :rows="item.rows || 5"
                clearable
                :disabled="handlerDisabled(item)"
              ></el-input>
            </template>

            <template v-else-if="item.type === 'dateType'">
              <DateType
                :startTime="form[item.props[0]]"
                :endTime="form[item.props[1]]"
                @change="handleDateType(item, $event)"
              ></DateType>
            </template>

            <template v-else-if="item.type === 'datePicker'">
              <DatePicker
                :value-format="item.valueFormat"
                :startTime.sync="form[item.props[0]]"
                :endTime.sync="form[item.props[1]]"
              ></DatePicker>
            </template>

            <template v-else-if="item.type === 'monthrange'">
              <DatePicker
                type="monthrange"
                :value-format="item.valueFormat"
                :startTime.sync="form[item.props[0]]"
                :endTime.sync="form[item.props[1]]"
              ></DatePicker>
            </template>

            <template v-else-if="item.type === 'dateTime'">
              <el-date-picker
                v-model="form[item.prop]"
                type="datetime"
                placeholder="选择日期时间"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </template>

            <template v-else-if="item.type === 'date'">
              <el-date-picker
                v-model="form[item.prop]"
                type="date"
                placeholder="选择日期时间"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </template>

            <template v-else-if="item.type === 'upload'">
              <Uploader v-model="form[item.prop]"></Uploader>
            </template>

            <template v-else-if="item.type === 'multiple-upload'">
              <Uploader v-model="form[item.prop]" :multiple="true"></Uploader>
            </template>

            <template v-else-if="item.type === 'avatar'">
              <Uploader
                v-model="form[item.prop]"
                type="avatar"
                :action="item.action"
                :headers="item.headers"
                :name="item.name"
              ></Uploader>
            </template>

            <template v-else-if="item.type === 'tagGroup'">
              <Tag v-model="form[item.prop]" :type="item.tagType"></Tag>
            </template>

            <template v-else-if="item.type === 'address'">
              <AddressCom
                v-model="form[item.prop]"
                :formDefault="formDefault"
                :prop="item.prop"
                :ref="item.prop"
              ></AddressCom>
            </template>

            <template v-else-if="item.type === 'switch'">
              <el-switch
                v-model="form[item.prop]"
                :active-value="item.options[0].value"
                :inactive-value="item.options[1].value"
              ></el-switch>
            </template>

            <template v-else-if="item.type === 'tips'">{{
              item.content
            }}</template>
          </el-form-item>
        </el-col>
      </template>
    </el-row>

    <slot name="other"></slot>

    <el-form-item>
      <div class style="text-align: right">
        <template v-if="actions && actions.length">
          <el-button
            @click="handlerClick(a)"
            :type="a.type || 'default'"
            v-for="a in actions"
            :key="a.label"
            :loading="a.loading"
          >
            {{ a.label }}
          </el-button>
        </template>
        <template v-else>
          <!-- <el-button @click="onCancel">取消</el-button> -->
          <Analysis
            v-if="showAnalysis"
            style="margin: 0 10px 0 0"
            :formDefault="formDefault"
            @on-complete="onComplete"
          ></Analysis>
          <el-button type="primary" @click="onSubmit" :loading="loading">
            提交
          </el-button>
        </template>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import mixin from '../common/compoment'
import DateType from '../components/DateType.vue'
import DatePicker from '../components/DatePicker.vue'
import AddressCom from '../components/Address.vue'
import Uploader from '../components/Uploader.vue'
import Tag from '../components/Tag.vue'
import Analysis from '../components/Analysis.vue'
export default {
  name: 'UiForm',
  mixins: [mixin],
  components: {
    DateType,
    DatePicker,
    AddressCom,
    Uploader,
    Tag,
    Analysis,
  },
  props: {
    showAnalysis: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelWidth: String,
    formDefault: {
      type: Object,
    },
    formItem: {
      type: Array,
    },
    inline: {
      default: false,
    },
    actions: Array,
    loading: Boolean,
  },
  computed: {
    formItems() {
      return this.formItem
        .filter(n => {
          if (n.include && n.include.length) {
            return n.include.includes('form')
          }
        })
        .map(n => {
          if (n.optionKey) {
            let list = []
            if (this.enumOptions[n.optionKey]) {
              list = this.enumOptions[n.optionKey].map(m => {
                let value = m.value
                if (n.dataType) {
                  if (n.dataType === 'string') {
                    value = value.toString()
                  }
                  if (n.dataType === 'number') {
                    value = Number(value)
                  }
                }
                return { ...m, value }
              })
              list.reverse()
            }
            n.options = this[n.optionKey] || list
          }
          return n
        })
    },
  },
  watch: {
    formDefault: {
      deep: true,
      immediate: true,
      handler(val) {
        let keys = val ? Object.keys(val) : []
        // 如果有默认值
        if (val && keys.length) {
          this.formItems.forEach(item => {
            if (
              item.type === 'dateType' ||
              item.type === 'datePicker' ||
              item.type === 'monthrange'
            ) {
              if (item.props && item.props.length) {
                item.props.forEach(p => {
                  this.$set(this.form, p, val[p])
                })
              }
              // 多选格式化
            } else if (item.type === 'date') {
              let key = item.prop
              let value = val[key]
              if (new Date(value).getTime()) {
                this.$set(this.form, key, value)
              }
            } else if (item.type === 'dateTime') {
              let key = item.prop
              let value = val[key]
              if (!value.match('Invalid')) {
                this.$set(this.form, key, value)
              }
            } else if (
              item.type === 'multiSelect' &&
              val[item.prop] &&
              val[item.prop].indexOf(',') !== -1
            ) {
              let key = item.prop
              let value = val[key]
              value = value
                .split(',')
                .filter(n => n !== '')
                .map(n => parseInt(n))
              this.$set(this.form, key, value)
            } else {
              let key = item.prop
              let value = val[key]
              if (Array.isArray(value)) {
                value = value.filter(
                  n => n !== '' && n !== null && n !== undefined && !isNaN(n)
                )
              }

              this.$set(this.form, key, value)

              if (item.type === 'select') {
                this.onSelectChange(item, null, false)
              }
            }
          })
          return
        }

        // 没有默认值, 格式化数组
        this.formItems.forEach(item => {
          let defaultValue = ['checkboxGroup', 'multiSelect'].includes(
            item.type
          )
            ? []
            : ''
          this.$set(this.form, item.prop, defaultValue)
        })

        let follower = this.formItems.find(n => n.prop === 'followerId')
        if (
          follower &&
          follower.options &&
          follower.options.length &&
          follower.options.find(n => n.value === this.userInfo.id)
        ) {
          this.$set(this.form, 'followerId', this.userInfo.id)
        }
      },
    },
  },
  data() {
    return {
      form: {},
      fileList: [],
    }
  },

  methods: {
    onComplete(state) {
      this.$emit('on-complete', state)
    },
    // 解析简历导入内容
    analysis(data) {
      let keys = Object.keys(data)
      keys.forEach(key => {
        let value = data[key]
        if (key === 'edu') {
          let item = this.formItems.find(n => n.prop === key)
          let target = item.options.find(n => n.label === value)
          value = target.value
        }

        this.$set(this.form, key, value)
      })
    },
    buttonListClick(btn, item) {
      item.click && item.click(btn, this.form)
    },
    handleDateType(item, value) {
      this.$set(this.form, item.props[0], value[0])
      this.$set(this.form, item.props[1], value[1])
    },
    onSelectChange(item, value, clearValue = true) {
      item.relate &&
        item.relate.forEach(r => {
          if (r.source) {
            r.source(this.form, item).then(list => {
              if (Array.isArray(list)) {
                let target = this.formItem.find(n => n.prop === r.prop)
                if (target) {
                  this.$set(target, 'options', list)
                }
                if (clearValue && target) {
                  let defaultValue = target.type === 'multiSelect' ? [] : ''
                  this.$set(this.form, r.prop, defaultValue)
                }
              }
            })
          }

          if (r.setValue) {
            r.setValue(this.form, item).then(value => {
              if (r.type && r.type === 'address') {
                if (this.$refs[r.prop] && this.$refs[r.prop].length) {
                  this.$refs[r.prop][0].setValue(value)
                }
              }
              if (clearValue) {
                this.$set(this.form, r.prop, value)
              }
            })
          }
        })
    },
    onFuncChange(opt) {
      for (const key in opt) {
        this.$set(this.form, key, opt[key])
      }
    },
    colWidth(item) {
      if (item.formWidth) {
        return item.formWidth
      }
      if (item.type === 'dateType') {
        return 16
      }
      if (this.inline !== false) {
        return 8
      }
      return 12
    },
    handlerClick(item) {
      this.$refs.form.validate(vaild => {
        if (item.noValid || vaild) {
          let postData = {}
          for (const key in this.form) {
            let value = this.form[key]
            if (
              key !== undefined &&
              value !== '' &&
              value !== undefined &&
              value !== null
            ) {
              postData[key] = value
            }
          }
          item.click && item.click(postData, item)
        }
      })
    },
    handlerDisabled(item) {
      if (item.disabled) {
        return item.disabled(this.form)
      }
      return false
    },
    onCancel() {
      this.$emit('cancel')
    },
    next() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('next', this.form)
        }
      })
    },
    onSubmit() {
      this.$refs.form.validate(vaild => {
        if (vaild) {
          let postData = {}
          for (const key in this.form) {
            let value = this.form[key]
            if (
              key === 'undefined' ||
              key === undefined ||
              value === undefined ||
              value === null
            ) {
              continue
            }
            if (Array.isArray(value)) {
              postData[key] = value.join(',')
              continue
            }
            postData[key] = value
          }
          this.$emit('submit', postData)
        }
      })
    },
    resetFields() {
      this.form = {}
      this.$refs.form.clearValidate()
      this.$refs.form.resetFields()
    },
  },

  created() {
    this.formItem.forEach(item => {
      item.source &&
        item.source().then(data => {
          this.$set(item, 'options', data)
        })
    })
  },

  destroyed() {
    this.form = {}
  },
}
</script>
<style lang="less">
.buttonList {
  margin: 0 10px 10px 0 !important;
}
.el-select {
  width: 100%;
}
.ui-dialog-form {
  .el-transfer-panel {
    width: 42% !important;
  }
}
</style>
