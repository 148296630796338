import { mapState } from 'vuex'
import clientColumns from './columns/client'
import contactColumns from './columns/contact'
import experienceColumns from './columns/experience'
import educationColumns from './columns/education'
import recordColumns from './columns/record'
import talentRecordColumns from './columns/talentRecord'
import resumeColumns from './columns/resume'
import candidateColumns from './columns/candidate'
import positionColumns from './columns/position'
import projectColumns from './columns/project'
import projectPositionColumns from './columns/projectPosition'
import positionCandidateColumns from './columns/positionCandidate'
import financeColumns from './columns/finance'
import reminderColumns from './columns/reminder'
import roleColumns from './columns/role'
import accountColumns from './columns/account'
import recommendColumns from './columns/recommend'
import contractColumns from './columns/contract'
import candidateProjectColumns from './columns/candidateProject'
import candidatePositionColumns from './columns/candidatePosition'
import positionOfferColumns from './columns/positionOffer'
import interviewColumns from './columns/interview'
import dataColumns from './columns/data'
import agentColumns from './columns/agent'
import logColumns from './columns/log'
import emailColumns from './columns/email'
import communityColumns from './columns/community'

import { stateList } from '../store/common'

const Prism = require('prismjs')

const mixin = {
  computed: {
    ...mapState(stateList),
    userList() {
      return this.$store.getters.userList
    },
    isAdmin() {
      return [1, 8].includes(this.userInfo?.roleId)
    }
  },
  data() {
    return {
      // 操作日志
      isShowLog: false,
      // 公共查询接口路径
      queryUrl: '',
      // 公共loading
      loading: false,
      // 公共弹窗状态
      isShow: false,
      // 当前编辑内容
      currentItem: {},
      // 当前查询字段
      currentParams: {},
      // 默认搜索字段
      defaultParams: {},
      // 默认排序
      queryOrder: '',
      // 所有客户列表
      companyList: [],
      // 当前翻页
      currentPage: {
        pageNo: 1,
        pageSize: 50,
      },
      // 表格字段 {list, total}
      tableData: {},
      // 公共查询按钮
      ctrls: [
        {
          label: '查询',
          click: params => {
            if (params) {
              window.localStorage.setItem(
                'path' + this.$route.path,
                JSON.stringify(params)
              )
            }
            this.query(params)
          },
        },
        {
          label: '重置',
          type: 'info',
          click: () => {
            const key = `path${this.$route.path}`
            window.localStorage.removeItem(key)
            this.defaultParams = {}
            const currentParams = this.defaultQueryParams
              ? { ...this.defaultQueryParams }
              : {}
            this.currentParams = currentParams
            this.query()
          },
        },
      ],

      // 客户字段
      clientColumns: clientColumns(this),
      // 联系人字段
      contactColumns: contactColumns(this),
      // 工作经历字段
      experienceColumns: experienceColumns(this),
      // 教育经历字段
      educationColumns: educationColumns(this),
      // 沟通记录
      recordColumns: recordColumns(this),
      // 人才沟通记录
      talentRecordColumns: talentRecordColumns(this),
      // 简历字段
      resumeColumns: resumeColumns(this),
      // 人才字段
      candidateColumns: candidateColumns(this),
      // 岗位字段
      positionColumns: positionColumns(this),
      // 项目字段
      projectColumns: projectColumns(this),
      // 项目关联岗位
      projectPositionColumns: projectPositionColumns(this),
      // 岗位关联人才
      positionCandidateColumns: positionCandidateColumns(this),
      // 开票字段
      financeColumns: financeColumns(this),
      // 提醒字段
      reminderColumns: reminderColumns(this),
      // 角色字段
      roleColumns: roleColumns(this),
      // 账号字段
      accountColumns: accountColumns(this),
      // 推荐字段
      recommendColumns: recommendColumns(this),
      // 合同信息
      contractColumns: contractColumns(this),
      // 人才项目经历
      candidateProjectColumns: candidateProjectColumns(this),
      // 人才关联岗位
      candidatePositionColumns: candidatePositionColumns(this),
      // 关联人才编辑
      positionOfferColumns: positionOfferColumns(this),
      // 面试信息
      interviewColumns: interviewColumns(this),
      // 数据报表
      dataColumns: dataColumns(this),
      // 小程序顾问
      agentColumns: agentColumns(this),
      // 日志列表
      logColumns: logColumns(this),
      // 邮件模板
      emailColumns: emailColumns(this),
      // 社区
      communityColumns: communityColumns(this),
    }
  },

  methods: {
    setTitle(title) {
      let t = title || this.$route.meta?.title || ''
      document.title = `${t} - Rockmind 洛克蒙德人力资源服务`
    },
    serialize(obj) {
      return Object.keys(obj)
        .map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
        })
        .join('&')
    },
    openLink(link, query) {
      let { origin, pathname } = window.location
      let url = `${origin}${pathname}#${link}`
      if (query) {
        url += '?' + this.serialize(query)
      }
      window.open(url)
    },
    formatObj(obj) {
      const keys = Object.keys(obj)
      const ret = {}
      keys.forEach(key => {
        const value = obj[key]
        if (value !== '' && value !== null && value !== undefined) {
          ret[key] = value
        }
      })
      return ret
    },
    // 异步延时执行
    waitUntil(func, time = 300) {
      return new Promise(resolve => {
        let timer = null
        timer = setInterval(() => {
          const ret = func()
          if (ret) {
            clearInterval(timer)
            resolve(ret)
          }
        }, time)
      })
    },
    onSortChange({ prop, order }) {
      if (order) {
        this.queryOrder = {
          orderBy: prop,
          orderType: order.replace('ending', ''),
        }
      } else {
        this.queryOrder = ''
      }
      this.query()
    },
    onClosed() {
      this.currentItem = {}
    },
    goBack() {
      window.history.back()
    },
    onPageChange(page) {
      this.currentPage = page
      this.query()
    },
    query(params) {
      if (!this.queryUrl) {
        return
      }
      this.loading = true
      this.tableData.list = []
      if (params) {
        this.currentParams = { ...this.currentParams, ...params }
      }
      this.$http
        .get(this.queryUrl, {
          params: {
            ...this.currentParams,
            ...this.currentPage,
            ...this.queryOrder,
          },
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data
          this.$nextTick(() => {
            Prism.highlightAll()
          })
        })
    },
  },

  // activated() {
  //   const key = `path${this.$route.path}`
  //   const params = window.localStorage.getItem(key)
  //   this.currentParams = {}
  //   if (params && !this.$route.query.startDate) {
  //     this.defaultParams = { ...this.defaultParams, ...JSON.parse(params) }
  //   }
  //   this.query({ ...this.defaultParams, ...this.$route.query })
  // },
  // deactivated() {
  //   this.currentParams = {}
  //   this.defaultParams = {}
  // },
  // mounted () {
  //   console.log('mounted');
  // }
}

export default mixin
