<template>
  <div>
    <UiTable
      :columns="positionCurrentColumns"
      :data="formatTableData"
      :ctrls="tableCtrls"
      :loading="loading"
      :ctrlWidth="300"
      @page-change="onPageChange"
    >
      <template #ctrlHeader>
        <el-button type="primary" @click="showNew" size="mini">
          关联人才
        </el-button>
        <el-button type="info" @click="isShowMuiltEmail = true" size="mini">
          发送邮件
        </el-button>
      </template>
    </UiTable>

    <el-dialog
      title="关联人才"
      :visible.sync="isShowRelate"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="positionCandidateColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        v-if="isShowRelate"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="修改推荐状态"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="relateColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShow = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="修改Offer信息"
      :visible.sync="isShowOffer"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="offerColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowOffer = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="入职信息"
      :visible.sync="isShowEntry"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="entryColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        @cancel="isShowEntry = false"
        @submit="onSubmit"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="开票信息"
      :visible.sync="isShowFinance"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="financeItems"
        :formDefault="currentFinance"
        :key="currentItem.id"
        @cancel="isShowFinance = false"
        @submit="onSubmitFinance"
      ></UiForm>
    </el-dialog>

    <el-dialog
      title="推荐信息"
      :visible.sync="isShowRecommend"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="90%"
      top="1%"
      @closed="onClosed"
    >
      <el-row :gutter="20">
        <el-col :span="16" :offset="0">
          <UiForm
            :formItem="recommendColumnsShow"
            :formDefault="currentItem"
            :key="currentItem.id"
            :loading="submitLoading"
            :actions="formActions"
            v-if="isShowRecommend"
            @cancel="isShowRecommend = false"
            @submit="onSubmit"
          ></UiForm>
        </el-col>
        <el-col :span="8" :offset="0">
          <div class="ui-sub-title">当前人才最近推荐信息</div>
          <dl
            v-for="item in talentLastList"
            :key="item.label"
            class="ui-talent-last"
          >
            <dt>{{ item.label }}</dt>
            <dd>{{ talentLast[item.prop] }}</dd>
          </dl>
          <div style="text-align: right">
            <el-button type="primary" size="small" @click="autoFill">
              自动填入表单
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      title="推荐邮件预览"
      :visible.sync="isShowEmail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
    >
      <Uemail
        ref="email"
        :content="emailData"
        :templateList="emailTemplateList"
        :subject.sync="emailData.subject"
        :scene="686"
        v-if="isShowEmail"
      ></Uemail>
      <span slot="footer">
        <el-button type="default" @click="isShowEmail = false">取消</el-button>
        <el-button type="primary" @click="doSend" :loading="submitLoading">
          确定发送
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="面试通知信息"
      :visible.sync="isShowInterview"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
      @closed="onClosed"
    >
      <UiForm
        :formItem="interviewColumns"
        :formDefault="currentItem"
        :key="currentItem.id"
        :loading="submitLoading"
        @cancel="isShowInterview = false"
        @submit="onInterviewEmailPreview"
      >
        <template #other>
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in interviewHistory"
              :key="index"
              :timestamp="activity.createdTime"
              placement="top"
            >
              <p><label for="">面试时间: </label>{{ activity.viewTime }}</p>
              <p><label for="">面试地点: </label>{{ activity.viewAddress }}</p>
              <p><label for="">面试方式: </label>{{ activity.viewType }}</p>
              <p><label for="">附加信息: </label>{{ activity.viewInfo }}</p>
            </el-timeline-item>
          </el-timeline>
        </template>
      </UiForm>
    </el-dialog>

    <el-dialog
      title="面试通知邮件预览"
      :visible.sync="isShowInterviewEmail"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
      top="1%"
    >
      <Uemail
        ref="interviewEmail"
        :content="interviewEmailData"
        :templateList="emailTemplateList"
        :scene="687"
        v-if="isShowInterviewEmail"
      ></Uemail>
      <span slot="footer">
        <el-button
          type="primary"
          @click="doSendInterview"
          :loading="submitLoading"
        >
          确定发送
        </el-button>
      </span>
    </el-dialog>

    <UiMultiEmail
      :visible.sync="isShowMuiltEmail"
      :scene="689"
      :emailListSet="emailListSet"
    />
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  props: {
    form: Object,
  },
  computed: {
    emailListSet() {
      if (this.formatTableData.list) {
        return this.formatTableData.list.map((n) => {
          let { email, name, id } = n.talent
          return {
            label: name + ` <${email || '无邮件'}>`,
            value: email,
            key: id,
            obj: n.talent,
          }
        })
      }
      return []
    },
    recommendColumnsShow() {
      if (this.userInfo.roleId === 11) {
        return this.recommendColumns.map((n) => {
          if (n.prop === 'contactId') {
            n.disabled = () => {
              return true
            }
            n.rule = null
          }
          return n
        })
      }
      return this.recommendColumns
    },
    positionCurrentColumns() {
      return this.positionCandidateColumns.filter(
        (n) => !['推荐客户', '推荐岗位'].includes(n.label)
      )
    },
    // 修改推荐状态
    relateColumns() {
      return this.positionOfferColumns.filter((n, i) => i <= 2)
    },
    // offer信息
    offerColumns() {
      return this.positionOfferColumns.filter((n, i) => i > 2 && i < 7)
    },
    entryColumns() {
      return this.positionOfferColumns.filter((n, i) => i >= 7)
    },
    postId() {
      return parseInt(this.$route.params.id)
    },
    extraData() {
      return {
        postId: this.postId,
      }
    },
    // 有权关联的用户
    validUser() {
      let ret = []
      // 岗位负责人
      if (this.form.followerId) {
        ret.push(this.userList.find((n) => n.value === this.form.followerId))
      }
      // 岗位跟进人
      if (this.form.adviserId) {
        let adviser = this.form.adviserId
          .split(',')
          .filter((n) => n !== '')
          .map((n) => Number(n))
        this.userList.forEach((n) => {
          if (adviser.includes(n.value)) {
            ret.push(n)
          }
        })
      }
      return ret
    },

    financeItems() {
      let include = [
        'cost',
        'type',
        'name',
        'contactId',
        'mobile',
        'address',
        'costFiles',
        'remark',
      ]
      return this.financeColumns.filter((n) => include.includes(n.prop))
    },

    formatTableData() {
      let tableData = {
        total: this.tableData.total,
      }
      if (this.tableData && this.tableData.list) {
        tableData.list = this.tableData.list.map((n) => {
          return { ...n, relateId: n.id, id: n.id }
        })
      }
      return tableData
    },
  },
  data() {
    return {
      emailTemplateList: [],
      talentLast: {},
      talentLastList: [
        {
          label: '学历及工作背景简介',
          prop: 'backIntro',
        },
        {
          label: '能力匹配',
          prop: 'skill',
        },
        {
          label: '优势劣势及软性评估',
          prop: 'assess',
        },
        {
          label: '求职动机及到岗时间',
          prop: 'intention',
        },
        {
          label: '薪资详情及期望',
          prop: 'expectation',
        },
        {
          label: '其他补充内容',
          prop: 'otherContent',
        },
      ],
      currentFinance: {}, // 开票信息
      interviewHistory: [], //面试记录
      formActions: [
        {
          label: '生成推荐报告',
          type: 'primary',
          click: this.exportFile,
        },
        {
          label: '保存草稿',
          click: this.onSave,
        },
        {
          label: '提交',
          type: 'primary',
          click: (form) => {
            this.onSubmit(form)
          },
        },
      ],
      queryUrl: '/post/talent/list',
      isShowEntry: false,
      isShowOffer: false,
      isShowFinance: false,
      isShowRecommend: false,
      isShowRelate: false,
      isShowEmail: false,
      isShowMuiltEmail: false,
      isShowInterview: false,
      isShowInterviewEmail: false,
      submitLoading: false,
      emailData: {},
      interviewEmailData: {},
      postData: {},
      tableCtrls: [
        {
          label: '修改',
          click: (item) => {
            this.currentItem = { ...item }
            this.isShow = true
          },
        },
        {
          label: '推荐',
          type: 'info',
          click: (item) => {
            this.getTalentLast(item.talentId)
            let currentItem = {
              ...item,
              isSend: 1,
              recommendDate: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
            }
            // 读取本地模板
            if (localStorage.getItem('recommendTemplate')) {
              currentItem.emailTemplate = parseInt(
                localStorage.getItem('recommendTemplate'),
                10
              )
            }
            // 默认取当前岗位的所属客户
            if (item.post && item.post.companyId) {
              currentItem.companyId = item.post.companyId
            }
            // if (item.contactId) {
            //   currentItem.contactId = {
            //     id: item.contactId
            //   }
            // }
            // 默认没有推荐顾问,选当前登录账号
            if (!item.recommendId) {
              currentItem.recommendId = this.userInfo.id
            }

            let storageKey = `${this.$route.path}/${currentItem.id}`
            let dataFromStorage = localStorage.getItem(storageKey)
            if (dataFromStorage) {
              let data = JSON.parse(dataFromStorage)
              currentItem = { ...currentItem, ...data }
            }

            this.currentItem = currentItem
            this.isShowRecommend = true
          },
        },
        {
          label: '面试',
          click: (item) => {
            let currentItem = { ...item }
            if (item.talent && item.talent.email) {
              currentItem.viewEmail = item.talent.email
            }
            if (item.viewUser) {
              currentItem.viewUser = {
                id: Number(item.viewUser),
              }
            }
            if (item.viewRecord && item.viewRecord.length) {
              this.interviewHistory = item.viewRecord.map((n) => {
                let ret = this.enumOptions.interviewType.find(
                  (m) => m.value === n.viewType
                )
                let viewType = ''
                if (ret) {
                  viewType = ret.label
                }
                return { ...n, viewType }
              })
            }
            if (!item.viewType) {
              currentItem.viewType = ''
            }
            // 默认取客户地址
            if (!item.viewAddress) {
              this.$http
                .get('/client/desc', {
                  params: {
                    id: item.companyId,
                  },
                })
                .then((res) => {
                  currentItem.viewAddressStore = res.data.address
                  this.currentItem = currentItem
                  this.isShowInterview = true
                })
              return
            }
            if (localStorage.interviewTemplate) {
              currentItem.emailTemplate = parseInt(
                localStorage.interviewTemplate,
                10
              )
            }
            this.currentItem = currentItem
            this.isShowInterview = true
            setTimeout(() => {
              let op = this.interviewColumns.find((n) => n.prop === 'viewUser')
              if (op && op.options) {
                let obj = op.options.find(
                  (n) => n.key === currentItem.viewUser.id
                )
                this.currentItem.viewUser = obj.value
              }
            }, 500)
          },
        },
        {
          label: 'offer',
          click: (item) => {
            this.currentItem = { ...item }
            this.isShowOffer = true
          },
        },
        {
          label: '入职',
          click: (item) => {
            this.currentItem = { ...item }
            this.isShowEntry = true
          },
        },
        {
          label: '开票',
          click: (item) => {
            let currentItem = {
              ...item,
            }
            // 默认取当前岗位的所属客户
            if (item.post && item.post.companyId) {
              currentItem.companyId = item.post.companyId

              this.$http
                .get('/client/desc', {
                  params: {
                    id: item.post.companyId,
                  },
                })
                .then((res) => {
                  this.currentFinance.name = res.data.serviceName
                  this.currentItem = currentItem
                  this.isShowFinance = true
                })
              return
            }

            this.currentItem = currentItem
            this.isShowFinance = true
          },
        },
        {
          label: '删除',
          type: 'danger',
          click: (item) => {
            this.$confirm('确定删除 ?', {
              type: 'warning',
            }).then(() => {
              this.$http
                .get('/post/talent/del', {
                  params: {
                    talentId: item.talentId,
                    postId: this.postId,
                  },
                })
                .then((ret) => {
                  if (ret.code === 200) {
                    this.$message.success('删除成功 !')
                    this.query()
                  }
                })
            })
          },
        },
      ],
      tableHeight: window.innerHeight - 190,
    }
  },
  methods: {
    // 自动填入表单
    autoFill() {
      this.talentLastList.forEach((item) => {
        let { prop } = item
        let val = this.talentLast[prop]
        this.$set(this.currentItem, prop, val)
      })
    },
    // 获取人才的最后推荐信息
    getTalentLast(talentId) {
      this.$http
        .get('/post/talent/list', {
          params: {
            talentId,
            isRecommend: true,
          },
        })
        .then((res) => {
          if (res.data && res.data.list && res.data.list.length) {
            this.talentLast = res.data.list[0]
          }
        })
    },
    exportFile(form) {
      this.$http
        .post('/file/export', { ...this.currentItem, ...form })
        .then((res) => {
          window.open('/file/get?fileName=' + res.data)
        })
    },
    onSave(form) {
      localStorage.setItem(
        `${this.$route.path}/${this.currentItem.id}`,
        JSON.stringify(form)
      )
      this.$message.success('保存成功 !')
      this.isShowRecommend = false
    },
    // 打开面试通知预览邮件
    onInterviewEmailPreview(form) {
      form.cc = []
      if (form.viewContactEmail) {
        form.viewCopyEmail = form.viewCopyEmail
          ? form.viewCopyEmail + ',' + form.viewContactEmail
          : form.viewContactEmail
        form.cc = form.viewCopyEmail.split(',')
      }
      this.interviewEmailData = { ...this.currentItem, ...form }
      // 保存上一次模板
      if (this.interviewEmailData.emailTemplate) {
        localStorage.interviewTemplate = this.interviewEmailData.emailTemplate
      }
      this.isShowInterviewEmail = true
    },
    // 发送面试通知邮件
    doSendInterview() {
      this.interviewEmailData.html =
        this.$refs.interviewEmail.$el.querySelector('.inner').innerHTML
      this.submitLoading = true
      let {
        viewEmail: email,
        viewCopyEmail: cc,
        talent,
        company,
      } = this.interviewEmailData
      return this.$http
        .post('/email', {
          ...this.interviewEmailData,
          email,
          cc,
          subject: `面试通知：${talent.name}-${company.name}-ROCKMIND`,
        })
        .then((ret) => {
          this.submitLoading = false
          this.isShowInterview = false
          this.isShowInterviewEmail = false
          if (ret.code === 200) {
            this.$message.success('邮件发送成功 !')
            let {
              viewTime,
              viewType,
              viewAddress,
              viewLink,
              viewUser,
              viewInfo,
              viewEmail,
              viewCopyEmail,
            } = this.interviewEmailData
            this.postData = {
              id: this.currentItem.id,
              viewTime,
              viewType,
              viewAddress,
              viewLink,
              viewUser: viewUser.id,
              viewInfo,
              viewEmail,
              viewCopyEmail,
            }
            let viewRecord = []
            if (
              this.currentItem.viewRecord &&
              this.currentItem.viewRecord.length
            ) {
              viewRecord = this.currentItem.viewRecord
            }
            viewRecord.push({
              ...this.postData,
              createdTime: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
            })
            this.postData.viewRecord = JSON.stringify(viewRecord)

            this.talentSave()
            return
          }
          this.$message.error(ret.message)
        })
        .catch((e) => {
          this.submitLoading = false
          if (e.response.data && e.response.data.msg) {
            this.$message.error(e.response.data.msg)
          }
        })
    },
    doSend() {
      this.emailData.html =
        this.$refs.email.$el.querySelector('.inner').innerHTML

      this.onSubmitRecommend(this.emailData).then((ret) => {
        if (ret) {
          this.talentSave()
        }
      })
    },
    showNew() {
      this.isShowRelate = true
    },
    // 发送推荐邮件
    onSubmitRecommend(form) {
      this.submitLoading = true
      return this.$http
        .post('/email', {
          ...form,
          email: form.email,
          file: form.file,
        })
        .then((ret) => {
          this.submitLoading = false
          if (ret.code === 200) {
            this.$message.success('邮件发送成功 !')
            return true
          }
          this.$message.error(ret.message)
          return false
        })
        .catch((err) => {
          this.$message.error(err.message)
          this.submitLoading = false
        })
    },
    // 开票
    onSubmitFinance(form) {
      let ret = this.tableData.list.find(
        (n) => n.id === this.currentItem.relateId
      )

      let postData = {
        ...form,
        companyId: ret.post.companyId,
        contactId: ret.post.contactId,
        projectId: ret.post.projectId,
        postId: ret.postId,
        talentId: ret.talentId,
        talentPostId: this.currentItem.id,
      }
      // 默认未开票
      let financeStatus = this.enumOptions.financeStatus.find(
        (n) => n.label === '未开票'
      )
      let defaultStatus = financeStatus ? financeStatus.value : ''
      if (defaultStatus) {
        postData.status = defaultStatus
      }
      this.$http.post('/finance/save', postData).then((ret) => {
        if (ret.code === 200) {
          this.$message.success('提交成功 !')
          this.isShowFinance = false
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
    // 保存关联关系
    talentSave() {
      let postData = {
        ...this.postData,
      }
      if (this.postData.companyId && this.postData.companyId.id) {
        postData.companyId = this.postData.companyId.id
      }
      if (this.postData.contactId && this.postData.contactId.id) {
        postData.contactId = this.postData.contactId.id
      }

      // 新关联人才
      if (this.postData.talentId) {
        postData.followerId = this.userInfo.id
      }

      // 修改为"已推荐"状态
      if (postData.recommendId) {
        postData.recommendStatus = this.$store.getters.getEnumByLabel(
          'recommendStatus',
          '已推荐'
        )
      }

      // // 修改为"面试中"状态
      if (postData.viewType) {
        postData.recommendStatus = this.$store.getters.getEnumByLabel(
          'recommendStatus',
          '面试中'
        )
      }

      // // 修改为"offer成功"状态
      if (postData.offerDate) {
        postData.recommendStatus = this.$store.getters.getEnumByLabel(
          'recommendStatus',
          'offer成功'
        )
      }

      // // 修改为"已入职"状态
      if (postData.entryDate) {
        postData.recommendStatus = this.$store.getters.getEnumByLabel(
          'recommendStatus',
          '已入职'
        )
      }

      return this.$http.post('/post/talent/save', postData).then((ret) => {
        this.isShowEmail = false
        this.isShowRecommend = false
        if (ret.code === 200) {
          this.removeStorage(this.currentItem.id)
          this.$message.success('提交成功 !')
          this.isShow = false
          this.isShowRelate = false
          this.isShowRecommend = false
          this.isShowEntry = false
          this.isShowOffer = false
          this.postData = {}
          this.query()
          return
        }
        this.$message.error(ret.message)
      })
    },
    removeStorage(id) {
      let key = `${this.$route.path}/${id}`
      localStorage.removeItem(key)
    },
    // 根据顾问id获取顾问信息
    getRecommendUser(id) {
      return this.users.find((n) => n.id === id)
    },
    // 关联人才 / 推荐 / 修改 / 面试 / 入职
    onSubmit(form) {
      let postData = {
        ...form,
        postId: this.postId,
      }

      // 推荐/修改/面试
      if (this.currentItem.id) {
        postData.id = this.currentItem.id
        postData.postId = this.currentItem.postId
        postData.talentId = this.currentItem.talentId
      }

      let file = ''

      // 遍历出简历附件
      if (postData.resumeId) {
        let resume = this.recommendColumns.find((n) => n.prop === 'resumeId')
        file = resume
          ? resume.options.find((n) => n.value === postData.resumeId).obj.file
          : ''
      }

      postData.companyId = this.form.companyId

      this.postData = postData

      // 需要发送邮件
      if (postData.isSend === 1) {
        if (!postData.email) {
          this.$message.error('联系邮箱不能为空 ！')
          return
        }
        let company = {}
        let contact = {}

        let companyItem = this.recommendColumns.find(
          (n) => n.prop === 'companyId'
        )
        company = companyItem.options.find(
          (n) => n.value === postData.companyId
        ).obj
        let contactItem = this.recommendColumns.find(
          (n) => n.prop === 'contactId'
        )
        contact = contactItem.options.find(
          (n) => n.value === postData.contactId
        ).obj

        let recommendUser = {}
        if (this.currentItem.post && this.currentItem.post.followerId) {
          recommendUser = this.getRecommendUser(
            this.currentItem.post.followerId
          )
        }

        // 本地保存默认模板
        if (postData.emailTemplate) {
          localStorage.recommendTemplate = postData.emailTemplate
        }

        this.emailData = {
          ...this.currentItem,
          ...postData,
          company,
          contact,
          file,
          name: this.currentItem.talent.name,
          recommendUser,
        }

        let followerId = this.currentItem.post.followerId
        let follower = this.users.find((n) => n.id === followerId)
        if (follower && follower.email) {
          this.emailData.cc = [follower.email]
        }
        if (postData.copyEmail) {
          let copyEmailList = postData.copyEmail.split(',')
          this.emailData.cc = [...this.emailData.cc, ...copyEmailList]
        }

        this.isShowEmail = true

        return
      }

      // 无需发邮件，直接提交
      this.talentSave()
    },
  },
  created() {
    this.defaultParams = {
      postId: this.postId,
    }
  },
  mounted() {
    this.query({
      postId: this.postId,
    })
  },
}
</script>

<style lang="less">
.el-timeline {
  p {
    line-height: 1.5;
  }
}
.ui-talent-last {
  margin: 20px 0 30px;

  dt {
    font-weight: bold;
    margin: 0 0 10px;
  }

  dd {
    margin: 0;
    padding: 0;
    max-height: 60px;
    overflow: auto;
    line-height: 1.5;
  }
}
</style>
