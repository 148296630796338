<template>
  <div class="ui-setting">
    <el-page-header @back="goBack" content="账号设置"> </el-page-header>

    <div class="ui-setting-form">
      <UiForm
        labelWidth="140px"
        :formItem="formItems"
        :formDefault="currentItem"
        :key="currentItem.id"
        :loading="loading"
        @submit="onSubmit"
      ></UiForm>
    </div>
  </div>
</template>

<script>
import mixin from '../../common/index'
export default {
  mixins: [mixin],
  watch: {
    userInfo: {
      immediate: true,
      deep: true,
      handler(val) {
        this.currentItem = {
          email: val.email,
          emailPassword: val.emailPassword
        }
      }
    }
  },
  data() {
    return {
      form: {},
      currentItem: {},
      formItems: [
        {
          label: '邮箱',
          prop: 'email',
          type: 'input',
          rule: 'emailNotRequired',
          formWidth: 24,
          include: ['form']
        },
        {
          label: '邮箱安全密码',
          prop: 'emailPassword',
          type: 'input',
          tooltip:
            '登录企业邮箱-设置-邮箱绑定-开启安全登录-生成新密码，获取安全密码',
          formWidth: 24,
          include: ['form']
        },
        {
          label: '账号密码',
          prop: 'password',
          type: 'password',
          rule: 'password',
          formWidth: 24,
          placeholder: '请输入新密码, 修改后需重新登录',
          include: ['form']
        }
      ]
    }
  },
  methods: {
    onSubmit(form) {
      this.loading = true
      this.$http
        .post('/modify/userInfo', {
          ...this.formatObj(form)
        })
        .then(res => {
          this.loading = false
          this.refresh()
          if (res.code === 200) {
            this.$message.success('修改成功 !')
            this.$store.dispatch('getUserInfo')

            // 修改密码需重新登录
            if (form.password) {
              this.$http.get('/logout').then(() => {
                window.localStorage.clear()
                window.location.reload()
              })
            }

            return
          }
          this.$message.error(res.message)
        })
    },

    refresh(){
      this.$http.get('/refresh/userInfo').then(()=>{
        this.$store.dispatch('getUserInfo')
      })
    },

    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less">
.ui-setting {
  &-form {
    width: 60%;
    margin: 40px 20px;
  }
}
</style>
