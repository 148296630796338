<template>
  <div class="">
    <template v-if="type === 'monthrange'">
      <el-row :gutter="5">
        <el-col :span="10">
          <el-date-picker
            v-model="startMonth"
            type="month"
            align="right"
            unlink-panels
            class="monthRange"
            placeholder="开始日期"
            @change="onStartMonthChange"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="10">
          <el-date-picker
            v-model="endMonth"
            :disabled="isNow"
            type="month"
            align="right"
            unlink-panels
            class="monthRange"
            placeholder="结束日期"
            @change="onEndMonthChange"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-checkbox v-model="isNow">至今</el-checkbox>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <el-date-picker
        v-model="dates"
        :type="type"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="onChange"
      >
      </el-date-picker>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    startTime: [Number, String],
    endTime: [Number, String],
    type: {
      default: 'daterange',
    },
    valueFormat: {
      default() {
        return 'YYYY-MM-DD'
      },
    },
  },
  watch: {
    isNow: {
      // immediate: true,
      handler(val) {
        if (val === true) {
          this.endMonth = ''
          let date = this.$dayjs('2100-02-01 00:00:00').format(this.valueFormat)
          this.$emit('update:endTime', date)
        } else {
          this.$emit('update:endTime', '')
        }
      },
    },
  },
  data() {
    return {
      isNow: false,
      dates: [],
      startMonth: '',
      endMonth: '',
      pickerOptions: {
        // shortcuts: [
        //   {
        //     text: '最近一周',
        //     onClick (picker) {
        //       const end = new Date()
        //       const start = new Date()
        //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        //       picker.$emit('pick', [start, end])
        //     }
        //   },
        //   {
        //     text: '最近一个月',
        //     onClick (picker) {
        //       const end = new Date()
        //       const start = new Date()
        //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        //       picker.$emit('pick', [start, end])
        //     }
        //   },
        //   {
        //     text: '最近三个月',
        //     onClick (picker) {
        //       const end = new Date()
        //       const start = new Date()
        //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        //       picker.$emit('pick', [start, end])
        //     }
        //   }
        // ]
      },
    }
  },
  methods: {
    onStartMonthChange(value) {
      let date = value ? this.$dayjs(value).format(this.valueFormat) : ''
      this.$emit('update:startTime', date)
    },
    onEndMonthChange(value) {
      let date = value ? this.$dayjs(value).format(this.valueFormat) : ''
      this.$emit('update:endTime', date)
    },
    onChange(value) {
      if (value && Array.isArray(value)) {
        let [st, et] = value
        let hh = et.getHours()
        let mm = et.getMinutes()
        let ss = et.getSeconds()
        if (hh === 0 && mm === 0 && ss === 0) {
          et.setHours(23)
          et.setMinutes(59)
          et.setSeconds(59)
        }
        this.$emit('update:startTime', this.$dayjs(st).format(this.valueFormat))
        this.$emit('update:endTime', this.$dayjs(et).format(this.valueFormat))
      } else {
        this.$emit('update:startTime', '')
        this.$emit('update:endTime', '')
      }
    },
  },
  mounted() {
    if (this.type === 'monthrange') {
      this.startMonth = this.startTime
      let end = this.$dayjs(this.endTime).year()
      if (end === 2100) {
        this.isNow = true
      } else {
        this.endMonth = this.endTime
      }
    } else {
      this.dates = [this.startTime, this.endTime]
    }
  },
}
</script>

<style lang="less">
.monthRange {
  width: 100% !important;
}
</style>
