<template>
  <div>
    <el-dialog
      title="群发邮件"
      :visible.sync="visible"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div v-if="visible">
        <el-form
          :model="form"
          ref="form"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="邮件模板">
            <el-select
              v-model="form.emailTemplate"
              placeholder="请选择"
              clearable
              filterable
            >
              <el-option
                v-for="item in emailTemplateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="已勾选" v-if="selection.length">
            {{ selectionList }}
          </el-form-item>
          <el-form-item label="收件人" v-else>
            <el-select
              v-model="form.email"
              placeholder="请输入关键词进行搜索"
              clearable
              filterable
              multiple
              :remote="isRemote"
              :loading="loading"
              :remote-method="remoteMethod"
              value-key="key"
            >
              <el-option
                v-for="item in emailList"
                :key="item.key"
                :label="item.label"
                :value="item"
                :disabled="!item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="邮件预览" :visible.sync="isShow" width="80%" top="0">
      <Uemail
        ref="email"
        :content="emailData"
        :templateList="emailTemplateList"
        :scene="scene"
        :subject.sync="subject"
        :isMulti="true"
        v-if="isShow"
      ></Uemail>
      <span slot="footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="sendEmail" :loading="sendLoading">
          发 送
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'UiMultiEmail',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    scene: {
      type: Number,
      default: 690,
    },
    // 收件人类型
    emailType: {
      type: String,
      default: 'talent',
    },
    emailListSet: {
      type: Array,
      default: () => [],
    },
    extraData: {
      type: Object,
      default: () => ({}),
    },
    // 父层已选收件人
    selection: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isRemote() {
      return this.emailListSet.length === 0
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    selectionList() {
      if (this.selection && this.selection.length) {
        return `${this.selection
          .slice(0, 5)
          .map((n) => `${n.name}<${n.email}>`)
          .join('；')}...等${this.selection.length}人`
      }
      return ''
    },
  },
  watch: {
    emailListSet(val) {
      if (val && val.length) {
        this.emailList = val
      }
    },
    visible(val) {
      if (val) {
        console.log('this.selection', this.selection)
      }
    },
  },
  data() {
    return {
      form: {},
      subject: '',
      emailTemplateList: [],
      emailList: [],
      emailData: {},
      timer: null,
      loading: false,
      isShow: false,
      sendLoading: false,
      urlMaps: {
        talent: '/talent/candidate/list',
        contact: '/client/user/list',
        candidate: '/post/talent/list',
      },
    }
  },
  methods: {
    sendEmail() {
      this.emailData.html =
        this.$refs.email.$el.querySelector('.inner').innerHTML
      this.sendLoading = true
      const list = this.selection.length
        ? this.selection.map((n) => {
            return { key: n.id }
          })
        : this.form.email
      return this.$http
        .post('/email/batch', {
          subject: this.subject,
          emailTemplate: this.form.emailTemplate,
          receiver: list.map((n) => {
            return {
              id: n.key,
            }
          }),
        })
        .then((ret) => {
          this.sendLoading = false
          this.isShow = false
          this.cancel()
          if (ret.code === 200) {
            this.$message.success('邮件发送成功 !')
            return
          }
          this.$message.error(ret.message)
        })
    },
    cancel() {
      this.$emit('update:visible', false)
    },
    submit() {
      if (
        !this.form.emailTemplate ||
        (!this.form.email && !this.selection.length)
      ) {
        this.$message.error('请选择邮件模板和收件人')
        return
      }
      const list = this.selection.length
        ? this.selection.map((n) => {
            return {
              label: n.name + ` <${n.email || '无邮件'}>`,
              value: n.email,
              key: n.id,
              obj: n,
            }
          })
        : this.form.email
      this.emailData = {
        ...this.form,
        email: list.map((item) => item.value).join(','),
        talents: list.map((item) => item.obj),
        recommendUser: this.userInfo,
      }
      this.isShow = true
      // this.$emit('update:visible', false)
    },
    getTemplate() {
      this.$http
        .get('/email/list', {
          params: {
            pageSize: 10000,
            scene: this.scene,
          },
        })
        .then((ret) => {
          this.emailTemplateList = ret.data.list.map((n) => {
            return {
              ...n,
              label: n.name,
              value: n.id,
              key: n.id,
            }
          })
        })
    },
    async remoteMethod(query) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(async () => {
        if (this.emailListSet.length) {
          this.emailList = this.emailListSet
          return
        }
        if (!query) {
          this.emailList = []
          return
        }
        this.loading = true
        let url = this.urlMaps[this.emailType]
        let data = await this.$http.get(url, {
          params: {
            talentType: -1,
            pageNo: 1,
            pageSize: 1000,
            name: query,
            ...this.extraData,
          },
        })
        this.loading = false
        if (data.data && data.data.list) {
          this.emailList = data.data.list.map((n) => {
            return {
              label: n.name + ` <${n.email || '无邮件'}>`,
              value: n.email,
              key: n.id,
              obj: n,
            }
          })
          return
        }
        this.emailList = []

        console.log('data', data)
      })
    },
  },
  mounted() {
    this.getTemplate()
  },
}
</script>

<style>
</style>